import React from 'react';
import L from 'leaflet';
import 'react-leaflet-fullscreen/dist/styles.css'
import FullscreenControl from 'react-leaflet-fullscreen';
import './leaflet_numbered_markers.js'
import './../../../css/leaflet_numbered_markers.css'

// styles
import useStyles from "./styles";

function MapContainer({ markersData, centerLatitude, centerLongitude, specialLocations }) {
  var classes = useStyles();
  
  // create map
  const mapRef = React.useRef(null);
  React.useEffect(() => {
    mapRef.current = L.map("map", {
      center: [centerLatitude, centerLongitude], // 58.14671, 7.9956
      zoom: 12,
      layers: [
        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        })
      ],
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: 'topleft'
      }
    });
  }, []);

  // add layer
  const layerRef = React.useRef(null);
  React.useEffect(() => {
    layerRef.current = L.layerGroup().addTo(mapRef.current);
    
  }, []);

  React.useEffect(() => {
    mapRef.current.flyTo(new L.LatLng(centerLatitude, centerLongitude));
  }, [centerLongitude]);


  var FreeIcon = L.NumberedDivIcon.extend({
    options: {
        iconUrl: '/markers/marker_ready.svg',
        iconSize: [48,48],
        iconAnchor: [24, 48],
        number:''
    }
  });

  var PauseIcon = L.NumberedDivIcon.extend({
    options: {
        iconUrl: '/markers/marker_pause.svg',
        iconSize: [48,48],
        iconAnchor: [24, 48],
        number:''
    }
  });

  var BusyIcon = L.NumberedDivIcon.extend({
    options: {
        iconUrl: '/markers/marker_busy.svg',
        iconSize: [48,48],
        iconAnchor: [24, 48],
        number:''
    }
  });

  var UnknownIcon = L.NumberedDivIcon.extend({
    options: {
        iconUrl: '/markers/marker_unknown.svg',
        iconSize: [48,48],
        iconAnchor: [24, 48],
        number:''
    }
  });

  // update markers
  React.useEffect(
    () => {
      markersData
      .sort(function (a, b) {
        let x = a.queuePosition==0 ? 100: a.queuePosition;
        let y = b.queuePosition==0 ? 100: b.queuePosition;
        return (x-y)      
      })
      .forEach(function(marker, index){
        var found = 0;

        mapRef.current.eachLayer(function(layer){
          if (layer.options.cabId == marker.cabId) {
            layer.remove();
            addMarker(marker, 1000-index);
            found = 1;
          }
        });

        if (found == 0) {
          addMarker(marker, 1000-index);
        }
      });
    },
    [markersData]
  );

  React.useEffect(
    () => {
      specialLocations.map(loc => {
        L.circle([loc.latitude,loc.longitude], loc.radius, {weight:1, opacity:0.3, fillOpacity:0.1}).addTo(mapRef.current);
      });
     
    },
    [specialLocations]
  );


  const addMarker = function(marker, zIndex) {

    if (marker.status== 1) {
      L.marker(marker.latLng, {zIndexOffset:zIndex, icon:new FreeIcon({number:getQueueNumber(marker.queuePosition)}), title: marker.title, cabId:marker.cabId, status:marker.status, queuePosition:marker.queuePosition }).addTo(
        layerRef.current
      );

    }
    else if (marker.status== 2) {
      L.marker(marker.latLng, {zIndexOffset:zIndex, icon:new BusyIcon({number:getQueueNumber(marker.queuePosition)}), title: marker.title, cabId:marker.cabId, status:marker.status, queuePosition:marker.queuePosition }).addTo(
        layerRef.current
      );

    }
    else if (marker.status== 3) {
      L.marker(marker.latLng, {zIndexOffset:zIndex, icon:new PauseIcon({number:getQueueNumber(marker.queuePosition)}), title: marker.title, cabId:marker.cabId, status:marker.status, queuePosition:marker.queuePosition }).addTo(
        layerRef.current
      );

    }
    else {
      L.marker(marker.latLng, {zIndexOffset:0, icon:new UnknownIcon({number:getQueueNumber(marker.queuePosition)}), title: marker.title, cabId:marker.cabId, status:marker.status, queuePosition:marker.queuePosition}).addTo(
        layerRef.current
      );
    }
  }

  const getQueueNumber = (number) => {
    if (number == 0 || typeof number == 'undefined') {
      return '';
    }
    else return number.toString();
  }

  return (<div id="map"  className={classes.mapLayout}/>);
}

export default MapContainer;