import React, { useState, useEffect } from "react";
import '../../css/components/my-trips.css'
import FormatUtc from '../dashboard/Tools/formatUtc'
import TripStatus from './child-components/tripStatus'
import TripPayment from './child-components/tripPayment'
import TripStatusDialog from './child-components/tripStatusDialog'
import SearchIndicator from "./child-components/searchIndicator";
import { FaTaxi, FaHamburger, FaSchool } from "react-icons/fa";
import { SemipolarLoading } from 'react-loadingg'



const MyTripsTable = (props) => {

  const getDefaultConfigForFieldsToShow = function(){
    return {
      createdDate: { isShown: true, order: 1},
      bookingNumber:{ isShown: true, order: 2 },
      driverNumber: { isShown: true, order: 3 },
      permitNumber: { isShown: true, order: 4 },
      from: { isShown: true, order: 5},
      to: { isShown: true, order:6 },
      regionName: { isShown: true, order:7 },
      price: { isShown: true, order:8},
      bookingStatus:{ isShown: true, order:9 },
      pickupTime: { isShown: false,order:10 },
      requestedPickupTime: { isShown: false,order:11 },
      numberOfPassengers: {isShown:false,order:12}
    }
  }

  let {tripList, onScrollCb, onSortCb, role, fieldsToShow=getDefaultConfigForFieldsToShow(), 
      sortFieldDefault = "createdDate", fieldsToShowDetails, tripsLoading} = props;

  const [trips, setTrips] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState({});
  const [sortField, setSortField] = useState(sortFieldDefault);
  const [ascending, setAscending] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setTrips(tripList);
    setLoading(false);
  },[tripList]);

 

  const handleClose = function() {
    setShow(false);
    //setSelectedTrip(null);
  }
  const handleShow = function(trip) {
    if (role==1) {
      setSelectedTrip(trip);
      setShow(true);
    }
  }

  const handleScroll = function(e) {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    //console.log(e.target.scrollHeight + ", " + e.target.scrollTop + ", " + e.target.clientHeight);

    if (bottom && !loading) { 
      setLoading(true);
      onScrollCb(); 
    }
  }

  const handleSort= (field) => {
    var direction = ascending;
    if (field == sortField) {
      //Just change direction
      direction = !direction;
    }
    else {
      direction = false;
      // New field

    }
    setSortField(field);
    setAscending(direction);
    onSortCb(field, direction);

  }

  const getIconColor = function(trip) {
    if (trip.isManual) {
      return "blue";
    }
    if (trip.cancelReason > 0) {
      return "black";
    }
    if (trip.bookingStatus < 4) {
      return "red";
    }

    return "green";
  }

  const getDriverName = function(trip) {
    if (trip.driverNumber == null) {
      return "Bil ikke tildelt";
    }
    return trip.driverNumber + ' - ' + trip.firstName + ' ' + trip.lastName;
  }

  const formatAddress= (address) => {
    var res = (address.streetName != null?address.streetName:'') + ' ' + 
              (address.houseNumber != null?address.houseNumber:'') + ' ' + 
              (address.houseLetter != null?address.houseLetter:'') + ' ' + 
              (address.zipCode != null?address.zipCode:'') + ' ' + 
              (address.district != null?address.district:'');
    return res;
  }

  let tripsHtml = null;
  if (trips) {
    tripsHtml = trips.map((trip, index) => {
      return (
          <div className="myTrips-table-row" title="Klikk for detaljer" key={'MTR_' + index} onScroll={handleScroll} onClick={() => handleShow(trip)}>
            <div className="myTrips-icon" style={{order: 0}}>
              {(function() {
                  switch (trip.category) {
                    case 0:
                        return <FaTaxi style={{color:getIconColor(trip)}} />;
                    case 1:
                        return <FaSchool style={{color:getIconColor(trip)}} />;
                    case 2:
                        return <FaHamburger style={{color:getIconColor(trip)}}/>;
                    default:
                        return <FaTaxi style={{color:getIconColor(trip)}} />;
                  }
                })()}
            </div>
           
            {
                fieldsToShow.createdDate && fieldsToShow.createdDate.isShown ? <div style={{order: fieldsToShow.createdDate.order}} className="myTrips-date"><FormatUtc item={trip.createdDate}/></div>: null
            }
            {
                fieldsToShow.bookingNumber && fieldsToShow.bookingNumber.isShown ? <div style={{order: fieldsToShow.bookingNumber.order}} className="myTrips-booking">{trip.bookingNumber}</div>: null
            }

            {
                fieldsToShow.driverNumber && fieldsToShow.driverNumber.isShown ? <div style={{order: fieldsToShow.driverNumber.order}} className="myTrips-driver">{getDriverName(trip)}</div>: null
            }
            
            {
                fieldsToShow.permitNumber && fieldsToShow.permitNumber.isShown ? <div style={{order: fieldsToShow.permitNumber.order}} className="myTrips-permit  text-center">{trip.permitNumber}</div>: null
            }
            
            {
                fieldsToShow.from && fieldsToShow.from.isShown ? <div style={{order: fieldsToShow.from.order}} className="myTrips-address">{formatAddress(trip.from)}</div>: null
            }
            {
                fieldsToShow.to && fieldsToShow.to.isShown ? <div style={{order: fieldsToShow.to.order}} className="myTrips-address">{formatAddress(trip.to)}</div>: null
            }
            {
                fieldsToShow.regionName && fieldsToShow.regionName.isShown ? <div style={{order: fieldsToShow.regionName.order}} className="myTrips-region">{trip.regionName}</div> : null
            }
            
            {
                fieldsToShow.price && fieldsToShow.price.isShown ? <div style={{order: fieldsToShow.price.order}} className="myTrips-payment text-right"><div><TripPayment price={trip.price} currency={trip.currency}/></div></div>: null
            }
            {
                fieldsToShow.bookingStatus && fieldsToShow.bookingStatus.isShown ? <div style={{order: fieldsToShow.bookingStatus.order}} className="myTrips-status text-center"><TripStatus bookingStatus={trip.bookingStatus} cancelReason={trip.cancelReason}></TripStatus></div> : null
            }
             {
                fieldsToShow.pickupTime && fieldsToShow.pickupTime.isShown ? <div style={{order: fieldsToShow.pickupTime.order, minWidth:'130px'}} className="myTrips-pickupTime text-center"><FormatUtc item={trip.pickupTime}/></div> : null
            }
             {
                fieldsToShow.requestedPickupTime && fieldsToShow.requestedPickupTime.isShown ? <div style={{order: fieldsToShow.requestedPickupTime.order, minWidth:'130px'}} className="myTrips-pickupTime text-center"><FormatUtc item={trip.requestedPickupTime}/></div> : null
            }         
              {
                fieldsToShow.numberOfPassengers && fieldsToShow.numberOfPassengers.isShown ? <div style={{order: fieldsToShow.numberOfPassengers.order, minWidth:'130px'}} className="myTrips-numberOfPassengers text-center">{trip.numberOfPassengers}</div> : null
            }       
          </div>
      )
    })
  }


  return (
    <>
      <div className="myTrips-table-header">
        <div className="myTrips-icon"></div>
        
        {
                fieldsToShow.createdDate && fieldsToShow.createdDate.isShown ? <div style={{order: fieldsToShow.createdDate.order}} name="createdDate" title="Sorter" className="myTrips-date" onClick={() =>handleSort("createdDate")}>Dato<SearchIndicator visible={sortField == "createdDate"} ascending={ascending}/></div>
                : null
        }
        {
                fieldsToShow.bookingNumber && fieldsToShow.bookingNumber.isShown ? <div style={{order: fieldsToShow.bookingNumber.order}} name="bookingNumber" title="Sorter" className="myTrips-booking" onClick={() =>handleSort("bookingNumber")}>Booking<SearchIndicator visible={sortField == "bookingNumber"} ascending={ascending}/></div>
                : null
        }
        {
                fieldsToShow.driverNumber && fieldsToShow.driverNumber.isShown ? <div style={{order: fieldsToShow.driverNumber.order}} name="driverNumber" title="Sorter" className="myTrips-driver" onClick={() =>handleSort("driverNumber")}>Sjåfør<SearchIndicator visible={sortField == "driverNumber"} ascending={ascending}/></div>
                : null
        }
        {
                fieldsToShow.permitNumber && fieldsToShow.permitNumber.isShown ? <div style={{order: fieldsToShow.permitNumber.order}} name="permitNumber" title="Sorter" className="myTrips-permit text-center" onClick={() =>handleSort("permitNumber")}>Løyve<SearchIndicator visible={sortField == "permitNumber"} ascending={ascending}/></div>
                :null
        }
        {
                fieldsToShow.from && fieldsToShow.from.isShown ? <div style={{order: fieldsToShow.from.order}} name="from.streetName" title="Sorter" className="myTrips-address" onClick={() =>handleSort("from.streetName")}>Fra<SearchIndicator visible={sortField == "from.streetName"} ascending={ascending}/></div>
                :null
        }
        {
                fieldsToShow.to && fieldsToShow.to.isShown ? <div style={{order: fieldsToShow.to.order}} name="to.streetName" title="Sorter" className="myTrips-address" onClick={() =>handleSort("to.streetName")}>Til<SearchIndicator visible={sortField == "to.streetName"} ascending={ascending}/></div>
                :null
        }
        {
                fieldsToShow.regionName && fieldsToShow.regionName.isShown ? <div style={{order: fieldsToShow.regionName.order}} name="regionName" title="Sorter" className="myTrips-region" onClick={() =>handleSort("regionName")}>Kjøreområde<SearchIndicator visible={sortField == "regionName"} ascending={ascending}/></div>
                :null
        }
        {
                fieldsToShow.price && fieldsToShow.price.isShown ? <div style={{order: fieldsToShow.price.order}} name="price" title="Sorter" className="myTrips-payment text-center" onClick={() =>handleSort("price")}>Beløp<SearchIndicator visible={sortField == "price"} ascending={ascending}/></div>
                :null
        }
        {
                fieldsToShow.bookingStatus && fieldsToShow.bookingStatus.isShown ? <div style={{order: fieldsToShow.bookingStatus.order}} name="bookingStatus" title="Sorter" className="myTrips-status text-center" onClick={() =>handleSort("bookingStatus")}>Status<SearchIndicator visible={sortField == "bookingStatus"} ascending={ascending}/></div>
                :null
        }
        {
                fieldsToShow.pickupTime && fieldsToShow.pickupTime.isShown ? <div style={{order: fieldsToShow.pickupTime.order, minWidth:'130px'}} name="pickupTime" title="Sorter" className="myTrips-pickup-time text-center" onClick={() =>handleSort("pickupTime")}>Hentetid<SearchIndicator visible={sortField == "pickupTime"} ascending={ascending}/></div>
                :null
        }
        {
                fieldsToShow.requestedPickupTime && fieldsToShow.requestedPickupTime.isShown ? <div style={{order: fieldsToShow.requestedPickupTime.order, minWidth:'130px'}} name="pickupTime" title="Sorter" className="myTrips-pickup-time text-center" onClick={() =>handleSort("requestedPickupTime")}>Hentetid<SearchIndicator visible={sortField == "requestedPickupTime"} ascending={ascending}/></div>
                :null
        }
                {
                fieldsToShow.numberOfPassengers && fieldsToShow.numberOfPassengers.isShown ? <div style={{order: fieldsToShow.numberOfPassengers.order, minWidth:'130px'}} name="numberOfPassengers" title="Sorter" className="myTrips-number-of-passengers text-center" onClick={() =>handleSort("numberOfPassengers")}>Passasjerer<SearchIndicator visible={sortField == "numberOfPassengers"} ascending={ascending}/></div>
                :null
        }
      </div>
      
        
        <div className="myTrips-table-content" onScroll={handleScroll}>
          {tripsHtml}
          
            {tripsLoading ?<div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <SemipolarLoading size="small" style={{ position: 'relative' }} />
                  </div> 
            :null}
        </div>

      <TripStatusDialog show={show} onCloseCb={handleClose} selectedTrip={selectedTrip} fieldsToShow={fieldsToShowDetails}></TripStatusDialog>
    </>
  )
}
export default MyTripsTable