import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {history,authHeader,config} from './../functions/_helpers'
import trip_home_icon from './../assets/image/web-icons/home.png'
import './../css/main.css'
import './../css/components/region.css'
import Section from '../components/dashboard/section'
import {regionService} from '../functions/services'
import RegionsTable from '../components/regions/regionsTable'
import RegionDetails from '../components/regions/regionDetails'

function Index(props) {
   const [regions, setRegions] = useState([]);
   const [selectedRegion, setSelectedRegion] = useState({});

   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
      
   useEffect(() => {
      // Get regions
      const fetchData = async () => {
         regionService.getRegions()
         .then(
            (response) => {
               setRegions(response); 
            },
            (error) => {
               console.log(error);
            });
      }
      fetchData();
      setSelectedRegion(null);
   } , []);
   
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }
   if(user_data.role != 1){
         history.push('/');
         return null;
   }
   
   
   const handleRegionSelected = function(region) {
      setSelectedRegion(region);
   }
   


   return (
      <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
         <div className="flex w-full trip_header ml-2" >
            <div className="col-6 text-left">
               <img src={trip_home_icon} alt="home" />
               <span className="font-bold"> <Link to='/' className="non-undeline-link">HJEM &nbsp; &nbsp; / &nbsp; </Link></span>
               <span className="font-bold uppercase"> Kjøreområder &nbsp; &nbsp; / &nbsp; </span>
            </div>
         </div>      
         <Section>
         <div className="trip_box ml-2 custom-add-item-border"  style= {{overflow: "auto", height:"80vh"}}>
            <h4 className="trip_title font-bold mb-3"> Kjøreområder </h4>
            <div className="regions-frame">
               <div className="regions-list">
                  <RegionsTable regionList={regions} onSelectedCb={handleRegionSelected}></RegionsTable>
               </div>
               <div className="regions-details">
                  <RegionDetails selectedRegion={selectedRegion}></RegionDetails>
               </div>
            </div>
         </div>
         </Section>
      </div>
   )
}
export default Index