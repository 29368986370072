import { authHeader, config, history } from '../_helpers';

export const regionService = {
  getRegions
}

function getRegions() {
   const requestOptions = {
       method: 'GET',
       headers: authHeader()
   };
    return fetch(process.env.REACT_APP_API_URL + '/api/regions', requestOptions).then(handleResponse, handleError);
 }
 




 function handleResponse(response) {
   return new Promise((resolve, reject) => {
       if (response.ok) {
           // return json if it was returned in the response
           var contentType = response.headers.get("content-type");
           if (contentType && contentType.includes("application/json")) {
               response.json().then(json => resolve(json));
           } else {
               reject();
               console.log(response);
           }
       } else {
           // return error message from response body
           response.text().then(text => reject(text));
           console.log(response);
           if(response.status === 401){
               localStorage.clear();
               history.push('/login');
           }
       }
   });
 }
 



 function handleError(error) {
   return Promise.reject(error && error.message);
 }