import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {FiX} from 'react-icons/fi'

export const Alert = ({
  outlined = false,
  raised = false,
  flat = false,
  rounded = false,
  borderLeft = false,
  icon = null,
  size = 'default',
  color,
  children,
  onClose
}) => {
  const [hidden, setHidden] = useState(false)

  const close = () => {
    if(typeof onClose === 'function'){
      onClose();
    }

    setHidden(!hidden);
  }
  
  let css = []
  css.push(color)
  if (outlined) css.push(`alert-outlined`)
  if (flat) css.push(`alert-flat`)
  if (raised) css.push('alert-raised')
  if (rounded) css.push('alert-rounded')
  if (hidden) css.push('alert-hidden')
  if (borderLeft) css.push('alert-border-left')
  css.push(`alert-${size}`)
  css = css.join(' ')
  return (
    <div className={`alert ${css}`}>
      <div className="flex-shrink">{icon}</div>
      <div className="flex-grow">{children}</div>
      <div className="flex-shrink">
        <button
          className="alert-close flex items-center justify-center"
          onClick={close}>
          <FiX className="stroke-current h-4 w-4" />
        </button>
      </div>
    </div>
  )
}

Alert.propTypes = {
  color: PropTypes.string,
  outlined: PropTypes.bool,
  raised: PropTypes.bool,
  flat: PropTypes.bool,
  rounded: PropTypes.bool,
  icon: PropTypes.any,
  children: PropTypes.any
}
