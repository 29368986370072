import React, { useState, useEffect} from 'react'
import Datatable from './datatable-permitHolder'
import Avatars from './avatars'
import { SemipolarLoading  } from 'react-loadingg'
import PermitUserInfo from './permitUserInfo'
import { history } from '../../functions/_helpers'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import TableMenu from './tableMenu'

const PermitHolders = (props) => {
   const [showEmptyTable,setShowEmptyTable] = useState(false)
   const [data, setData] = useState({ hits: [] });
   const [loading,setLoading] = useState(true);
   useEffect(() => {
      if (!loading && (props.permitHolders == null || props.permitHolders.length === 0)) {
         setShowEmptyTable(true);
      }
      else {
         setData(props.permitHolders);
         setShowEmptyTable(false);
      }
      setLoading(false);
   }, [props]);


   const editPermitHolder = function(id) {
      history.push('/permitHolderEdit/' + id);
   }
  
   const deletePermitHolder = function(id) {
      confirmAlert({
         title: '',
         message: 'Vil du slette løyvehaveren?',
         buttons: [
            {
            label: 'Ja',
            onClick: () => doDelete(id)
            },
            {
            label: 'Nei',
            onClick: () => doNothing()
            }
         ]
      });
   }
    
   function doNothing(){
      history.push('/permitholders');
   }
   
   function doDelete(id){
      props.onPermitHolderDelete(id);
   }
  

   const columns = React.useMemo(
      () => [
         {
               Header: '',
               accessor: 'profileImageUrl',
               Cell: props => <Avatars items={props.value} />
         },
         {
               Header: 'Løyvehaver',
               accessor: 'user',
               Cell: props => <PermitUserInfo items = {props.row}/>
         },
         {
               Header: 'E-post',
               accessor: 'email'
         },
         {
               Header: 'telefonnummer',
               accessor: 'phoneNumber'
         },
         {
               Header: 'kontonummer',
               accessor: 'accountNumber'
         },
         {
               Header: 'Firma',
               accessor: 'company'
         },
         {
               Header: 'Org. Nummer',
               accessor: 'vatNumber',
         },
         {
               Header: '',
               accessor: 'permitHolderId',
               Cell: props => <TableMenu id={props.value} editCb={editPermitHolder} deleteCb={deletePermitHolder}/>
         }
         
      ],
      []
   )

   const items = JSON.stringify(data);

   if(typeof(data) === 'undefined' || items === null  || items.length <= 0 || items.length === undefined || data.length <= 0 || data.length ===undefined){
      if(!showEmptyTable){
         return <SemipolarLoading/>
      }
   }

   if (showEmptyTable) {
      return(<div><h3 style={{textAlign:"center",margin:"0 auto",color:"#4663ac"}}>Ingen løyvehavere funnet!</h3></div>)
   }


   return (
      <Datatable columns={columns} data={data} sortById={'permitHolderId'} />
   )
    
}

export default PermitHolders

