import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import Loader from '../../components/loader'
import LeftSidebar1 from '../../components/left-sidebar-1'
import Navbar1 from '../../components/navbar-1'
import Backdrop from '../../components/backdrop'
import {NotificationContainer} from 'react-notifications'
import {CSSTransition} from 'react-transition-group'

const Layout1 = ({children}) => {
  const {config, palettes} = useSelector(
    state => ({
      config: state.config,
      palettes: state.palettes
    }),
    shallowEqual
  )
  const {layout, collapsed} = {...config}
  let {background, navbar, logo, leftSidebar, rightSidebar} = {
    ...palettes
  }

  return (
    <div
      data-layout={layout}
      data-collapsed={collapsed}
      data-background={background}
      data-navbar={navbar}
      data-logo={logo}
      data-left-sidebar={leftSidebar}
      data-right-sidebar={rightSidebar}
      className={`${background === 'dark' ? 'dark-mode' : 'default-mode'}`}>
      <Loader />
      <Backdrop />
      <NotificationContainer />
      <CSSTransition in={collapsed} timeout={300} classNames="collapse-layout">
        <div className="wrapper">
          <LeftSidebar1 />
          <div className={`main w-full min-h-screen `}>
            <Navbar1 />
            <div className="w-full mycontent">{children}</div>
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}
export default Layout1
