import React,{ useEffect } from 'react'
import {Router,Switch,Redirect,Route} from 'react-router-dom'
import Layouts from './layouts'
import Routes from './Routes'
import './css/tailwind.css'
import './css/main.css'
import './css/_components.css'
import './css/_layouts.css'
import './css/_palettes.css'
import {history} from './functions/_helpers'
import { useDispatch,useSelector  } from 'react-redux';
import ForgotPassword from './pages/forgot-password'
import {alertActions} from './functions/_actions'

const Wrapper = ({children}) => {
  return <Layouts>{children}</Layouts>
}

const App = () => {
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch();

  useEffect(() => {
      history.listen((location, action) => {
          // clear alert on location change
          dispatch(alertActions.clear());
      });
  }, []);

  return (
    <>
     
    <Router history={history}>
     <Switch>
      <Wrapper>
        <Routes />
      </Wrapper>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Redirect from="*" to="/" />
     </Switch>
    </Router>
    </>
  )
}
export default App
/**
 {alert.message &&
      <div className={`alert ${alert.type}`}>{alert.message}</div>
     }
 * 
 */