import { userConstants } from '../_constants';
import { userService } from '../services';
import { alertActions } from '.';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    register,
    updateUser,
    getAll,
    delete: _delete,
    createDriver,
    createCab,
    createLicense,
    createTrip,
    getTrip,
    updatePermit,
    addPermit,
    updateLicense,
    updateDriver,
    updateCab,
    deleteDriver,
    deleteLicense,
    deleteUser,
    deleteCab,
    updateSetting,
    systemChange
};

function login(email, userPassword) {
    return dispatch => {
        dispatch(request({ email }));

        userService.login(email, userPassword)
            .then(
                user => { 
                    dispatch(success(user));
                   // dispatch(userActions.getAll);
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
        );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout(userId,token) {
    userService.logout(userId,token);
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        if(user.role){
            var rolevalue = parseInt(user.role);
            user.role = rolevalue;
        }
        user.active = true;
        user.deleted = false;
        dispatch(request(user));
        userService.register(user)
        .then(
            () => { 
                dispatch(success());
                dispatch(alertActions.success('Created a driver successfully.'));
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );

    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getById(user){
    return dispatch => {
        dispatch(request());
        userService.getById()
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error))
            );
    };
    function request(user) { return { type: userConstants.GETALL_REQUEST } }
    function success(user) { return { type: userConstants.GETALL_SUCCESS } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE } }
}
function updateUser(user){
    return dispatch => {
        dispatch(request(user));

        userService.updateUser(user)
            .then(
                () => { 
                    dispatch(success());
                    //alert('løyvehaver oppdatert');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(license) { return { type: userConstants.REGISTER_REQUEST, license } }
    function success(license) { return { type: userConstants.REGISTER_SUCCESS, license } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
function deleteUser(user){
    return dispatch => {
        dispatch(request(user));

        userService.deleteUser(user)
            .then(
                () => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(license) { return { type: userConstants.REGISTER_REQUEST, license } }
    function success(license) { return { type: userConstants.REGISTER_SUCCESS, license } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
function getAll() {
    return dispatch => {
        dispatch(request());
        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
        
    };
    
    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}
function getAllPermit() {
    return dispatch => {
        dispatch(request());
        userService.getAllPermit()
            .then(
                permitHolders => dispatch(success(permitHolders)),
                error => dispatch(failure(error))
            );
        
    };
    
    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(permitHolders) { return { type: userConstants.GETALL_SUCCESS, permitHolders } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                () => { 
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function createDriver(driver) {
    //dispatch(request(driver.user));
    return dispatch => {

        dispatch(request(driver));

        userService.createDriver(driver)
            .then(
                () => { 
                    dispatch(success(driver));
                    //alert('Sjåfør har lagt');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(driver) { return { type: userConstants.REGISTER_REQUEST, driver } }
    function success(driver) { return { type: userConstants.REGISTER_SUCCESS, driver } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function createCab(car) {
    return dispatch => {
        dispatch(request(car));

        userService.createCab(car)
            .then(
                () => { 
                    dispatch(success());
                    //history.push('/users');
                    //alert('Taxi har lagt');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(car) { return { type: userConstants.REGISTER_REQUEST, car } }
    function success(car) { return { type: userConstants.REGISTER_SUCCESS, car } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
//løyve
function createLicense(license) {
    return dispatch => {
        dispatch(request(license));

        userService.createLicense(license)
            .then(
                () => { 
                    dispatch(success());
                    //alert('Løyvehaver har lagt');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(license) { return { type: userConstants.REGISTER_REQUEST, license } }
    function success(license) { return { type: userConstants.REGISTER_SUCCESS, license } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
function updateLicense(permitholder){
    return dispatch => {
        dispatch(request(permitholder));

        userService.updateLicense(permitholder)
            .then(
                () => { 
                    dispatch(success());
                    
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(permitholder) { return { type: userConstants.REGISTER_REQUEST, permitholder } }
    function success(permitholder) { return { type: userConstants.REGISTER_SUCCESS, permitholder } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
function deleteLicense(permitholder){
    return dispatch => {
        dispatch(request(permitholder));

        userService.deleteLicense(permitholder)
            .then(
                () => { 
                    dispatch(success());
                  
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(permitholder) { return { type: userConstants.REGISTER_REQUEST, permitholder } }
    function success(permitholder) { return { type: userConstants.REGISTER_SUCCESS, permitholder } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
function updatePermit(permit) {
    return dispatch => {
        dispatch(request(permit));

        userService.updatePermit(permit)
            .then(
                () => { 
                    dispatch(success());
                    //alert('løyve oppdatert');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(permit) { return { type: userConstants.REGISTER_REQUEST, permit } }
    function success(permit) { return { type: userConstants.REGISTER_SUCCESS, permit } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function updateDriver(driver){
    return dispatch => {
        dispatch(request(driver));

        userService.updateDriver(driver)
            .then(
                () => { 
                    dispatch(success());
                    //alert('sjåfør oppdatert');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(driver) { return { type: userConstants.REGISTER_REQUEST, driver } }
    function success(driver) { return { type: userConstants.REGISTER_SUCCESS, driver } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
function deleteDriver(driver){
    return dispatch => {
        dispatch(request(driver));

        userService.deleteDriver(driver)
            .then(
                () => { 
                    dispatch(success());
                   
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(driver) { return { type: userConstants.REGISTER_REQUEST, driver } }
    function success(driver) { return { type: userConstants.REGISTER_SUCCESS, driver } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
function updateCab(cab){
    return dispatch => {
        dispatch(request(cab));

        userService.updateCab(cab)
            .then(
                () => { 
                    dispatch(success());
                   // alert('Taxi oppdatert');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(cab) { return { type: userConstants.REGISTER_REQUEST, cab } }
    function success(cab) { return { type: userConstants.REGISTER_SUCCESS, cab } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
function deleteCab(cab){
    return dispatch => {
        dispatch(request(cab));

        userService.deleteCab(cab)
            .then(
                () => { 
                    dispatch(success());
                   
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(cab) { return { type: userConstants.REGISTER_REQUEST, cab } }
    function success(cab) { return { type: userConstants.REGISTER_SUCCESS, cab } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
function addPermit(permit) {
    return dispatch => {
        dispatch(request(permit));

        userService.addPermit(permit)
            .then(
                () => { 
                    dispatch(success());
                   // alert('løyve lagt');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(permit) { return { type: userConstants.REGISTER_REQUEST, permit } }
    function success(permit) { return { type: userConstants.REGISTER_SUCCESS, permit } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
// My Trips //
function createTrip(trip){
    return dispatch => {
        dispatch(request(trip));

        userService.createTrip(trip)
            .then(
                () => { 
                    dispatch(success());
                   
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(trip) { return { type: userConstants.REGISTER_REQUEST, trip } }
    function success(trip) { return { type: userConstants.REGISTER_SUCCESS, trip } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getTrip() {
    return dispatch => {
        dispatch(request());

        userService.getTrip()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(trip) { return { type: userConstants.GETALL_SUCCESS, trip } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function updateSetting(setting){
    return dispatch => {
        dispatch(request(setting));

        userService.updateSetting(setting)
            .then(
                () => { 
                    dispatch(success());
                   // alert('Taxi oppdatert');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(setting) { return { type: userConstants.REGISTER_REQUEST, setting } }
    function success(setting) { return { type: userConstants.REGISTER_SUCCESS, setting } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function systemChange(actionId){
    return dispatch => {
        dispatch(request(actionId));

        userService.systemChange(actionId)
            .then(
                () => { 
                    dispatch(success());
                   // alert('Taxi oppdatert');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(actionId) { return { type: userConstants.REGISTER_REQUEST, actionId } }
    function success(actionId) { return { type: userConstants.REGISTER_SUCCESS, actionId } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}