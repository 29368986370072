import React, { useState, useEffect } from "react";
import '../../css/components/my-trips.css'

const AccountSearch = ({showSearch, onSearchCb}) => {
   const [show, setShow] = useState(false);
   const [searchData, setSearchData, getSearchData] = useState({
      from:'',
      to:'',
      status:4,
      permitNumber:'',
      fromTime:null,
      toTime:null,
      bookingNumber:'',
      driverNumber:'',
      regionName:'',
      shiftNumber:'',
      phoneNumber:'',
      forceSearch:false
   })
  
   const [fromTime, setFromTime] = useState('');
   const [toTime, setToTime] = useState('');

   useEffect(()=>{
      setShow(showSearch);
   },[showSearch]);


   useEffect(()=>{
      if (searchData.forceSearch) {
         search_trip();
         setSearchData(searchData=> ({...searchData,forceSearch:false}));
      }
   },[searchData.forceSearch]);



   const search_trip = function() {
      onSearchCb(searchData.from, searchData.to, searchData.status, searchData.permitNumber, searchData.fromTime, searchData.toTime, searchData.bookingNumber, searchData.driverNumber, searchData.regionName, searchData.phoneNumber)
   }

   const handleChange = function(e) {
      const {name, value} = e.target;
      if (name == "status") {
         setSearchData(searchData=> ({...searchData,[name]:parseInt(value)}));
      }
      else {
         setSearchData(searchData=> ({...searchData,[name]:value}));
      }
   }

   const handleStatusChange = function(e) {
      const {name, value} = e.target;
      setSearchData(searchData=> ({...searchData,[name]:parseInt(value),forceSearch:true}));
   }

   const handleFromTimeSearch = function(e) {
      const { value} = e.target;
      setFromTime(value);
      if (value=='') {
         setSearchData(searchData=> ({...searchData,fromTime:null}));
      }
      else {
         setSearchData(searchData=> ({...searchData,fromTime:value}));
      }
   }

   const handleToTimeSearch = function(e) {
      const { value} = e.target;
      setToTime(value);
      if (value=='') {
         setSearchData(searchData=> ({...searchData,toTime:null}));
      }
      else {
         setSearchData(searchData=> ({...searchData,toTime:value}));
      }
   }

   const resetSearch = function() {
      setFromTime('');
      setToTime('');
      setSearchData(searchData=> ({...searchData,
         from:'',
         to:'',
         status:4,
         permitNumber:'',
         fromTime:null,
         toTime:null,
         bookingNumber:'',
         driverNumber:'',
         regionName:'',
         shiftNumber:'',
         phoneNumber:''
      }));
      setSearchData(searchData=> ({...searchData,forceSearch:true}));
   }


   return (
    <>
     {
          show ?
            <div className="w-full trip_search_box ml-2 custom-add-item-border" style={{overflowY:"auto",width:"98.8%"}}>
              <div name="form" className="form flex flex-wrap w-full">
                <h4 className="trip_title font-bold mb-3"> søk fullførte turer </h4>
                <div className="flex w-full trips" style={{marginTop:"-10px", marginBottom:"10px"}}>
                  {/**/}
                  <div className="myTrips-search-field flex-1">
                    <label className="font-bold block"> Fra Adresse </label>
                    <input type="text" style={{border:"1px solid #37708e"}} name="from" value={searchData.from} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
                  <div className="myTrips-search-field flex-1">
                    <label className="font-bold block"> Til Adresse </label>
                    <input type="text" style={{border:"1px solid #37708e"}} name="to" value={searchData.to} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
                  <div className="myTrips-search-field flex-1">
                    <label className="font-bold block"> Løyve </label>
                    <input type="text" style={{border:"1px solid #37708e"}} name="permitNumber" value={searchData.permitNumber} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
                  <div className="myTrips-search-field flex-1">
                    <label className="font-bold block"> Kjøreområde </label>
                    <input type="text" style={{border:"1px solid #37708e"}} name="regionName" value={searchData.regionName} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
                  <div className="myTrips-search-field flex-1">
                     {/* Free */}
                  </div>
                </div>
                <div className="flex w-full trips">
                  <div className="myTrips-search-field flex-1">
                  <label className="font-bold block" style={{marginTop:"0px",marginBottom:"-2px"}}> Fra </label>
                     <input type="datetime-local" id="turstartTime" style={{margin: ".4rem 0",border:"1px solid #37708e"}} name="fromTime"  className="mt-6" value={fromTime} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleFromTimeSearch} 
                           min="2018-06-07T00:00" max="2040-06-14T00:00"/>   
                  </div>
                  <div className="myTrips-search-field flex-1">
                  <label className="font-bold block" style={{marginTop:"0px",marginBottom:"-2px"}}> Til </label>
                     <input type="datetime-local" dateformat="d M y" id="tursluttTime" style={{margin: ".4rem 0",border:"1px solid #37708e"}} name="toTime" className="mt-6" value={toTime} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleToTimeSearch} 
                          min="2018-06-07T00:00" max="2040-06-14T00:00"/>
                  </div>
                  <div className="myTrips-search-field flex-1">
                    <label className="font-bold block"> Bookingnummer  </label>
                    <input type="text" style={{border:"1px solid #37708e"}} name="bookingNumber" value={searchData.bookingNumber} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
                  <div className="myTrips-search-field flex-1">
                    <label className="font-bold block"> Sjåførnummer </label>
                    <input type="number" style={{border:"1px solid #37708e"}} name="driverNumber" value={searchData.driverNumber} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
                  <div className="myTrips-search-field flex-1">
                    <label className="font-bold block"> Telefonnummer</label>
                    <input type="number" style={{border:"1px solid #37708e"}} name="phoneNumber" value={searchData.phoneNumber} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
                </div> 
                <div className="col-4 trip_btns text-right mt-10" style={{marginTop:"7px",left:"65%",display:"inline-flex"}}>
                  <button className="sign_btn mr-3 adjust-small" onClick={resetSearch}> nullstill </button>
                  <button className="sign_btn mr-3 adjust-small" onClick={search_trip}> søk </button>
                </div>
              </div>
            </div>
         :''
      }
    </>
  )
}
export default AccountSearch