import { authHeader, history } from '../_helpers';

export const uploadService = {
   upload
};

function upload(file) {
   var formData = new FormData();
   formData.append('file', file);

   const requestOptions = {
      method: 'POST',
      headers: {...authHeader(),
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*' },
      body: formData
   };

   return fetch(process.env.REACT_APP_API_URL + '/api/upload', requestOptions).then(handleResponse, handleError); 
}

function handleResponse(response) {
   return new Promise((resolve, reject) => {
      if (response.ok) {
         response.json().then(json => resolve(json));
       } else {
          console.log(response);
           // return error message from response body
           response.text().then(text => reject(text));
           if(response.status === 401){
               localStorage.clear();
               history.push('/login');
           }
       }
   });
}

function handleError(error) {
   return Promise.reject(error && error.message);
}