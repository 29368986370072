import React, { useRef, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Section from '../components/dashboard/section'
import PermitHolders from '../components/dashboard/permitHolders'
import trip_home_icon from '../assets/image/web-icons/home.png'
import './../css/main.css'
import { history } from '../functions/_helpers'
import {FiSearch} from 'react-icons/fi'
import _ from "lodash";
import jwt from 'jwt-decode'
import {permitHolderService} from '../functions/services'

function Index() {
   const addBtns = useRef(null);
   const searchIcon = useRef(null);
   

   const [permitHolders, setPermitHolders] = useState([]);
   const [filteredPermitHolders, setFilteredPermitHolders] = useState([]);
   const [searchString, setSearchString] = useState('');
   const permitHoldersRef = useRef();
   const filteredPermitHoldersRef = useRef();

   permitHoldersRef.current = permitHolders;
   filteredPermitHoldersRef.current = filteredPermitHolders;

/* Check access rights. Only admin (1) is allowed */
   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }

    // Check Role
   const decodedToken = jwt(user_data.token);
   if (decodedToken == null || decodedToken == undefined) {
         localStorage.clear();
         history.push('/login');  
   }
   const role = parseInt(decodedToken.role);
   if(role !== 1){
      history.push('/');
   }
        
   useEffect(() => {
      const fetchData = async () => {        
         await permitHolderService.getPermitHolders()            
         .then((response)=>{
            setPermitHolders(response);
            setFilteredPermitHolders(response);
         }).catch((error)=>{
            console.log(error.message);
         });                  
      };

      fetchData();
   }, []);

 
   const handleSearch= (e) => {
      const { name, value } = e.target;
      setSearchString(value);
      filterPermitHolders(permitHolders, value);
   }

   const filterPermitHolders = (array, filter) => {
      setFilteredPermitHolders(array.filter(permitHolder => 
         (permitHolder.company != null && permitHolder.company.toLowerCase().indexOf(filter) >= 0) ||
         (permitHolder.firstName != null && permitHolder.firstName.toLowerCase().indexOf(filter) >= 0) ||
         (permitHolder.lastName != null && permitHolder.lastName.toLowerCase().indexOf(filter) >= 0) ||
         (permitHolder.email != null && permitHolder.email.toLowerCase().indexOf(filter) >= 0) ||
         (permitHolder.phoneNumber != null && permitHolder.phoneNumber.toLowerCase().indexOf(filter) >= 0)
         ));
   }


   const onPermitHolderDelete = (id) => {
      permitHolderService.deletePermitHolder(id)
      .then(
         (response) => {
            setPermitHolders(permitHoldersRef.current.filter(permitHolder => permitHolder.permitHolderId !== id));
            setFilteredPermitHolders(filteredPermitHoldersRef.current.filter(permitHolder => permitHolder.permitHolderId !== id));
         },
         (error) => {
            console.log(error);
         }
      )
   }

   const addBox = () => {
        history.push('/permitHolderEdit/0');
   }
    
   return (
      <>
         <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
               <div className="flex w-full trip_header ml-2">
                  <div className="col-6 text-left">
                     <img src={trip_home_icon} alt="home" />
                     <span className="font-bold"> <Link to='/' className="non-undeline-link">HJEM &nbsp; &nbsp; / &nbsp; </Link></span>
                     <span className="font-bold uppercase"> <Link to='/users' className="non-undeline-link" >Løyvehavere &nbsp; &nbsp; / &nbsp; </Link></span>
                  </div>
                  <div className="col-6 text-right" style={{display: "inline-flex"}}>

                     <input type="search" placeholder="Søk"  onChange={handleSearch} className="pl-10 pr-5 appearance-none h-10 w-full rounded-full text-sm focus:outline-none simple_sorter_btn_search" />
                     <button type="submit" className="absolute top-0 mt-3 left-0 ml-4 top-adjust" ref={searchIcon} style={{top:"-6px"}}>
                           <FiSearch className="stroke-current h-4 w-4" />
                     </button>
                     <button hidden={role != 1}  type="button" className="add_btn" ref={addBtns} id="addBtn" onClick={() => addBox()}> legg til </button>
                  </div>
               </div>
               <Section>
                  <PermitHolders permitHolders={filteredPermitHolders} onPermitHolderDelete={onPermitHolderDelete} />
               </Section>

         </div>
      </>
    )
}
export default Index
