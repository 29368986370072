import React, { Component } from 'react';
import { MdStar,MdStarBorder,MdStarHalf } from "react-icons/md";
import './../../css/main.css' 
class DriverRating extends Component {
    constructor(props) {
        super(props);

    }
    render() {
    const {items} = this.props;
    const x = parseInt(items);
        return (
           
                <div className="flex flex-row items-center justify-start" style={{fontSize: "12px"}}>
                    {(function() {
                    switch (true) {
                    case(x<0):
                        return <span>{items} <MdStarHalf className="starPosition" /><MdStarBorder className="starPosition"/><MdStarBorder className="starPosition"/><MdStarBorder className="starPosition"/><MdStarBorder className="starPosition"/></span>;         
                    case (x<10):
                        return <span>{items} <MdStar className="starPosition" /><MdStarBorder className="starPosition"/><MdStarBorder className="starPosition"/><MdStarBorder className="starPosition"/><MdStarBorder className="starPosition"/></span>;
                    case (x<20):
                        return <span>{items} <MdStar className="starPosition"/><MdStar className="starPosition"/><MdStarBorder className="starPosition"/><MdStarBorder className="starPosition"/><MdStarBorder className="starPosition"/></span>;
                    case (x<30): 
                        return <span>{items} <MdStar className="starPosition"/><MdStar className="starPosition"/><MdStar className="starPosition"/><MdStarBorder className="starPosition"/><MdStarBorder className="starPosition"/></span>;
                    case (x<50):
                        return <span>{items} <MdStar className="starPosition"/><MdStar className="starPosition"/><MdStar className="starPosition"/><MdStar className="starPosition"/><MdStarBorder className="starPosition"/></span>;
                    case (x<100):
                        return <span>{items} <MdStar className="starPosition"/><MdStar className="starPosition"/><MdStar className="starPosition"/><MdStarHalf className="starPosition"/><MdStarBorder className="starPosition"/></span>;
                    default:
                        return <span>{items} <MdStar className="starPosition"/><MdStar className="starPosition"/><MdStar className="starPosition"/><MdStar className="starPosition"/><MdStar className="starPosition"/></span>;        
                     }
                })()}

           </div>
        );
    }
}

export default DriverRating;