import React, { useState, useEffect,useRef } from 'react'
import {Link} from 'react-router-dom'
import Section from '../components/dashboard/section'
import Cabs from '../components/dashboard/cabs'
import trip_home_icon from '../assets/image/web-icons/home.png'
import './../css/main.css'
import {history} from './../functions/_helpers'
import {FiSearch} from 'react-icons/fi'
import jwt from 'jwt-decode'
import {cabService} from '../functions/services'


function Index(props) {
   const addBtns = useRef(null);
   const searchIcon = useRef(null);

   const [cabs, setCabs] = useState([]);
   const [filteredCabs, setFilteredCabs] = useState([]);
   const [searchString, setSearchString] = useState('');
   const cabsRef = useRef();
   const filteredCabsRef = useRef();

   cabsRef.current = cabs;
   filteredCabsRef.current = filteredCabs;

   /* Check access rights. Only admin (1) is allowed */
   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }

   // Check Role
   const decodedToken = jwt(user_data.token);
   if (decodedToken == null || decodedToken == undefined) {
         localStorage.clear();
         history.push('/login');  
   }

   const role = parseInt(decodedToken.role);
   if(role !== 1 && role != 2){
      history.push('/');
   }

   useEffect(() => {
      const fetchData = async () => {        
         if(role === 2){
            /* Permitholder */
            await cabService.getCabs(user_data.id)            
            .then((response)=>{
               setCabs(response);
               setFilteredCabs(response);
            }).catch((error)=>{
               console.log(error.message);
            });                  
         }
         else{
            /* Admin */
            await cabService.getCabs(0)  
            .then((response)=>{
               setCabs(response);
               setFilteredCabs(response);
            }).catch((error)=>{
               console.log(error.message);
            });           
         }
      };

      fetchData();
   }, []);



   const handleSearch= (e) => {
      const { name, value } = e.target;
      setSearchString(value);
      filterCabs(cabs, value);
   }

   
   const filterCabs = (array, filter) => {
      setFilteredCabs(array.filter(cab => 
         (cab.region != null && cab.region.toLowerCase().indexOf(filter) >= 0) ||
         (cab.licenseNumber != null && cab.licenseNumber.toLowerCase().indexOf(filter) >= 0) ||
         (cab.permit.permitNumber != null && cab.permit.permitNumber.toLowerCase().indexOf(filter) >= 0) ||
         (cab.permitHolder != null && cab.permitHolder.toLowerCase().indexOf(filter) >= 0) ||
         (cab.carType != null && cab.carType.toLowerCase().indexOf(filter) >= 0) ||
         (cab.brand != null && cab.brand.toLowerCase().indexOf(filter) >= 0)
         ));
   }


   const onCabDelete = (id) => {
      cabService.deleteCab(id)
      .then(
         (response) => {
            setCabs(cabsRef.current.filter(cab => cab.cabId !== id));
            setFilteredCabs(filteredCabsRef.current.filter(cab => cab.cabId !== id));
         },
         (error) => {
            console.log(error);
         }
      )
   }

   const addBox = () => {    
      history.push('/cabEdit/0');
      return;
   }

   return (    
      <>
         <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
            <div className="flex w-full trip_header ml-2">
               <div className="col-6 text-left">
                  <img src={trip_home_icon} alt="home" />
                  <span className="font-bold"> <Link to='/' className="non-undeline-link">HJEM &nbsp; &nbsp; / &nbsp; </Link></span>
                  <span className="font-bold uppercase"> BILER &nbsp; &nbsp; / &nbsp; </span>
               </div>
               <div className="col-6 text-right inline-flex">
                  <input type="search" placeholder="Søk" onChange={handleSearch} className="pl-10 pr-5 appearance-none h-10 w-full rounded-full text-sm focus:outline-none simple_sorter_btn_search" />
                  <button type="submit" className="absolute top-0 mt-3 left-0 ml-4 top-adjust" ref={searchIcon} style={{top:"-6px"}}>
                        <FiSearch className="stroke-current h-4 w-4"/>
                  </button>
                  <button hidden={user_data.role != 1} type="button" className="add_btn" ref={addBtns} id="addBtn" onClick={() => addBox()}> legg til </button>
               </div>
            </div>
               
            <Section>
               <Cabs cabs={filteredCabs} onCabDelete={onCabDelete}/>
            </Section>
         </div>
      </>
   )
}
export default Index
