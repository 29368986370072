import React, { useState, useEffect } from 'react';
import CabRow from './cabRow'
import {cabService} from '../../functions/services'
import { confirmAlert } from 'react-confirm-alert'; 

export default function CabList({ data, onCabSelected }) {

   const [sortedCabs, setSortedCabs] = useState([]);

   useEffect(() => {
      //Sort
      var connectedLast = data.sort((a,b) => 
      {
         if (Number(b.connected) > Number(a.connected)) {
            return 1;
        } else if (Number(a.connected) > Number(b.connected)) { 
            return -1;
        }

        return (b.cabStatus - a.cabStatus)
      });
      setSortedCabs(connectedLast);
   });

   const onCabStatusEdit = (cabId) => {
      confirmAlert({
         title: '',
         message: 'Vil du resette automatisk pause for denne sjåføren?',
         buttons: [
            {
               label: 'Ja',
               onClick: () => cabService.resetBreak(cabId)
            },
            {
               label: 'Nei',
               onClick: () => {}
            }
         ]
         });

   }


   const cabsHtml = sortedCabs.map(data => { 
      return <CabRow cabData={data} onCabSelected={onCabSelected} onCabStatusEdit={onCabStatusEdit}></CabRow>
   });


   return ( 
      <div className="cab-list-frame">
         <div className="cab-list-heading">Biler</div>
         <div className="display-flex cab-list-table-header">
            <div className="flex-1 cab-list-table-heading">Status</div>
            <div className="flex-1 cab-list-table-heading">Reg. nr.</div>
            <div className="flex-2 cab-list-table-heading">Løyve nr.</div>
            <div className="flex-1 cab-list-table-heading">Sjåfør nr.</div>
            <div className="flex-3 cab-list-table-heading">Sjåfør</div>
            <div className="flex-1 cab-list-table-heading">Versjon</div>
         </div>

         {cabsHtml}

      </div>
   )
}