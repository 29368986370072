import React, { useState, useEffect } from "react";
import '../../css/components/my-trips.css'
import moment from 'moment'

const WorkShiftsSearch = ({showSearch, onSearchCb}) => {
   const [show, setShow] = useState(false);
   const [searchData, setSearchData, getSearchData] = useState({
      from:null,
      to:null,
      permitNumber:'',
      driverNumber:'',
      regionName:'',
      shiftNumber:'',
      forceSearch:false
   })
  
   const [from, setFrom] = useState('');
   const [to, setTo] = useState('');

   useEffect(()=>{
      setShow(showSearch);
   },[showSearch]);


   useEffect(()=>{
      if (searchData.forceSearch) {
         search_trip();
         setSearchData(searchData=> ({...searchData,forceSearch:false}));
      }
   },[searchData.forceSearch]);



   const search_trip = function() {
      console.log(searchData);
      onSearchCb(searchData.from, searchData.to, searchData.permitNumber, searchData.driverNumber, searchData.regionName, searchData.shiftNumber)
   }

   const handleChange = function(e) {
      const {name, value} = e.target;
      if (name == "status") {
         setSearchData(searchData=> ({...searchData,[name]:parseInt(value)}));
      }
      else {
         setSearchData(searchData=> ({...searchData,[name]:value}));
      }
   }

   const handleStatusChange = function(e) {
      const {name, value} = e.target;
      setSearchData(searchData=> ({...searchData,[name]:parseInt(value),forceSearch:true}));
   }

   const handleFromTimeSearch = function(e) {
      const { value} = e.target;
      setFrom(value);
      if (value=='') {
         setSearchData(searchData=> ({...searchData,from:null}));
      }
      else {
         setSearchData(searchData=> ({...searchData,from:value}));
      }
   }

   const handleToTimeSearch = function(e) {
      const { value} = e.target;
      setTo(value);
      if (value=='') {
         setSearchData(searchData=> ({...searchData,to:null}));
      }
      else {
         setSearchData(searchData=> ({...searchData,to:value}));
      }
   }

   const resetSearch = function() {
      setFrom('');
      setTo('');
      setSearchData(searchData=> ({...searchData,
         from:null,
         to:null,
         permitNumber:'',
         driverNumber:'',
         regionName:'',
         shiftNumber:'',
      }));
      setSearchData(searchData=> ({...searchData,forceSearch:true}));
   }


   return (
    <>
     {
          show ?
         <div className="w-full trip_search_box ml-2 custom-add-item-border" style={{overflowY:"auto",width:"98.8%"}}>
            <div name="form" className="form flex flex-wrap w-full">
               <h4 className="trip_title font-bold mb-3"> søk skift </h4>
               <div className="flex w-full trips" style={{marginTop:"-10px", marginBottom:"10px"}}>
                  {/**/}
                  <div className="myTrips-search-field flex-1">
                     <label className="font-bold block" style={{marginTop:"0px",marginBottom:"-2px"}}> Fra </label>
                     <input type="datetime-local" id="turstartTime" style={{margin: ".4rem 0",border:"1px solid #37708e"}} name="from"  className="mt-6" value={from} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleFromTimeSearch} 
                        min="2018-06-07T00:00" max="2040-06-14T00:00"/>   
                  </div>
                  <div className="myTrips-search-field flex-1">
                     <label className="font-bold block" style={{marginTop:"0px",marginBottom:"-2px"}}> Til </label>
                     <input type="datetime-local" dateformat="d M y" id="tursluttTime" style={{margin: ".4rem 0",border:"1px solid #37708e"}} name="to" className="mt-6" value={to} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleToTimeSearch} 
                           min="2018-06-07T00:00" max="2040-06-14T00:00"/>
                  </div>
                  <div className="myTrips-search-field flex-1">
                     <label className="font-bold block"> Løyve </label>
                     <input type="text" style={{border:"1px solid #37708e"}} name="permitNumber" value={searchData.permitNumber} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
                  <div className="myTrips-search-field flex-1">
                     <label className="font-bold block"> Skiftnummer </label>
                     <input type="text" style={{border:"1px solid #37708e"}} name="shiftNumber" value={searchData.shiftNumber} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
                  <div className="myTrips-search-field flex-1">
                     <label className="font-bold block"> Sjåførnummer </label>
                     <input type="number" style={{border:"1px solid #37708e"}} name="driverNumber" value={searchData.driverNumber} onKeyPress={event => { if (event.key === 'Enter') {search_trip()}}} onChange={handleChange} />
                  </div>
               </div>
               <div className="col-4 trip_btns text-right mt-10" style={{marginTop:"7px",left:"65%",display:"inline-flex"}}>
                  <button className="sign_btn mr-3 adjust-small" onClick={resetSearch}> nullstill </button>
                  <button className="sign_btn mr-3 adjust-small" onClick={search_trip}> søk </button>
               </div>
            </div>
         </div>
         :''
      }
    </>
  )
}
export default WorkShiftsSearch