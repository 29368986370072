import React from 'react';
import Logo from "../../assets/image/logo/ridel_logo_vector.svg";
import './../../css/main.css'
const Index = ({title, subtitle, children}) => {
  return (
    <div className="form-scroll flex flex-col bg-transparent p-8 w-full max-w-lg">
      <div className="text-center" style={{marginTop:"-60px"}}>
        <img src={Logo} alt="logo" className="logo"/>
      </div>
      {/*<div className="flex flex-col w-full mb-4">
        <h4 className="uppercase font-bold title">{title}</h4>
        <div className="text-xl font-bold subtitle">{subtitle}</div>
  </div>*/}
      {children}
    </div>
  )
}


export default Index
