import React, { useState, useEffect } from "react";
import '../../css/components/region.css'
import L from 'leaflet';
import 'react-leaflet-fullscreen/dist/styles.css'
import FullscreenControl from 'react-leaflet-fullscreen';
import axios from 'axios'
import {history,authHeader,config} from './../../functions/_helpers'
import Setting from '../dashboard/setting'

// styles
import useStyles from "./styles";

const Regiondetails = ({selectedRegion}) => {
   var classes = useStyles();
   const mapRef = React.useRef(null);
   const layerRef = React.useRef(null); 
   const [regionName, setRegionName] = useState('Velg kjøreområde');
   const [settings,setSettings]= useState([]);

   var regionId=0;
   var latitude=58.14671;
   var longitude=7.9956;

   // create map
   React.useEffect(() => {
      mapRef.current = L.map("map", {
         center: [latitude, longitude],
         zoom: 12,
         layers: [
            L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            attribution:
               '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            })
         ],
         fullscreenControl: true,
         fullscreenControlOptions: {
            position: 'topleft'
         }
      });
   }, []);

   React.useEffect(() => {
      if (selectedRegion !== null && selectedRegion.latitude !== undefined && selectedRegion.longitude !== undefined) {
         //regionName = selectedRegion.name;
         latitude = selectedRegion.latitude;
         longitude = selectedRegion.longitude;
         regionId = selectedRegion.regionId;
         setRegionName(selectedRegion.name);

         mapRef.current.eachLayer(function(layer){
            console.log(layer);
            if (layer.options.regionId > 0) {
               layer.remove();
            }
         });

         mapRef.current.flyTo(new L.LatLng(latitude, longitude));

         var latlong = {lat: latitude, lng: longitude};
         L.marker(latlong, {icon:new UnknownIcon, title: selectedRegion.name, regionId:regionId }).addTo(layerRef.current);

         fetchData(regionId);
      }

   },[selectedRegion]);
   


   // add layer
   React.useEffect(() => {
      layerRef.current = L.layerGroup().addTo(mapRef.current);
   }, []);


   const fetchData = async (regionId) => {
      console.log(regionId);
      if (regionId > 0) {


         await axios.get(process.env.REACT_APP_API_URL+'/api/settings/' + regionId,{headers:authHeader()}
         ).then((response)=>{
            console.log(response.data);
            // let groups = response.data.reduce((r, a) => {
            //    r[a.group] = [...r[a.group] || [], a];
            //    return r;
            // }, {});
            
            setSettings(response.data);
         }).catch((error)=>{
            if(error.response){
               console.log(error.response.data);
            }else if (error.request){
               console.log(error.request);
            }else{
               console.log(error.message);
            }
         });
      }
   }






   var UnknownIcon = L.Icon.extend({
   options: {
         iconUrl: '/markers/marker_unknown.svg',
         iconSize: [48,48],
         iconAnchor: [24, 48]
   }
   });


   return (
      <>
         <div className="region-detail-frame">
            <div className="flex-1">
               <div className="settings-group">Parametere</div>
               {
               settings.map((setting)=>
               {
                  return (
                  <Setting setting={setting} slim={1}></Setting>
                  )
               })
               }
            </div>
            <div className="flex-1">
               <div className="region-info-container">
                  <div className="region-info-name">{regionName}</div>
               </div>
               <div className="region-map-container">
                  <div id="map" className={classes.mapLayout}/>
               </div>
               <div className=""></div>
            </div>



         </div>

      </>
   )
}
export default Regiondetails