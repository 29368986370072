import { authHeader, history } from '../_helpers';

export const permitHolderService = {
   getPermitHolders,
   getPermitHolder,
   putPermitHolder,
   postPermitHolder,
   deletePermitHolder
}


function getPermitHolders() {
   const requestOptions = {
         method: 'GET',
         headers: authHeader()
   };
   return fetch(process.env.REACT_APP_API_URL + '/api/permitholders/', requestOptions).then(handleResponse, handleError);
}


function getPermitHolder(id) {
   const requestOptions = {
         method: 'GET',
         headers: authHeader()
   };
   return fetch(process.env.REACT_APP_API_URL + '/api/permitholders/' + id, requestOptions).then(handleResponse, handleError);
}

function putPermitHolder(permitHolder){
   const requestOptions = {
      method: 'PUT',
      headers: {...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(permitHolder)
   };

   console.log(requestOptions.body);
   return fetch(process.env.REACT_APP_API_URL + '/api/permitholders/' + permitHolder.permitHolderId, requestOptions).then(handleResponse, handleError);
}


function postPermitHolder(permitHolder){
   const requestOptions = {
      method: 'POST',
      headers: {...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(permitHolder)
   };

   return fetch(process.env.REACT_APP_API_URL + '/api/permitholders' , requestOptions).then(handleResponse, handleError);
}

function deletePermitHolder(id){
   const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
   };
   return fetch(process.env.REACT_APP_API_URL + '/api/permitholders/' + id, requestOptions).then(handleResponse, handleError);
}


function handleResponse(response) {
return new Promise((resolve, reject) => {
      if (response.ok) {
         // return json if it was returned in the response
         var contentType = response.headers.get("content-type");
         if (contentType && contentType.includes("application/json")) {
               response.json().then(json => resolve(json));
         } else {
               resolve();
         }
      } else {
         // return error message from response body
         response.text().then(text => reject(text));
         console.log(response);
         if(response.status === 401){
            localStorage.clear();
            history.push('/login');
         }
      }
});
}
 



function handleError(error) {
   return Promise.reject(error && error.message);
}