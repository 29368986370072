import React from 'react'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'

const Backdrop = () => {
  const {config} = useSelector(
    state => ({
      config: state.config
    }),
    shallowEqual
  )
  let {backdrop} = {...config}
  const dispatch = useDispatch()

  return (
    <div
      className={`backdrop ${backdrop ? 'fade-in' : ''}`}
      onClick={() => {
        dispatch({
          type: 'SET_CONFIG_KEY',
            key: 'backdrop',
            value: !backdrop
        })
      }}></div>
  )
}

export default Backdrop
