import React from 'react';
import CabStatus from './cabStatus'
import AppVersion from '../dashboard/appVersion'
import {DbIcon} from '../icons/dbIcons'
import { PROPERTY_TYPE } from '../../functions/_constants/trip.constants';

export default function CabRow({cabData, onCabSelected, onCabStatusEdit}) {

  const driverProps = [];

  if (cabData.driverProperties != null &&  Array.isArray(cabData.driverProperties)){
    cabData.driverProperties.forEach(p => {
      const icon = p.icon;
      if (p.propertyType == PROPERTY_TYPE.b.name && p.value == 1) {
        driverProps.push(<div style={{marginRight:'5px'}}><DbIcon icon={icon}/></div>);
      }
    });
  }

  return (
   <div className="display-flex cab-list-table-data" title="Sentrer i kart">
      <div className="flex-1" ><CabStatus cabId={cabData.cabId} 
                           cabStatus={cabData.cabStatus} 
                           connected={cabData.connected} 
                           queueSize={cabData.queueSize} 
                           forcedBreak={cabData.forcedBreak} 
                           driver={cabData.driver} 
                           atHome={cabData.atHome}
                           onCabSelected={onCabSelected} 
                           onCabStatusEdit={onCabStatusEdit}
                           category={cabData.category}></CabStatus></div>
      <div className="flex-1" onClick={() =>onCabSelected(cabData.cabId)}>{cabData.licenseNumber}</div>
      <div className="flex-2" onClick={() =>onCabSelected(cabData.cabId)}>{cabData.permitNumber}</div>
      <div className="flex-1" onClick={() =>onCabSelected(cabData.cabId)}>{cabData.driverNumber}</div>
      <div className="flex-3 cab-driver-icon-wrapper" onClick={() =>onCabSelected(cabData.cabId)}>
        {cabData.driverName} 
        {
          driverProps.length > 0 ? <div className="cab-driver-icon">
            {driverProps}
          </div>: <div style={{height:'15px'}}></div>
        }
        
      </div>
      <div className="flex-1" onClick={() =>onCabSelected(cabData.cabId)}><AppVersion items = {[cabData.appVersion]}/></div>
   </div>
  );
}
