import { authHeader, config, history } from '../_helpers';

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    updateUser,
    delete: _delete,
    createDriver,
    createCab,
    createLicense,
    createTrip,
    getTrip,
    updatePermit,
    addPermit,
    updateLicense,
    updateDriver,
    updateCab,
    getAllPermit,
    deleteDriver,
    deleteCab,
    deleteLicense,
    deleteUser,
    updateSetting,
    systemChange
};

function login(email, userPassword) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({ email, userPassword })
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/users/Authenticate', requestOptions)
        .then(handleResponse, handleError)
        .then(user => {
            // login successful if there's a jwt token in the response
         
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            
            return user;
        });
}

function logout(userId,token) {
  
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(),'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify(userId,token)
    };
      // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.clear();
    return fetch(process.env.REACT_APP_API_URL + '/api/users/logout', requestOptions).then(handleResponse, handleError); 
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/users/getall', requestOptions).then(handleResponse, handleError);
}
function getAllPermit() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/PermitHolders', requestOptions).then(handleResponse, handleError);
}
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/users/' + id, requestOptions).then(handleResponse, handleError);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(),'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(process.env.REACT_APP_API_URL + '/api/users/register', requestOptions).then(handleResponse, handleError);
}

function updateUser(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/users/' + user.userId, requestOptions).then(handleResponse, handleError);
}
function deleteUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/users/' + id, requestOptions).then(handleResponse, handleError);
}
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/users/' + id, requestOptions).then(handleResponse, handleError);
}

function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
            if(response.status === 401){
                localStorage.clear();
                history.push('/login');
            }
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}

function createDriver(driver) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(driver)
    };
    console.log(requestOptions);
    // return "ok";
    return fetch(process.env.REACT_APP_API_URL + '/api/drivers', requestOptions).then(handleResponse, handleError);    
}


function createCab(car) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(),'Content-Type': 'application/json' },
        body: JSON.stringify(car)
    };
    
    return fetch(process.env.REACT_APP_API_URL + '/api/cabs', requestOptions).then(handleResponse, handleError);    
}
function addPermit(permit) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(),'Content-Type': 'application/json' },
        body: JSON.stringify(permit)
    };
    
    return fetch(process.env.REACT_APP_API_URL + '/api/permits', requestOptions).then(handleResponse, handleError);    
}
function updatePermit(permit){ // før å oppdatere løyve
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(permit)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/permits/' + permit.permitId, requestOptions).then(handleResponse, handleError);
}
function updateLicense(permitHolder){ // for å oppdatere løyvehavere
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(permitHolder)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/permitholders/' + permitHolder.permitHolderId, requestOptions).then(handleResponse, handleError);
}
function deleteLicense(permitHolder){ // for å oppdatere løyvehavere
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(permitHolder)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/permitholders/' + permitHolder.permitHolderId, requestOptions).then(handleResponse, handleError);
}
function updateDriver(driver){ // for å oppdatere løyvesjåfør
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(driver)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/drivers/' + driver.driverId, requestOptions).then(handleResponse, handleError);
}
function deleteDriver(driver){
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(driver)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/drivers/' + driver.driverId, requestOptions).then(handleResponse, handleError);
}
function updateCab(cab){ // for å oppdatere cabs
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(cab)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/cabs/' + cab.cabId, requestOptions).then(handleResponse, handleError);
}
function deleteCab(cab){ // for å oppdatere cabs
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(cab)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/cabs/' + cab.cabId, requestOptions).then(handleResponse, handleError);
}
function createLicense(license) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(license)
    };
    console.log(requestOptions);
    // return "ok";
    return fetch(process.env.REACT_APP_API_URL + '/api/permitHolders', requestOptions).then(handleResponse, handleError);    
}
function createTrip(trip) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(),'Content-Type': 'application/json'},
        body: JSON.stringify(trip)
    };
    console.log(requestOptions);
    // return "ok";
    return fetch(process.env.REACT_APP_API_URL + '/api/TripAssignments', requestOptions).then(handleResponse, handleError);    
}

function getTrip() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/TripAssignments', requestOptions).then(handleResponse, handleError);
}

function updateSetting(setting){ 
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(setting)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/settings/' + setting.settingId, requestOptions).then(handleResponse, handleError);
}

function systemChange(actionId){ 
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/settings/' + actionId, requestOptions).then(handleResponse, handleError);
}