import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {myTripsService} from '../../../functions/services'
import FormatUtc from '../../dashboard/Tools/formatUtc'
import TripStatus from './tripStatus'
import TripPayment from './tripPayment'
import { FaTaxi, FaCircle, FaHamburger, FaSchool } from "react-icons/fa";

const fieldsToShowDefault = {
   permitNumber: { isShown: true},
   company:  { isShown: true},
   driverNumber:  { isShown: true},
   price:  { isShown: true},
   distance:  { isShown: true},
   numberOfPassengers: {isShown:true},
   cancelReason:  { isShown: true},
   traveller:  { isShown: true},
   messageToDriver:  { isShown: true},
   pickupTime: {isShown: false},
   tripLog:  { isShown: true},
}

const TripStatusDialog = ({show, onCloseCb, selectedTrip, fieldsToShow=fieldsToShowDefault}) => {
   const [tripLog, setTripLog] = useState([]);
   const [trip, setTrip] = useState({});
   const [stops, setStops] = useState([]);
   const [permitDetails, setPermitDetails] = useState({});
   const [travellerDetails, setTravellerDetails] = useState({});

   useEffect(() => {
      // load data
      setTrip(selectedTrip);
      if (selectedTrip !== null && selectedTrip.bookingId > 0) {
         fetchData();
      }
   }, [selectedTrip])

   const fetchData = async () => {
      myTripsService.getTripLog(selectedTrip.bookingId)
      .then(
         (response) => {
            setTripLog(response);      
         },
         (error) => {
            console.log(error);
         });

      myTripsService.getStops(selectedTrip.bookingId)
      .then(
         (response) => {
            setStops(response);      
         },
         (error) => {
            console.log(error);
         });

      myTripsService.getPermitDetails(selectedTrip.permitNumber)
      .then(
         (response) => {
            setPermitDetails(response);      
         },
         (error) => {
            console.log(error);
         });

      myTripsService.getTravellerDetails(selectedTrip.traveller)
      .then(
         (response) => {
            setTravellerDetails(response);      
         },
         (error) => {
            console.log(error);
         });
   }


   // Fetch booking details
   const handleClose = () => {
      onCloseCb();
   }

   const getIconColor = function(trip) {
      if (trip.isManual) {
        return "blue";
      }
      if (trip.cancelReason > 0) {
        return "black";
      }
      if (trip.bookingStatus < 4) {
        return "red";
      }
  
      return "green";
    }

   const getLogText = (log) => {
      var link = "";
      if (log.Latitude != 0 && log.longitude != 0) {
         var url = "http://maps.google.com/maps?q=" + log.latitude + "," + log.longitude; //"24.197611,120.780512";
         link = <a href={url} target="_blank"><img src="./icons/google-map.png" alt="home" /></a>
      }

      var queueIcon = "";
      if (log.queueSize > 0) {
         queueIcon= <div className="queue-size-container queue-size"><FaCircle style={{color:"#000000", width:"1rem"} } /><span>{log.queueSize + 1}</span></div>
      }

      var tripValue = "";
      if (log.tripValue > 0) {
         tripValue="(" + Number(log.tripValue).toFixed(2) + ")";
      }

      var permitNumber = "";
      if (log.permitNumber != null && log.permitNumber!="") {
         permitNumber = log.permitNumber;
      }

      var pickupTime = "";
      if (log.pickupTime > 0) {
         pickupTime ="(" + Number(log.pickupTime/60).toFixed(0) + " min)";
      }

      return <>{log.name} {permitNumber} {queueIcon} {tripValue} {link} {pickupTime}</>
   }

   
   let htmlTripLog = null;
   if (tripLog) {
      htmlTripLog = tripLog.map((log, index) => {
       return (
           <div className="myTrips-dialog-log-entry">
              <div className="myTrips-dialog-log-entry-date"><FormatUtc item={log.timestamp}/></div> 

              {/* <div  className="myTrips-dialog-log-entry-text" dangerouslySetInnerHTML={{__html:log.name}}></div> */}
              {/* <div className="myTrips-dialog-log-entry-text" dangerouslySetInnerHTML={{__html:getLogText(log)}}></div> */}
              <div className="myTrips-dialog-log-entry-text">{getLogText(log)}</div>
           </div>
       )
     })
   }

   let originalPriceHtml = null;
   if (trip.price == 0) {
      originalPriceHtml = (
         <>
         (<TripPayment price={trip.maxPrice} currency={trip.currency}/>)
         </>
      )
   }

   let stopsHtml = null;
   if (stops.length > 0) {
      stopsHtml = stops.map((stop, index) => {
         var heading = "Stopp:"; //Only one time
         if (index > 0) {heading = ""};
         return (
            <div className="myTrips-dialog-address-info">   
               <div className="myTrips-dialog-info-key">{heading}</div>
               <div className="myTrips-dialog-info-value">{stop.streetName} {stop.houseNumber}{stop.houseLetter}, {stop.zipCode} {stop.district}</div>
            </div>
            
         )
      })
   }
   
   return (
     <>
         <Dialog  open={show} fullWidth={true} maxWidth = {'md'} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
               <div className="mytrips-dialog-header">
                  <div className="mytrips-dialog-header-icon">
                  {(function() {
                    switch (trip.category) {
                    case 0:
                        return <FaTaxi style={{color:getIconColor(trip)}} />;
                    case 1:
                        return <FaSchool style={{color:getIconColor(trip)}} />;
                    case 2:
                        return <FaHamburger style={{color:getIconColor(trip)}}/>;
                    default:
                        return <FaTaxi style={{color:getIconColor(trip)}} />;
                    }
                  })()}
                  </div>
                  <div className="mytrips-dialog-header-booking">Bookingnr: {trip.bookingNumber}</div>
                  <div className="mytrips-dialog-header-status"><TripStatus bookingStatus={trip.bookingStatus} cancelReason={trip.cancelReason}></TripStatus></div>
               </div>
            </DialogTitle>
            <DialogContent  className="mytrips-dialog">
               <div className="mytrips-dialog-wrapper"> 
                  <div className="mytrips-dialog-column">
                     <div className="myTrips-dialog-address-info">
                        <div className="myTrips-dialog-info-key">Fra:</div>
                        <div className="myTrips-dialog-info-value">{trip.from?.streetName} {trip.from?.houseNumber}{trip.from?.houseLetter}, {trip.from?.zipCode} {trip.from?.district}</div>
                     </div>
                     <div className="myTrips-dialog-address-info">   
                        <div className="myTrips-dialog-info-key">Til:</div>
                        <div className="myTrips-dialog-info-value">{trip.to?.streetName} {trip.to?.houseNumber}{trip.to?.houseLetter}, {trip.to?.zipCode} {trip.to?.district}</div>
                     </div>
                     {stopsHtml}
                  </div>
               </div>
               <div className="mytrips-dialog-wrapper"> 
                  <div className="mytrips-dialog-column">
                     <div className="myTrips-dialog-column-header">Data</div>
                     {
                        fieldsToShow.pickupTime && fieldsToShow.pickupTime.isShown ?  <div className="myTrips-dialog-info">   
                        <div className="myTrips-dialog-info-key">Hentetid: </div>
                        <div className="myTrips-dialog-info-value"><FormatUtc item={trip.pickupTime} /> </div>

                     </div>: null
                     }

                     {
                        fieldsToShow.permitNumber && fieldsToShow.permitNumber.isShown ?  <div className="myTrips-dialog-info">   
                           <div className="myTrips-dialog-info-key">Løyve:</div>
                           <div className="myTrips-dialog-info-value">{trip.permitNumber}</div>
                        </div>: null
                     }
                     {
                        fieldsToShow.company && fieldsToShow.company.isShown ? <div className="myTrips-dialog-info">   
                           <div className="myTrips-dialog-info-key">Løyvehaver:</div>
                           <div className="myTrips-dialog-info-value">{permitDetails.company}</div>
                        </div> : null
                     }
                     {
                        fieldsToShow.driverNumber && fieldsToShow.driverNumber.isShown ? <div className="myTrips-dialog-info">   
                        <div className="myTrips-dialog-info-key">Sjåfør:</div>
                        <div className="myTrips-dialog-info-value">{trip.driverNumber} - {trip.firstName} {trip.lastName}</div>
                     </div> : null
                     }
                     {
                        fieldsToShow.price && fieldsToShow.price.isShown ? <div className="myTrips-dialog-info">   
                           <div className="myTrips-dialog-info-key">Pris:</div>
                           <div className="myTrips-dialog-info-value"><TripPayment price={trip.price} currency={trip.currency}/> {originalPriceHtml}</div>
                        </div>: null
                     }
                      {
                        fieldsToShow.distance && fieldsToShow.distance.isShown ? <div className="myTrips-dialog-info">   
                           <div className="myTrips-dialog-info-key">Distanse:</div>
                           <div className="myTrips-dialog-info-value">{ <span>{(trip.distance/1000).toFixed(2)} km</span>}</div>
                        </div>: null
                     }
                     {
                        fieldsToShow.numberOfPassengers && fieldsToShow.numberOfPassengers.isShown ?  <div className="myTrips-dialog-info">   
                           <div className="myTrips-dialog-info-key">Passasjerer:</div>
                           <div className="myTrips-dialog-info-value">{trip.numberOfPassengers}</div>
                        </div>: null
                     }
                     {
                        fieldsToShow.cancelReason && fieldsToShow.cancelReason.isShown ? <div className="myTrips-dialog-info">   
                        <div className="myTrips-dialog-info-key">Kanselleringsgrunn:</div>
                        <div className="myTrips-dialog-info-value"><TripStatus bookingStatus={0} cancelReason={trip.cancelReason}></TripStatus></div>
                     </div>: null
                     }
                     {
                        fieldsToShow.traveller && fieldsToShow.traveller.isShown ?   <div className="myTrips-dialog-info">   
                        <div className="myTrips-dialog-info-key">Kunde:</div>
                        <div className="myTrips-dialog-info-value">{trip.traveller} ({travellerDetails.completedTrips})</div>
                     </div>: null
                     }
                     {
                        fieldsToShow.messageToDriver && fieldsToShow.messageToDriver.isShown ?  <div className="myTrips-dialog-info">   
                        <div className="myTrips-dialog-info-key">Melding til sjåfør:</div>
                        <div className="myTrips-dialog-info-value">{trip.messageToDriver}</div>
                     </div>: null
                     }

                  </div>
                  { fieldsToShow.tripLog && fieldsToShow.tripLog.isShown ? <div className="mytrips-log-column">
                     <div className="myTrips-dialog-column-header">Logg</div>
                     <div className="myTrips-dialog-log-wrapper">
                        <div className="myTrips-dialog-log-table">
                           
                           {htmlTripLog}
                        </div>
                     </div>
                  </div>: null }
               </div>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} color="primary">
                  Lukk
               </Button>
            </DialogActions>
         </Dialog>
     </>

   )
}

export default TripStatusDialog