import React from 'react'
import './../../css/main.css'
const Section = ({title, description, right = null, children}) => {
  return (
    <div className="w-full custom-border-2" style={{marginLeft: "0px"}}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <div className="text-sm font-light text-grey-500">{title}</div>
          <div className="text-sm font-bold">{description}</div>
        </div>
        {right}
      </div>
      {children}
    </div>
  )
}

export default Section
