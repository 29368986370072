import React, { useState, useEffect} from 'react'
import Datatable from './datatable'
import {SemipolarLoading   } from 'react-loadingg'
import { history} from '../../functions/_helpers';
import { confirmAlert } from 'react-confirm-alert'; 
import TableMenu from './tableMenu'

const Cabs = (props) => {
   const [showEmptyTable,setShowEmptyTable] = useState(false)
   const [data, setData] = useState([]);
   const [loading,setLoading] = useState(true);

   useEffect(() => {
      if (!loading && props.cabs.length === 0) {
         setShowEmptyTable(true);
      }
      else {
         setData(props.cabs);
         setShowEmptyTable(false);
      }
      setLoading(false);
   }, [props]);
 

   const editCab = function(id) {
      history.push('/cabEdit/' + id);
   }
   
   const deleteCab = function(id) {
      confirmAlert({
         title: '',
         message: 'Vil du slette bilen?',
         buttons: [
            {
               label: 'Ja',
               onClick: () => doDelete(id)
            },
            {
               label: 'Nei',
               onClick: () => doNothing()
            }
         ]
         });
   }
   
   function doNothing(){
      history.push('/cabs');
   }
       
   function doDelete(id){
      props.onCabDelete(id);
   }

   const boolSort = (rowA, rowB, columnId) => {
      const a = rowA.values[columnId].toString();
      const b = rowB.values[columnId].toString();
      return a > b ? 1 : -1;
      };

   const columns = React.useMemo(
      () => [
         {
            Header: 'reg-nr',
            accessor: 'licenseNumber',
         },
         {
            Header: 'løyve-nr',
            accessor: 'permit.permitNumber',
            sortType: boolSort
         },
         {
            Header: 'Løyvehaver',
            accessor:'permitHolder'
         },         
         {
            Header: 'Kjøreområde',
            accessor:'region',
            isSorted:true
         },
         {
            Header: 'Merke',
            accessor:'brand'
         },
         {
            Header: 'Modell',
            accessor:'model'                
         },
         {
            Header: 'År',
            accessor: 'year'
         },
         {
            Header:'Type',
            accessor: 'carType'
         },
         {
            Header: 'kategori',
            accessor: 'cabCategory'
         },
         {
            Header: '',
            accessor: 'cabId',
            Cell: props => <TableMenu id={props.value} editCb={editCab} deleteCb={deleteCab}/>
         }   
      ],
      []
   )

   const items = JSON.stringify(data);  

   if(typeof(data) === 'undefined' || items === null  || items.length <= 0 || items.length === undefined || data.length <= 0 || data.length ===undefined){
      if(!showEmptyTable){
         return <SemipolarLoading/>
      }
   }

   if (showEmptyTable) {
      return(<div><h3 style={{textAlign:"center",margin:"0 auto",color:"#4663ac"}}>Ingen biler funnet!</h3></div>)
   }

   return (
      <Datatable columns={columns} data={data} sortById={'region'} />
   )

}

export default Cabs

