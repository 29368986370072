import React from 'react'
import { RiHome3Line } from 'react-icons/ri'

export const ModalBody = () => (
  <div className="relative p-4 flex-auto">
    <p>
      
    </p>
  </div>
)
