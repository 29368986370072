import React, { useState, useEffect} from 'react'
import Datatable from './datatable-driver'
import Avatars from './avatars'
import {SemipolarLoading   } from 'react-loadingg'
import DriverContactCell from './driverContactCell'
import StatusCheck from './statusCheck'
import TableMenu from './tableMenu'
import DriverRating from './driverRating'
import { history } from '../../functions/_helpers';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';


const Drivers = (props) => {
   const [showEmptyTable,setShowEmptyTable] = useState(false)
   const [data, setData] = useState([]);
   const [loading,setLoading] = useState(true);

   useEffect(() => {
      if (!loading && (props.drivers == null || props.drivers.length === 0)) {
         setShowEmptyTable(true);
      }
      else {
         setData(props.drivers);
         setShowEmptyTable(false);
      }
      setLoading(false);
   }, [props]);
 
 
   const editDriver = function(id) {
      history.push('/driverEdit/' + id);
   }

   const deleteDriver = function(id) {
      confirmAlert({
         title: '',
         message: 'Vil du slette sjåføren?',
         buttons: [
           {
             label: 'Ja',
             onClick: () => doDelete(id)
           },
           {
             label: 'Nei',
             onClick: () => doNothing()
           }
         ]
       });
   }

   
   const activateDriver = function(id, activate) {
      if (activate) {
         confirmAlert({
            title: '',
            message: 'Vil du aktivere sjåføren?',
            buttons: [
               {
               label: 'Ja',
               onClick: () => doActivate(id)
               },
               {
               label: 'Nei',
               onClick: () => doNothing()
               }
            ]
         });
      }
      else {
         confirmAlert({
            title: '',
            message: 'Vil du deaktivere sjåføren?',
            buttons: [
               {
               label: 'Ja',
               onClick: () => doDeactivate(id)
               },
               {
               label: 'Nei',
               onClick: () => doNothing()
               }
            ]
         });
      }
   }

   function doNothing(){
      history.push('/drivers');
   }
    
   function doDelete(id){
      props.onDriverDelete(id);
   }

   function doActivate(id){
      props.onDriverActivate(id);
   }

   function doDeactivate(id){
      props.onDriverDeactivate(id);
   }



   const columns = React.useMemo(
      () => [
         {   
               Header: '',
               accessor: 'profileImageUrl',
               Cell: props => <Avatars items={props.value} />
         },
         {
               Header: 'Sjåførnummer',
               accessor: 'driverNumber',
         },
         {
               Header: 'Fornavn',
               accessor: 'firstName'
         },
         {
               Header: 'EtterNavn',
               accessor: 'lastName'
         },
         {
               Header: 'kontakt',
               accessor: 'email',
               Cell: props => <DriverContactCell items = {props.row}/>
         },
         {
               Header: 'rating',
               accessor: 'rating',
               Cell: props => <DriverRating items = {props.value}/>
         },
         {
               Header: 'status',
               accessor: 'active',
               Cell: props => <StatusCheck active={props.value}/>
         },
         {
               Header: '',
               accessor: 'driverId',
               Cell: props => <TableMenu id={props.value} editCb={editDriver} deleteCb={deleteDriver} showActivate={true} activateCb={activateDriver} item={props.row} />
         }
         
      ],
      []
   )


   const items = JSON.stringify(data);

   if(typeof(data) === 'undefined' || items === null  || items.length <= 0 || items.length === undefined || data.length <= 0 || data.length ===undefined){
      if(!showEmptyTable){
         return <SemipolarLoading/>
      }
   }

   if (showEmptyTable) {
      return(<div><h3 style={{textAlign:"center",margin:"0 auto",color:"#4663ac"}}>Ingen sjåfører funnet!</h3></div>)
   }

   return (
      <Datatable columns={columns} data={data} sortById={'driverNumber'} />
   )
}

export default Drivers

