import React from 'react'
import * as moment from 'moment'
const FormatUtc = (items) => {
    if(typeof(items) === 'undefined' || typeof(items.item) === 'undefined'  || items.length <= 0 || items[0] === null || typeof(items) == [null] || typeof(items)===[undefined]){
        return(<></>) 
    }

    //const localTime = moment.utc(items.item).toDate();
    const tripTime = moment(items.item).format('DD.MM.YYYY HH:mm');
    return(

     <div>
       {tripTime}
     </div>
    )

}
export default FormatUtc