import React, { useState, useEffect } from "react";
import '../../css/components/my-trips.css'
import FormatUtc from '../dashboard/Tools/formatUtc'
import TripStatus from '../myTrips/child-components/tripStatus'
import TripPayment from '../myTrips/child-components/tripPayment'
import TripStatusDialog from '../myTrips/child-components/tripStatusDialog'
import SearchIndicator from "../myTrips/child-components/searchIndicator";
import { FaTaxi } from "react-icons/fa";

const AccountTable = ({tripList, onScrollCb, onSortCb, role}) => {
  const [trips, setTrips] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState({});
  const [sortField, setSortField] = useState("createdDate");
  const [ascending, setAscending] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setTrips(tripList);
    setLoading(false);
  },[tripList]);


  const handleClose = function() {
    setShow(false);
    //setSelectedTrip(null);
  }
  const handleShow = function(trip) {
    if (role==1) {
      setSelectedTrip(trip);
      setShow(true);
    }
  }

  const handleScroll = function(e) {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    //console.log(e.target.scrollHeight + ", " + e.target.scrollTop + ", " + e.target.clientHeight);

    if (bottom && !loading) { 
      setLoading(true);
      onScrollCb(); 
    }
  }

  const handleSort= (field) => {
    var direction = ascending;
    if (field == sortField) {
      //Just change direction
      direction = !direction;
    }
    else {
      direction = false;
      // New field
    }
    setSortField(field);
    setAscending(direction);
    onSortCb(field, direction);
  }


  const getIconColor = function(trip) {
    if (trip.isManual) {
      return "blue";
    }
    if (trip.cancelReason > 0) {
      return "black";
    }
    if (trip.bookingStatus < 4) {
      return "red";
    }

    return "green";
  }

  const getDriverName = function(trip) {
    if (trip.driverNumber == null) {
      return "Bil ikke tildelt";
    }
    return trip.driverNumber + ' - ' + trip.firstName + ' ' + trip.lastName;
  }

  const formatAddress= (address) => {
    var res = (address.streetName != null?address.streetName:'') + ' ' + 
              (address.houseNumber != null?address.houseNumber:'') + ' ' + 
              (address.houseLetter != null?address.houseLetter:'') + ' ' + 
              (address.zipCode != null?address.zipCode:'') + ' ' + 
              (address.district != null?address.district:'');
    return res;
  }


  let tripsHtml = null;
  if (trips) {
    tripsHtml = trips.map((trip, index) => {
      return (
          <div className="myTrips-table-row" title="Klikk for detaljer" key={'MTR_' + index} onScroll={handleScroll} onClick={() => handleShow(trip)}>
            <div className="myTrips-icon"><FaTaxi style={{color:getIconColor(trip)}} /></div>
            <div className="myTrips-date"><FormatUtc item={trip.completedDate}/></div>
            <div className="myTrips-booking">{trip.bookingNumber}</div>
            <div className="myTrips-driver">{getDriverName(trip)}</div>
            <div className="myTrips-permit  text-center">{trip.permitNumber}</div>
            <div className="myTrips-address">{formatAddress(trip.from)}</div>
            <div className="myTrips-address">{formatAddress(trip.to)}</div>
            <div className="myTrips-region">{trip.regionName}</div>
            <div className="myTrips-payment text-right"><div><TripPayment price={trip.price} currency={trip.currency}/></div></div>
            <div className="myTrips-status text-center"><TripStatus bookingStatus={trip.bookingStatus} cancelReason={trip.cancelReason}></TripStatus></div>
          </div>
      )
    })
  }


  return (
    <>
      <div className="myTrips-table-header">
        <div className="myTrips-icon"></div>
        <div name="createdDate" title="Sorter" className="myTrips-date" onClick={() =>handleSort("createdDate")}>Fullført dato<SearchIndicator visible={sortField == "createdDate"} ascending={ascending}/></div>
        <div name="bookingNumber" title="Sorter" className="myTrips-booking" onClick={() =>handleSort("bookingNumber")}>Booking<SearchIndicator visible={sortField == "bookingNumber"} ascending={ascending}/></div>
        <div name="driverNumber" title="Sorter" className="myTrips-driver" onClick={() =>handleSort("driverNumber")}>Sjåfør<SearchIndicator visible={sortField == "driverNumber"} ascending={ascending}/></div>
        <div name="permitNumber" title="Sorter" className="myTrips-permit text-center" onClick={() =>handleSort("permitNumber")}>Løyve<SearchIndicator visible={sortField == "permitNumber"} ascending={ascending}/></div>
        <div name="from.streetName" title="Sorter" className="myTrips-address" onClick={() =>handleSort("from.streetName")}>Fra<SearchIndicator visible={sortField == "from.streetName"} ascending={ascending}/></div>
        <div name="to.streetName" title="Sorter" className="myTrips-address" onClick={() =>handleSort("to.streetName")}>Til<SearchIndicator visible={sortField == "to.streetName"} ascending={ascending}/></div>
        <div name="regionName" title="Sorter" className="myTrips-region" onClick={() =>handleSort("regionName")}>Kjøreområde<SearchIndicator visible={sortField == "regionName"} ascending={ascending}/></div>
        <div name="price" title="Sorter" className="myTrips-payment text-right" onClick={() =>handleSort("price")}>Beløp<SearchIndicator visible={sortField == "price"} ascending={ascending}/></div>
        <div name="bookingStatus" title="Sorter" className="myTrips-status text-center" onClick={() =>handleSort("bookingStatus")}>Status<SearchIndicator visible={sortField == "bookingStatus"} ascending={ascending}/></div>
      </div>
      <div className="myTrips-table-content" onScroll={handleScroll}>
        {tripsHtml}
      </div>

      <TripStatusDialog show={show} onCloseCb={handleClose} selectedTrip={selectedTrip}></TripStatusDialog>
    </>
  )
}
export default AccountTable