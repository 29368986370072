export default function user(
  user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user')),

  state = {
    name: '',
    email: '',
    location: '',
    company: '',
    description: '',
    img: '',
    role: '',
    country: ''
  },
  action
) {
  switch (action) {
    case 'SET_USER':
      return {
        ...state,
        [`${action.key}`]: action.value
      }
    default:
      return state
  }
}
