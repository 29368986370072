import React from 'react'
import Setting from './setting'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { userActions } from '../../functions/_actions'
import { useDispatch } from 'react-redux'

const SettingsGroup = ({group, settings}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  // Special handling for Rating
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => { 
    setOpen(false);
  }

  const handleSubmit = () => {   

    dispatch(userActions.systemChange(1));   
    setOpen(false);
  };

  const addGroupHeading = function(group) {
    console.log(group);
    if (group === 'Rating') {
      return (
        <>
          {group}
          <button className="settings_btn" style={{marginLeft:"10px",marginRight:"10px"}} onClick={()=>handleClickOpen()}> Nullstill </button>
        </>
      )
    }
    else {
      return (<>{group}</>);
    }
  }


  return (
    <>
      <div>
      <div className="settings-group">{addGroupHeading(group)}</div>
        {
          settings.map((setting)=>
          {
            if (setting.displayWeb) {
            return (
              <Setting setting={setting}></Setting>
            )
            }
          })

        }
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Nullstille Rating</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ønsker du å nullstille rating for alle sjåfører.<br></br><br></br> Advarsel: Denne handlingen kan ikke reverseres.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Nullstill
          </Button>
        </DialogActions>
      </Dialog>
    </>    
  )
}

export default SettingsGroup;