import React from 'react';
import { FaTaxi, FaHandPaper, FaCircle, FaHome, FaHamburger, FaSchool } from "react-icons/fa";

export default function CabStatus({cabId, cabStatus, connected, queueSize, forcedBreak, driver, atHome, onCabSelected, onCabStatusEdit, category}) {

   if(connected === false || driver === null){
      return( <div className="flex flex-row items-center justify-start" style={{textAlign:"center",marginLeft:"16px",fontSize:"20px"}}>
                  <FaTaxi title="Ikke aktiv" style={{color:"#a19f9f"}} />
             </div>)
  }
 
  const getTripCount = () => {
      if (queueSize > 1) {
         return  <div className="flex-1 queue-size" onClick={()=>onCabSelected(cabId)}>
            <FaCircle style={{color:"#000000", width:"1rem"} } />
            <span>{queueSize -1}</span>
         </div>
      }
      else 
      return '';
  }
  
  const IconByTripCategory = ({title, color}) => {
   switch (category) {
      case 0:
          return <FaTaxi title={title} style={{color: color}}/>;
      case 1:
          return <FaSchool title={title} style={{color: color}}/>;
      case 2:
          return <FaHamburger title={title} style={{color: color}}/>;
      default:
         return <FaTaxi title={title} style={{color: color}}/>;
  }}
 
  return (
      
          <div className="flex flex-row items-center justify-start" style={{textAlign:"center",marginLeft:"16px",fontSize:"20px"}}>
              {(function() {
                 
              switch (cabStatus) {
              case 0:
                  return <div className="display-flex">
                     <div className="flex-1" onClick={()=>onCabSelected(cabId)}><IconByTripCategory  title="Ukjent" color="#d3d3d3" /></div>
                  </div>
              case 1:
                  return <div className="display-flex">                     
                     <div className="flex-1" onClick={()=>onCabSelected(cabId)}><IconByTripCategory title="Ledig"  color="#04cc04" /></div>
                     {atHome &&
                     <div className="flex-1" onClick={()=>onCabSelected(cabId)}><FaHome title="Hjemme" style={{color:"#222222"}} /></div>
                     }
                  </div>
              case 2:
                  return <div className="display-flex">
                     <div className="flex-1" onClick={()=>onCabSelected(cabId)}><IconByTripCategory title="Opptatt" color="#ff0000" /></div>
                     {getTripCount()}
                  </div>
              case 3:
                  return <div className="display-flex">
                     <div className="flex-1" onClick={()=>onCabSelected(cabId)}><IconByTripCategory  title="Pause" color="#ff8c00" /></div>
                     {forcedBreak &&
                     <div className="flex-1" onClick={()=>onCabStatusEdit(cabId)}><FaHandPaper title="Endre status" style={{color:"#ff0000"}} /></div>
                     }
                  </div>
              default:
                  return <div className="display-flex">
                     <div className="flex-1" onClick={()=>onCabSelected(cabId)}><IconByTripCategory title="Ukjent" color="#d3d3d3" /></div>
                  </div>
              }
              
          }
          )()}

          </div>
  );
}
