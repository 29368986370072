import React,{useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import Layout from '../layouts/centered'
import {history,config,authHeader} from '../functions/_helpers';
import axios from 'axios'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay';
const Index = () => {
  //ReactDOM.findDOMNode().getElementsByClassName('snap')
 const [email,setEmail] = useState(null);
 const [newPassword,setNewPassword] = useState(null);
 const [repeatPassword,setRepeatPassword] = useState(null);
 const [showReset, setShowResetPassword] = useState(false);
const [userData,setUserData] = useState({ hits: [] });
const repeatPasswordInput = useRef(null);
const nyttpasswordInput = useRef(null);
const [isActive, setActive] = useState(false);
const [showError,setShowError] = useState(false);


 function handleChange(e) {
    
      const { name, value } = e.target;

      if(name === "email")
      {
        setShowError(false);
        setEmail(value);
      }
      if(name==="passwordFromEmail"){
        if(value !== userData.password)
        {
          if(nyttpasswordInput.current !== null)
            {
              //
              nyttpasswordInput.current.style = 'border:1px solid #37708e;margin:10px auto; max-width:240px;min-height:36px;heigth:36px;border-color:#FFC0CB;border-width:1px;border-radius:10px;';
            }
          
        }else{
          if(nyttpasswordInput.current !== null)
          {
            nyttpasswordInput.current.style = 'border:1px solid #37708e;margin:10px auto; max-width:240px;min-height:36px;heigth:36px;border-color:#185A76;border-width:1px;border-radius:10px;';
          }
        }
      }
      if(name === "newpassword"){
        setNewPassword(value);
      }
      if(name === "repeatPassword"){
        if(value !== newPassword){
          if(repeatPasswordInput.current !== null)
            {
              //
              repeatPasswordInput.current.style = 'border:1px solid #37708e;margin:10px auto; max-width:240px;min-height:36px;heigth:36px;border-color:#FFC0CB;border-width:1px;border-radius:10px;';
            }
          //alert('passord ikke samme som oppgitt'); return;
        }else{
          if(repeatPasswordInput.current !== null)
            {
              repeatPasswordInput.current.style = 'border:1px solid #37708e;margin:10px auto; max-width:240px;min-height:36px;heigth:36px;border-color:#185A76;border-width:1px;border-radius:10px;';
            }
        }
        setRepeatPassword(value);
      }
    
  }
  const sendPassword = () => {
   
    if(email !== null)
    {
      sendPass();
    }
      
   
  }
  const resetPassword = () => {
 // save password and update and return to the loging page
 setActive(true);
    const userToUpdate = {
        userId : userData.userId,
        email: userData.email,
        userPassword: newPassword,
        createdDate: userData.createdDate,
        modifiedDate: moment.utc(new Date()).format().toLocaleString()
    }
    
    axios.post(process.env.REACT_APP_API_URL + '/api/user/updatePassword',userToUpdate,{headers:authHeader()},{"Access-Control-Allow-Origin":"*"}).then((response) => {

      if(response.status === 200){
        sendMail(email);
        setActive(false);
        localStorage.clear();
        history.push('/login');
      }

    }).catch((error) => {
      if(error.response){
        if(error.response){
          console.log(error.response.data);
        }else if (error.request){
            console.log(error.request);
        }else{
          console.log(error.message);
       }
      }
    });
    
    
  }
  function sendMail(email){
    const requestBody = {
      toEmail: email,
      subject: "passord oppdatert",
      body: "ditt Intercab web passord er oppdatert!"
    };
    setActive(true);
    const fetchData = async () => {
      
      await axios.post(process.env.REACT_APP_API_URL + '/api/mail/send',requestBody,{"Access-Control-Allow-Origin": "*"}
      ).then((response)=>{
      
        if(typeof(response) !== 'undefined'){
        //  setUserData(response.data);
        setActive(false);
        }
        
       
      })
      .catch((error)=>{
          if(error.response){
              console.log(error.response.data);
          }else if (error.request){
              console.log(error.request);
          }else{
              console.log(error.message);
          }

      });
      
    };
    axios.interceptors.response.use( (response) => {
      // Return a successful response back to the calling service
      return response;
    }, (error) => {
      // Return any error which is not due to authentication back to the calling service
      if (error.response.status === 401) {
          localStorage.clear();
          history.push('/login');
      }else{
          return new Promise((resolve, reject) => {
              reject(error);
            });
      }
     
    });
    fetchData();
  }
function sendPass() {
  setActive(true);
    const fetchData = async () => {
      
      await axios.post(process.env.REACT_APP_API_URL + '/api/users/forgotpassword',{email:email},{"Access-Control-Allow-Origin": "*"}
      ).then((response)=>{
      
        if(typeof(response) !== 'undefined'){
          setUserData(response.data);
          setActive(false);
        }
        //
        setShowResetPassword(true);
       
      })
      .catch((error)=>{        
          if(error.response){
              console.log(error.response.data);setActive(false); 
              if(error.response.status === 404){
                //alert('Brukeren ikke funnet, sjekk epost addressen på nytt.')
                setShowError(true);
              }
          }else if (error.request){
              console.log(error.request);
          }else{
              console.log(error.message);
          }

      });
      
    };
    
    axios.interceptors.response.use( (response) => {
        // Return a successful response back to the calling service
        return response;
      }, (error) => {
        // Return any error which is not due to authentication back to the calling service
        if (error.response.status === 401) {
            localStorage.removeItem('user');
            localStorage.clear();
            history.push('/login');
        }else{
            return new Promise((resolve, reject) => {
                reject(error);
              });
        }
       
      });
    fetchData();
   
}
 
  return (
    <Layout>
      <div  className="form-scroll flex flex-col bg-transparent p-8 w-full max-w-lg" style={{margin:"0 auto", borderColor:"black", borderWidth:"thin",borderRadius:"10px"}}>
      <LoadingOverlay
        active={isActive}
        spinner
        text=''
        styles={{
          spinner: (base) => ({
            ...base,
            width: '80px',
            '& svg circle': {
              stroke: '#03b2cb'
            }
          })
        }}
        >
      </LoadingOverlay>
      {showReset? 
        <div>
        <h6> Skriv nytt passord !</h6> 
        <div className="w-full mt-2">

          <div className="text-center">
            <label>Nytt passord fra epost </label><br/>
            <input type="password" className="col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12" ref={nyttpasswordInput} style={{border:"1px solid #37708e",margin:"10px auto", maxWidth:"240px",minHeight:"36px",heigth:"36px",borderColor:"#185A76",borderWidth:"1px",borderRadius:"10px"}} name="passwordFromEmail" onChange={handleChange} /><br/>
            <label>Nytt passord</label><br/>
            <input type="password" className="col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12" style={{border:"1px solid #37708e",margin:"10px auto", maxWidth:"240px",minHeight:"36px",heigth:"36px",borderColor:"#185A76",borderWidth:"1px",borderRadius:"10px"}} name="newpassword" onChange={handleChange} /><br/>
            <label>Gjente passord</label><br/>
            <input type="password" className="col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12" ref = {repeatPasswordInput} style={{border:"1px solid #37708e",margin:"10px auto", maxWidth:"240px",minHeight:"36px",heigth:"36px",borderColor:"#185A76",borderWidth:"1px",borderRadius:"10px"}} name="repeatPassword" onChange={handleChange} />

            <div className="col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12" style={{margin:"10px auto"}}>
            </div>
            <button className="sign_btn col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12" type="button" style={{maxWidth:"200px"}} onClick={resetPassword}> Lagre</button>
            <div className="col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12" style={{margin:"10px auto"}}>
            </div>
          </div>
        </div>
      </div>
      :
      <div>
      <h6>Vennligst skriv inn e-postadressen din for å gjenopprette passordet ditt !</h6> 
      <div className="w-full mt-2">

        <div className="text-center">
          <input type="text" className="col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12"  name="email" onChange={handleChange} style={{border:"1px solid #37708e",margin:"10px auto", maxWidth:"240px",minHeight:"36px",heigth:"36px",borderColor:"#185A76",borderWidth:"1px",borderRadius:"10px"}} />
          {showError? <div><br/><span style={{color:"#ff0000"}}>    *ugyldig brukernavn sjekk e posten</span></div>:''}
          <div className="col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12" style={{margin:"10px auto"}}> 
          </div>
          <button className="sign_btn col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12" type="button" style={{maxWidth:"200px"}} onClick={sendPassword}> Sende passord</button>
          <div className="col-8 col-md-6 col-lg-3 col-sm-4 col-xs-12" style={{margin:"10px auto"}}>
          </div>
        </div>
      </div>
      </div>
      }
        
        <span>
              <Link style={{margin:"10px auto"}} to="/login" className="link">
                        Tilbake til logg inn
              </Link>
        </span>
     </div>
      
    </Layout>
  )
}

export default Index
