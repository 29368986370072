import React from 'react';
import { FaHamburger, FaCalendar } from "react-icons/fa";
import {BsFillPersonFill} from "react-icons/bs";

// icon info from database.
export const  DbIcon = ({icon}) => {

    if (icon === 'faHamburger') {
        return <FaHamburger title={"Matkjøring"} fontSize={"15px"} style={{color: '#a64b0f'}}/>;
    }
    else if (icon === 'bsFillPersonPlusFill'){
        return <BsFillPersonFill title={"Persontransport"} fontSize={"17px"} style={{color: "#545956"}}/>;
    }
    else if (icon === 'faCalendar'){
        return <FaCalendar title={"Forhåndsbestilling"} fontSize={"14px"} style={{ color: "#167842"}}/>;
    }
    else {
        return null;
    }    
      
};