import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import MapContainer from "../components/dashboard/MapContainer/MapContainer";
import {SemipolarLoading   } from 'react-loadingg'
import axios from 'axios'
import {authHeader} from '../functions/_helpers'
import { useLocation } from "react-router-dom";

const Index = () => {
   const [tableData, setTableData] = useState({ hits: [] });
   const [centerLatitude, setCenterLatitude] = useState(58.14671);
   const [centerLongitude, setCenterLongitude] = useState(7.9956);
   const timer = useRef(null); // we can save timer in useRef and pass it to child
   const [specialLocations, setSpecialLocations] = useState([]);
   //Todo: Need a special kind og authentication here... This will be called by the cabs

   const search = useLocation().search;
   const id = new URLSearchParams(search).get('id');
   const latString = new URLSearchParams(search).get('latitude');
   const longString = new URLSearchParams(search).get('longitude');


   useEffect(() => {

      window.addEventListener('clear_timer_event',clear_timer, false);

      console.log(id);
      const fetchCabStatus = async () => {
            await axios.get(process.env.REACT_APP_API_URL+'/api/dashboard/cabLocations/' + id,{headers:authHeader()}
            ).then((response)=>{
              setTableData(response.data);
            }).catch((error)=>{
              console.log(error);
            }
          );
      };

      const fetchSpecialLocations = async () => {
        await axios.get(process.env.REACT_APP_API_URL+'/api/dashboard/specialLocations/' + id,{headers:authHeader()}
        ).then((response)=>{
          setSpecialLocations(response.data);
        }).catch((error)=>{
          console.log(error);
        });
      };


      fetchCabStatus();
      fetchSpecialLocations();

      if (latString != null) {
        var lat = parseFloat(latString);
        setCenterLatitude(lat);
      }

      if (longString != null) {
        var long = parseFloat(longString);
        setCenterLongitude(long);
      }

      timer.current = setInterval(() => {
         fetchCabStatus();
      }, 30000);
      
      return () => {
        window.removeEventListener('clear_timer_event',clear_timer, false);
        clearInterval(timer.current);
      };
   }, []);


   function clear_timer() 
   {
     if (timer != null) {
      clearInterval(timer.current);
     }
   }


   if(typeof(tableData) === 'undefined' || tableData.length <= 0 || tableData.length ===undefined ){
      return <SemipolarLoading/>
   }
   
   const cabLocations = tableData.filter(function(cab) {
    if (cab.permitNumber == null || cab.permitNumber == "") {
      return false; // skip
    }
    return true;
  }).map(data => {
      var title = '';
      var status = data.cabStatus;
      if (data.connected == 0 || data.driverNumber == '' || data.driverNumber== 'null') {
        status = 0;
      }
 
      return {
        latLng: {lat: data.latitude, lng: data.longitude}, 
        title: title, 
        status: status,
        cabId: data.cabId,
        queuePosition:data.queuePosition
      }
    })


   return (
      <div style={{position:"absolute", width:'100%', height:'100%'}}>
         <MapContainer markersData={cabLocations} centerLatitude={centerLatitude} centerLongitude={centerLongitude} specialLocations={specialLocations} />
      </div>

  )
}
export default Index
