import React, { useEffect } from 'react'
import {Link} from 'react-router-dom'
import {history} from './../functions/_helpers'
import trip_home_icon from './../assets/image/web-icons/home.png'
import './../css/main.css'
import Section from '../components/dashboard/section'
import MessageEdit from '../components/dashboard/EditItemsFromTable/messageEdit'


function Messages(props) {

  
  let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
 
  useEffect(() => {

    if(user_data === null || user_data.length === 0)
    {
        localStorage.clear();
        history.push('/login');
    }
    if(user_data.role !== 1){
        history.push('/');
        return null;
    }
   
  } , []);


  return (
    <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
      <div className="flex w-full trip_header ml-2" >
          <div className="col-6 text-left">
              <img src={trip_home_icon} alt="home" />
              <span className="font-bold"> <Link to='/' className="non-undeline-link">HJEM &nbsp; &nbsp; / &nbsp; </Link></span>
              <span className="font-bold uppercase"> Meldinger &nbsp; &nbsp; / &nbsp; </span>
          </div>
      </div>      

      <Section>

        <div className="calc-container w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main message-container">
        <div className="w-full trip_box ml-2" style={{ width: "100%", overflow: "auto" }}>
        <div className="flex w-full ">
          <MessageEdit />

          </div></div></div>

      </Section>
     </div>
  )
}
export default Messages