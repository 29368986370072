import { authHeader, history } from '../_helpers';

export const homeLocationService = {
  getHomeLocation,
  postHomeLocation,
  deleteHomeLocation,
}


function getHomeLocation(id) {
   const requestOptions = {
         method: 'GET',
         headers: authHeader()
   };
   return fetch(process.env.REACT_APP_API_URL + '/api/homeLocation/' + id, requestOptions).then(handleResponse, handleError);
}


function postHomeLocation(homeLocation){
   const requestOptions = {
      method: 'POST',
      headers: {...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(homeLocation)
   };

   return fetch(process.env.REACT_APP_API_URL + '/api/homeLocation' , requestOptions).then(handleResponse, handleError);
}

function deleteHomeLocation(id){
   const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
   };
   return fetch(process.env.REACT_APP_API_URL + '/api/homeLocation/' + id, requestOptions).then(handleResponse, handleError);
}


function handleResponse(response) {
return new Promise((resolve, reject) => {
      if (response.ok) {
         // return json if it was returned in the response
         var contentType = response.headers.get("content-type");
         if (contentType && contentType.includes("application/json")) {
               response.json().then(json => resolve(json));
         } else {
               resolve();
         }
      } else {
         // return error message from response body
         response.text().then(text => reject(text));
         console.log(response);
         if(response.status === 401){
            localStorage.clear();
            history.push('/login');
         }
      }
});
}
 



function handleError(error) {
   return Promise.reject(error && error.message);
}