import React, { useEffect, useState, useRef } from 'react';


const TableMenu = (props) => {

   const dropdownRef = useRef(null);
   const [isActive, setIsActive] = useState(false);
   
   const onClick = (e) => {
      e.stopPropagation();
      setIsActive(!isActive);
   }

   useEffect(() => {
      const pageClickEvent = (e) => {
         // If the active element exists and is clicked outside of
         if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
            setIsActive(!isActive);
         }
      };

       // If the item is active (ie open) then listen for clicks
      if (isActive) {
         window.addEventListener('click', pageClickEvent);
      }

      return () => {
         window.removeEventListener('click', pageClickEvent);
      }

   }, [isActive]);

   const editFunc = function(e, id) {
      e.stopPropagation();
      props.editCb(id);
   }
   const deleteFunc = function(e, id) {
      e.stopPropagation();
      props.deleteCb(id);
   }
   const activateFunc = function(e, id, activate) {
      e.stopPropagation();
      props.activateCb(id, activate);
   }

   var activateHtml = null;
   if (props.showActivate && props.item) {
      if (props.item.original.active) {
         activateHtml = <li onClick={(e) => activateFunc(e, props.id, false)}><a>Deaktiver</a></li>
      }
      else {
         activateHtml = <li onClick={(e) => activateFunc(e, props.id, true)}><a>Aktiver</a></li>
      }
   }

   return (
      <div className="menu-container">
      <button onClick={(e) =>onClick(e)} className="menu-trigger">
      <span>...</span>
      </button>
      <nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
      <ul>
         <li onClick={(e) => editFunc(e, props.id)}><a>Rediger</a></li>
         {activateHtml}
         <li onClick={(e) => deleteFunc(e, props.id)}><a>Slett</a></li>
      </ul>
      </nav>
   </div>
   )
}

export default TableMenu;