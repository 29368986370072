import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import intercab from '../../assets/image/otherImages/intercab.png';

export default class Footer extends Component {
    render() {
        return (
            
            <div className="footer" style={{float:"bottom", backgroundColor:"#185a7d", left: "0", right:"0", margin:"auto",bottom:"0", position:"fixed", height:"60px", width:"100%", textAlign:"center", marginLeft:"0px", marginRight:"0px"}}>
                <footer className="page-footer font-small blue">
                    <div style={{marginTop:"0px"}}>
                        <img src={intercab} alt="intercab" style={{margin: "0 auto", height:"46px"}}/>
                    </div>
                    <div style={{fontSize:"9px",marginTop:"-2px"}}>
                        <p style={{color:"#fff"}} >© 2020 Intercab AS<a href="#"></a> </p>
                    </div>
                </footer>
            </div>
                
           
        );
    }
}
