import React, { Component } from 'react'


class TripPayment extends Component {

   render() {
      const {price, currency} = this.props;

      var thisCurrency = currency;
      if (currency === null || currency === undefined) {
         thisCurrency = "kr";
      }

      return (
         <span>{price/100} {thisCurrency}</span>
      )
   }
}
  
export default TripPayment;