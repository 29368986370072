import React, { useState, useEffect } from 'react'
import { Grid } from "@material-ui/core";
import {Link} from 'react-router-dom'
import axios from 'axios'
import {history,authHeader,config} from '../functions/_helpers'
import trip_home_icon from './../assets/image/web-icons/home.png'
import './../css/main.css'
import Section from '../components/dashboard/section'
import HeatmapContainer from "../components/heatmap/heatmapContainer";
import {heatmapService} from '../functions/services'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

function Index(props) {

   const [positions,setPositions]= useState([]);
   const [centerLatitude, setCenterLatitude] = useState(58.14671);
   const [centerLongitude, setCenterLongitude] = useState(7.9956);
   const [searchData, setSearchData, getSearchData] = useState({
      fromDate:new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate:new Date(),
      booked:false,
      bookingStatus:0,
      addressType:0
   })

   const [tripSelection, setTripSelection] = useState('all');
   const [periodSelection, setPeriodSelection] = useState('month');
   const [addressType, setAddressType] = useState('pickup');
   
   useEffect(() => {
      fetchData();
   } , []);

   useEffect(() => {
      fetchData();
   } , [searchData]);


   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }
   if(user_data.role != 1){
      history.push('/');
      return null;
   }
   
   history.push('/');


   const fetchData = async () => {
      console.log("Fetching data");
      // heatmapService.fetchData(searchData)
      // .then(
      //    (response) => {
      //       console.log("Got the data");
      //       console.log(response);
      //    setPositions(response);
      //    },
      //    (error) => {
      //       console.log(error);
      //    });
   }



   // const handleFromTimeSearch = function(e) {
   //    const { value} = e.target;
   //    setFromTime(value);
   //    if (value=='') {
   //       setSearchData(searchData=> ({...searchData,fromTime:null}));
   //    }
   //    else {
   //       setSearchData(searchData=> ({...searchData,fromTime:value}));
   //    }
   // }

   // const handleToTimeSearch = function(e) {
   //    const { value} = e.target;
   //    setToTime(value);
   //    if (value=='') {
   //       setSearchData(searchData=> ({...searchData,toTime:null}));
   //    }
   //    else {
   //       setSearchData(searchData=> ({...searchData,toTime:value}));
   //    }
   // }


   const handleChange=(e)=>{
      var value = e.target.value;
      setTripSelection( value);
      if (value == 'all'){
         setSearchData(searchData=> ({...searchData,booked:false,bookingStatus:0}));
      }
      else if (value == 'booked') {
         setSearchData(searchData=> ({...searchData,booked:true,bookingStatus:0}));
      }
      else if (value == 'completed') {
         setSearchData(searchData=> ({...searchData,booked:true,bookingStatus:4}));
      }

   }

   const handlePeriodChange=(e)=>{
      var value = e.target.value;
      setPeriodSelection(value);
      if (value == 'month'){
         var fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
         setSearchData(searchData=> ({...searchData,fromDate:fromDate}));
      }
      else if (value == 'year') {
         var fromDate = new Date(new Date().getFullYear(), 0, 1);
         setSearchData(searchData=> ({...searchData,fromDate:fromDate}));
      }
      else if (value == 'all') {
         var fromDate = new Date(2020, 0, 1);
         setSearchData(searchData=> ({...searchData,fromDate:fromDate}));
      }

   }

   const handleAddressChange=(e)=>{
      var value = e.target.value;
      setAddressType(value);
      if (value == 'pickup'){
         setSearchData(searchData=> ({...searchData,addressType:0}));
      }
      else if (value == 'delivery') {
         setSearchData(searchData=> ({...searchData,addressType:1}));
      }
   }



   return (
      
         <Section>
         <div className="trip_box ml-2 custom-add-item-border"  style= {{overflow: "auto", height:"95%"}}>
            <h4 className="trip_title font-bold mb-3"> Heatmap </h4>
               <div className="flex w-full trips" style={{marginTop:"-10px", marginBottom:"10px"}}>
                  <div className="myTrips-search-field flex-1">
                     <FormControl component="fieldset">
                        <RadioGroup row value={tripSelection} onChange={handleChange}>
                           <FormControlLabel value="all" control={<Radio />} label="Alle" />
                           <FormControlLabel value="booked" control={<Radio />} label="Bestilte" />
                           <FormControlLabel value="completed" control={<Radio />} label="Gjennomførte" />
                        </RadioGroup>
                     </FormControl>
                  </div>


                  <div className="myTrips-search-field flex-2">
                     <FormControl component="fieldset-2">
                        <RadioGroup row  value={periodSelection} onChange={handlePeriodChange}>
                           <FormControlLabel value="week" control={<Radio />} label="Siste 7 dager" />
                           <FormControlLabel value="month" control={<Radio />} label="Siste 30 dager" />
                           <FormControlLabel value="year" control={<Radio />} label="Inneværende år" />
                           <FormControlLabel value="all" control={<Radio />} label="Siden oppstart" />
                        </RadioGroup>
                     </FormControl>
                  </div>

                  <div className="myTrips-search-field flex-1">
                     <FormControl component="fieldset-2">
                        <RadioGroup row  value={addressType} onChange={handleAddressChange}>
                           <FormControlLabel value="pickup" control={<Radio />} label="Henteadresser" />
                           <FormControlLabel value="delivery" control={<Radio />} label="Leveringsadresser" />
                        </RadioGroup>
                     </FormControl>
                  </div>

                  {/* <div className="myTrips-search-field flex-1">
                     <label className="font-bold block" style={{marginTop:"0px",marginBottom:"-2px"}}> Fra </label>
                     <input type="datetime-local" id="turstartTime" style={{margin: ".4rem 0",border:"1px solid #37708e"}} name="fromTime"  className="mt-6" value={fromTime} onChange={handleFromTimeSearch} 
                           min="2018-06-07T00:00" max="2040-06-14T00:00"/>   
                  </div>
                  <div className="myTrips-search-field flex-1">
                     <label className="font-bold block" style={{marginTop:"0px",marginBottom:"-2px"}}> Til </label>
                     <input type="datetime-local" dateformat="d M y" id="tursluttTime" style={{margin: ".4rem 0",border:"1px solid #37708e"}} name="toTime" className="mt-6" value={toTime} onChange={handleToTimeSearch} 
                        min="2018-06-07T00:00" max="2040-06-14T00:00"/>
                  </div> */}


               </div>
               <div className="top-adjustment" style={{overflow:"auto", height:"85%", paddingRight:"10px",paddingBottom:"10px"}}>
                  <div style={{width:"98%", height:"90%"}}>
               <  HeatmapContainer centerLatitude={centerLatitude} centerLongitude={centerLongitude} addressPoints={positions}/>
               </div>
            </div>
         </div>
         </Section>
   )
}
export default Index