import React from 'react'
const StatusCheck = (data) => {
    if(typeof(data) === 'undefined' || data === null  || data.length <= 0 ){
        return(<></>) 
    }

    return (
      <div className="flex flex-row items-center justify-start" style={{fontSize: "12px"}}>
          <ul>
          {data.active? "Aktiv":"Deaktivert"}
        </ul>
      </div>
  )
}

export default StatusCheck