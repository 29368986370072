import React, { Component } from 'react';

class rolls extends Component {
  
    render() {
    const {items} = this.props;
        return (
           <>
                <div className="flex flex-row items-center justify-start" style={{fontSize: "12px"}}>
                    {(function() {
                    switch (items) {
                    case 1:
                        return <span>Admin</span>;
                    case 2:
                        return <span>LøyveHaver</span>;
                    case 3:
                        return <span>Sjåfør</span>;
                    default:
                        return <span>Ukjent rolle</span>
                    }
                })()}

                </div>
            </>
        );
    }
}

export default rolls;