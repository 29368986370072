import React, {useState, useEffect} from 'react'
import {history} from '../functions/_helpers'
import jwt from 'jwt-decode'
import trip_home_icon from './../assets/image/web-icons/home.png'
import { AiOutlineSearch } from "react-icons/ai";
import {BatteryLoading} from 'react-loadingg'
import AccountTable from '../components/account/accountTable'
import AccountSearch from '../components/account/accountSearch'
import {myTripsService} from '../functions/services'
import moment from 'moment'



const keyPressTimer = 1000;
let inputTimer = null;
const defaultTake = 50;

function Index(props) {
   const [isMount, setIsMount] = useState(true);
   const [searchOpen, setSearchOpen] = useState(false);
   const [printProgressShow, setPrintProgressShow] = useState(false);
   const [trips, setTrips] = useState([]);
   const [searchModel,setSearchModel] = useState({
      searchString:'',
      driverId:0,
      fromAddress:null,
      toAddress:null,
      status:0,
      permitNumber:null,
      driverNumber:null,
      bookingNumber:null,
      from:null,
      to:null,
      phoneNumber:null,
      paid:true,
      dateMode:1,
      orderField:'',
      ascending:false,
      index:0,
      take:10,
      utcOffset:0,
      isManual:false
   })

   
   useEffect(() => {
      // Get role and update search model
      setSearchModel(searchModel=> ({...searchModel,take:defaultTake, utcOffset:moment().utcOffset()}));
   }, [])


   useEffect(() => {
      // First to skip the initialising phase. 
      // because of security reasons we have to check user role before fetching
      if(isMount){
         setIsMount(false);
         return;
      }
      // Fetch data based on the changes in the search model
      fetchData();     
   }, [searchModel])


   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }

   // Check Role
   const decodedToken = jwt(user_data.token);
   if (decodedToken == null || decodedToken == undefined) {
      localStorage.clear();
      history.push('/login');  
   }

   // TODO - Fix to allow other roles
   const role = parseInt(decodedToken.role);
   // if(role !== 1){
   //    history.push('/myTrips');
   //    return null;
   // }

   // Routine to fetch data based on search criteria
   const fetchData = async () => {
      myTripsService.tripSearch(searchModel)
      .then(
         (response) => {
            if (searchModel.index > 0) {
               setTrips([...trips, ...response]);    
            }
            else {
               setTrips(response); 
            }
         },
         (error) => {
            console.log(error);
         });

   }

   const onViewSearch = () => {
      setSearchOpen(!searchOpen);
   }

   const handleExport = () =>{
      myTripsService.getReport(searchModel);
   }


   const handleOnSortCb = function(field,direction) {
      setSearchModel(searchModel=> ({...searchModel,orderField:field, ascending:direction, take:defaultTake, index:0}));
   }

   const handleOnSearchCb = function(fromAddress, toAddress, status, permit, from, to, bookingNumber, driverNumber, regionName, phoneNumber) {
      setSearchModel(searchModel=> ({...searchModel,fromAddress:fromAddress, toAddress:toAddress, status:status, 
         permitNumber:permit, from:from, to:to, bookingNumber:bookingNumber, driverNumber:driverNumber, regionName:regionName, phoneNumber:phoneNumber, take:defaultTake, index:0}));
   }


   const handleOnScrollCb = function() {
      var index = searchModel.index + searchModel.take;
      setSearchModel(searchModel=> ({...searchModel,take:defaultTake, index:index}));
   }

   const doTextSearch = function(searchText) {
      setSearchModel(searchModel=> ({...searchModel,take:defaultTake, index:0, searchString:searchText, from:null, to:null}));
   }

   const onSearchTextChanged = function(e) {
      const { value } = e.target;

      if (inputTimer !== null){
         clearTimeout(inputTimer);
       }
   
       inputTimer = setTimeout(() => {
         doTextSearch(value);
       }, keyPressTimer); 
   }

   return (
      <>
         <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
         <div className="top-adjustment" style={{overflow:"hidden", height:"90vh", paddingRight:"10px",paddingBottom:"10px"}}>
            <div style={{width:"100%", height:"100%"}}>
               <div className="flex w-full trip_header ml-2" >
                  <div className="col-6 text-left">
                        <img src={trip_home_icon} alt="home" />
                        
                        <span className="font-bold"> HJEM &nbsp; &nbsp; / &nbsp; </span>
                        <span className="font-bold uppercase"> REGNSKAP</span>
                  </div>
                  <div className="col-6 text-right">
                        <input type="text" className="search_input" placeholder="Fritekst søk ..." onChange={(e) =>onSearchTextChanged(e)}></input>
                        <button type="button" className="sorter_btn" onClick={() => onViewSearch()}> <AiOutlineSearch /> 
                           {searchOpen? <span>lukk søk </span>:<span>vis søk </span>}
                        </button>
                        <button type="button" className="export_btn" onClick={() => handleExport()}> eksporter </button>
                           {printProgressShow?
                              <div>
                                 <BatteryLoading style={{right: "0px",top:"0px",left:"88%",height:"8px"}}/>
                              </div>
                              :''
                           }
                  </div>
               </div>
               <AccountSearch showSearch={searchOpen} onSearchCb={handleOnSearchCb}></AccountSearch>
               <AccountTable tripList={trips} onScrollCb={handleOnScrollCb} onSortCb={handleOnSortCb} role={role}></AccountTable>
            </div>
         </div>
         </div>
      </>
   )
}
export default Index