import React, { useState } from 'react'
import Validation from '../forms/validation'
import { Alert } from '../alerts'

const Login = ({ message = null , props}) => {
    const [data, onSubmit] = useState(null);
    let items = [{
        label: 'Epost',
        error: { required: 'Please enter a valid email address.' },
        name: 'email',
        type: 'email',
        placeholder: '',
    },
    {
        label: 'Passord',
        error: {
            required: 'Password is required.',
        },
        name: 'password',
        type: 'password',
        placeholder: ''
    },
    {
        label: null,
        name: 'remember',
        type: 'checkbox',
        options: [{ value: false, label: 'Husk meg' }]
    },
    ]
    return (
        <>
            <div className="flex flex-col">
                {data && message && (
                    <div className="w-full mb-4">
                        <Alert
                            color="bg-transparent border-green-500 text-green-500"
                            borderLeft
                            raised>
                            {message}
                        </Alert>
                    </div>
                )}
                <Validation items={items} onSubmit={onSubmit} props={props}/>
            </div>
        </>
    )
}

export default Login