import React, { useState, useEffect, useCallback} from 'react'
import { useParams } from "react-router-dom";
import trip_home_icon from './../assets/image/web-icons/home.png'
import '../css/main.css'
import { history } from '../functions/_helpers'
import _ from "lodash";
import { permitService, uploadService } from '../functions/services'
import {useDropzone} from 'react-dropzone'
import { FaRegFile, FaRegFilePdf, FaRegFileImage, FaPlus  } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import jwt from 'jwt-decode'

const  Permit= ({props}) => {
   const { id, permitHolderId }= useParams();
   const [submitted, setSubmitted] = useState(false);
   const [changes, setChanges] = useState(false);
    
   const [permit, setPermit] = useState({
      permitId:0,
      permitHolderId: 0,
      permitDocumentUrl: '',
      permitNumber:'',
      shortNumber:'',
      deleted:false
   })


   useEffect(() => {
      // Load permit
      if (id > 0) {
         permitService.getPermit(id)
         .then(
         (response) => {
            setPermit(response);
         },
         (error) => {
               console.log(error);
         }
         )
      }
      else {
         setPermit(permit => ({ ...permit, permitHolderId: parseInt(permitHolderId)}));
      }

   }, []);

   /* Callback for profile image */
   const onDrop = useCallback(acceptedFiles => {
      // Only allow one file
      if (acceptedFiles.length != 1) {
          return;
      }

      var fileType = acceptedFiles[0].name.split('.').pop().toLowerCase();

      // allow jpg, png and gif
      if (fileType != 'jpg' && fileType != 'jpeg' && fileType != 'png' && fileType != 'gif' && fileType != 'pdf') {
         return;
      }

      setChanges(true);

      uploadService.upload(acceptedFiles[0])
      .then ((fileName) => {
         var url = process.env.REACT_APP_API_URL + '/' + fileName;

         setPermit(permit => ({ ...permit, permitDocumentUrl: url}));
      })
      .catch((err) => console.error(err));

   }, []);

   const {getRootProps, getInputProps } = useDropzone({onDrop});
 


   /* Check access rights. Only admin (1) is allowed */
   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }

   // Check Role
   const decodedToken = jwt(user_data.token);
   if (decodedToken == null || decodedToken == undefined) {
         localStorage.clear();
         history.push('/login');  
   }
   const role = parseInt(decodedToken.role);

   if(role !== 1){
      history.push('/');
      return null;
   }





   function handleChange(e) {
      const { name, value } = e.target;
      setPermit(permit => ({ ...permit, [name]: value }));
      setChanges(true);
   }
 
   const openDocument= function(e, url) {
      e.stopPropagation();
      window.open(url, '_blank');
   }
   
  
   const deleteDocument= function(e) {
      e.stopPropagation();
      setChanges(true);
      permit.permitDocumentUrl=null;  
      setPermit(permit => ({ ...permit, permitDocumentUrl: null}));
   }

   const deletePermit = function() {
      confirmAlert({
         title: '',
         message: 'Vil du slette løyvet?',
         buttons: [
           {
             label: 'Ja',
             onClick: () => doDelete()
           },
           {
             label: 'Nei',
             onClick: () => doNothing()
           }
         ]
       });
   }

   function doNothing(){
   }
    
   function doDelete(){
      permitService.deletePermit(id)
      .then(
        (response) => {
         handleBack();
        },
        (error) => {
           console.log(error);
        }
      )
   }

   function handleSubmit(e) {
      e.preventDefault();
      setSubmitted(true);
      
      if(changes) {

         if (id > 0) {
            permitService.putPermit(permit)
            .then(
            (response) => {
               handleBack();
            },
            (error) => {
               console.log(error);
            })
         }
         else {
            console.log(permit);
            permitService.postPermit(permit)
            .then(
            (response) => {
               handleBack();
            },
            (error) => {
               console.log(error);
            })
         }
      }
   }


   const handleBack = () => {
      history.push('/permitHolderEdit/' + permitHolderId);
   }

   const getMenuText = function() {
      if (id == 0){
         return "LEGG TIL";
      }
      else {
         return "ENDRE";
      }
   }


   const getHeading = function() {
      if (id == 0){
         return "Nytt løyve";
      }
      else {
         return "Endre Løyve nr: " + permit.permitNumber;
      }
   }


   const getFileIconHtml = function(url) {
      var fileType = url.split('.').pop().toLowerCase();
      if (fileType === 'pdf') {
         return (<FaRegFilePdf className="document-icon document-icon-pdf" onClick={(e) =>openDocument(e, url)}></FaRegFilePdf>);
      }
      else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif') {
         return (<FaRegFileImage className="document-icon document-icon-image" onClick={(e) =>openDocument(e, url)}></FaRegFileImage>);
      }
      else {
         return (<FaRegFile className="document-icon" onClick={(e) =>openDocument(e, url)}></FaRegFile>);
      }
   }


   const getDocument = function(documentUrl) {
      if (documentUrl !== null && documentUrl !== undefined && documentUrl !== '') {
         return (
            <div className="document-upload-container">
               <div className="document-icon-container" {...getRootProps()} title="Klikk for å laste opp nytt bilde">
                  <input  {...getInputProps()} />
                  {getFileIconHtml(documentUrl)}
               </div>
               <div className="document-delete" title="Slett dokument"><FaPlus className="document-delete-icon" onClick={(e) =>deleteDocument(e)}></FaPlus></div>
            </div>
         )
      }
      else {
         return ( 
            <div className="document-upload-container">
               <div className="upload-dialog-border" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="frame-header">Dra & slipp</div>
                  <div className="frame-text">eller klikk for å laste opp</div>
               </div>
            </div>);
      }
   }


    return (
      <>
         <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
            <div className="flex w-full trip_header ml-2" >
               <div className="col-6 text-left">
                  <img src={trip_home_icon} alt="home" />
                  <span className="font-bold"> HJEM &nbsp; &nbsp; / &nbsp; </span>
                  <span className="font-bold uppercase"> Løyve &nbsp; &nbsp; / &nbsp; </span>
                  <span className="font-bold"> {getMenuText()} &nbsp; &nbsp; / &nbsp; </span>
               </div>
               <div className="text-right inline-flex" style={{position:"absolute",right:"30px"}}>
                  <button type="button" className="add_btn" id="backBtn" onClick={()=>handleBack()}> Tilbake </button>
               </div>
            </div>
            <div className="form-container">
               <form name="form" className="form permits" onSubmit={handleSubmit} >
                  <h4 className="trip_title font-bold mb-3"> {getHeading()} </h4>
                  <div className="trips ">
                     <div>
                        <label className="font-bold block"> Løyvenummer </label>
                        <input type="text" style={{border:"1px solid #37708e"}} name="permitNumber" value={permit.permitNumber} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleChange} className={'form-control' + (submitted && !permit.permitNumber ? ' is-invalid' : '')} />
                     </div>
                     <br></br>
                     <div>
                     <label className="font-bold block"> Kortnummer </label>
                     <input type="text" style={{border:"1px solid #37708e"}} name="shortNumber" value={permit.shortNumber} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleChange} className={'form-control' + (submitted && !permit.shortNumber ? ' is-invalid' : '')} />
                     </div>
                     <br></br>
                     <div className="document-container trips">
                           <label className="font-bold block">Løyve dokument </label>
                           {getDocument(permit.permitDocumentUrl)}
                        </div>
                  </div>
                  <div className="driver_btns text-right mt-10" style={{float: "right",marginRight: "0px",height: "45px",margin: "30px auto"}}>
                     <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>deletePermit(e)}> Slett </button>                            
                     <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>handleBack(e)}> Avbryt </button>                            
                     <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>handleSubmit(e)}> Lagre </button>                            
                  </div>
               </form>
            </div>
         </div>
      </>
    )

}
export default Permit
