import React, { Component } from 'react'


class TripStatus extends Component {

    render() {
        const {bookingStatus, cancelReason} = this.props;
  
        var status = "";
        var cancelledReason = "";

        if (bookingStatus != null)
        {
            switch (bookingStatus) {
            case 3:
                status = "Aktiv";
                break;
            case 4:
                status = "Fullført";
                break;
            case 5:
                status = "Avbrutt";
                break;
            default:
                break;
            }
        }
      
        if (cancelReason != null)
        {
            var delimitor='';
            if (status != '') {
                delimitor=' - ';
            }
            switch (cancelReason) {
               case 0:
                    cancelledReason = "";
                    break;
                case 1:
                    cancelledReason = delimitor + "Ingen bil";
                    break;
                case 2:
                    cancelledReason = delimitor + "Maks turer";
                    break;
                case 3:
                    cancelledReason = delimitor + "Maks turer";
                    break;
                case 4:
                    cancelledReason = delimitor + "Svartelistet";
                    break;
                case 5:
                    cancelledReason = delimitor + "Ugyldig adr.";
                    break;
                case 6:
                    cancelledReason = delimitor + "Ugyldig adr.";
                    break;
                case 7:
                    cancelledReason = delimitor + "av kunde";
                    break;
                case 8:
                    cancelledReason = delimitor + "av dispatcher";
                    break;
                case 9:
                    cancelledReason = delimitor + "ikke møtt";
                    break;
                case 10:
                    cancelledReason = delimitor + "krav ikke møtt";
                    break;
                case 11:
                    cancelledReason = delimitor + "Problem med bil";
                    break;
                case 12:
                    cancelledReason = delimitor + "Annet";
                    break;
                case 13:
                    cancelledReason = delimitor + "Annet";
                    break;
                case 14:
                    cancelledReason = delimitor + "Annet";
                    break;
                case 15:
                    cancelledReason = delimitor + "Annet";
                    break;
                case 16:
                    cancelledReason = delimitor + "Annet";
                    break;                    
                default:
                    break;
            }
        }


      return (
        <span>{status}{cancelledReason}</span>
      )
    }
  }
  
  export default TripStatus;