import React,{useState} from 'react'
import default_profile from './../../assets/image/web-icons/defaultProfile.png'
import {config,authHeader} from './../../functions/_helpers'
import axios from 'axios'
const Avatars = ({items}) => {
 
  
  const [imageExist, setImageExist] = useState(false);
 
  if(typeof(items) === 'undefined' || items === null  || items.length <= 0 || items[0] === null || typeof(items) == [null] || typeof(items)===[undefined]){
    
    return(<div className="flex flex-row items-center justify-start">
      <img
          src={default_profile}
          alt="media"
          className={`h-8 w-8 shadow-outline rounded-full `}
        />
    </div>) 
    }
    
    checkUrl(items);
    
    function checkUrl(imageUrl){
  
   
      axios.get(imageUrl,{headers: authHeader()}).then((response) => {
        setImageExist(true);
    }).catch((error) => {
        setImageExist(false);
    })
    
  }
  return (
    <div className="flex flex-row items-center justify-start">
      {imageExist ?
        
        <img
          src={items != null ? items: default_profile}
          alt="media"
          className={`h-8 w-8 shadow-outline rounded-full object-fit-cover`}
        />:<img src={default_profile} alt="media" className={`h-8 w-8 shadow-outline rounded-full`}/>
        
      }
    </div>
  )
}

export default Avatars
