import React from 'react'
import {Link} from 'react-router-dom'

const AccountLinks = () => {
  const items = [
    // {
    //   url: '/profile',
    //   name: 'MIN PROFIL',
    //   badge: null
    // },
    {
      url: '/logout',
      name: 'LOGG UT',
      badge: null
    }
  ]

  return (
    <div className="flex flex-col w-full">
      <ul className="list-none">
        {items.map((item, i) => (
          <li key={i} className="dropdown-item">
            <Link
              to={item.url}
              className="flex flex-row items-center justify-start h-10 w-full px-2 py-3">
              {item.icon}
              <span className="mx-2">{item.name}</span>
              {item.badge && (
                <span
                  className={`badge badge-circle badge-sm ${item.badge.color} ml-auto`}>
                  {item.badge.number}
                </span>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AccountLinks
