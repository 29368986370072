import React from 'react'
import {FiMenu} from 'react-icons/fi'
import { MdClose } from 'react-icons/md';
// import { AiOutlineMenu } from "react-icons/ai";
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'
import './../../css/main.css';
const Logo = () => {
  const dispatch = useDispatch()
  const {config, leftSidebar} = useSelector(
    state => ({
      config: state.config,
      leftSidebar: state.leftSidebar
    }),
    shallowEqual
  )

  const {collapsed} = {...config}
  const {showLogo} = {...leftSidebar}
  if (showLogo) {
    return (
      <div className="logo">
        <button className="fimenu screen-adjust-fimenu"
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'collapsed',
              value: !collapsed
            })
          }
          >
          <FiMenu size={20}/>
        </button>
        <button className="fimenu screen-adjust-closeMenu"
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'collapsed',
              value: !collapsed
            })
          }
          >
          <MdClose size={20}/>
        </button>
        <Link
          to="/"
          className="flex flex-row items-center justify-start space-x-2">
          <span className="logo_text"> INTER <span className="cab">CAB </span> </span>
        </Link>

      </div>
    )
  }
  return null
}

export default Logo
