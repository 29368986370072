import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ResponsiveContainer, ComposedChart, Line, Area, Legend, Tooltip, Bar, CartesianGrid, YAxis, XAxis } from "recharts";
import useStyles from "./styles";
import Widget from "./Widget";
import { Typography } from "./Wrappers";
import Table from "./Table/Table";
import TripStatistics from "./TripStatistics/TripStatistics";
import RevenueStatistics from "./RevenueStatistics/RevenueStatistics";
import MapContainer from "./MapContainer/MapContainer";
import axios from 'axios'
import {authHeader,history} from '../../functions/_helpers'
import {SemipolarLoading   } from 'react-loadingg'
import CabList from '../cab/cabList'


const Dashboard = (props) => {
  const dispatch = useDispatch();
  const [cabData, setCabData] = useState({ hits: [] });
  const [tripStatisticsData, setTripStatisticsData] = useState();
  const [revenueStatisticsData, setRevenueStatisticsData] = useState();
  const [specialLocations, setSpecialLocations] = useState([]);
  const [tripTrendData, setTripTrendData] = useState();
  const [centerLatitude, setCenterLatitude] = useState(0); // 58.14671
  const [centerLongitude, setCenterLongitude] = useState(0); //7.9956
  const [regions, setRegions] = useState([]);

  //const [data, setData] = useState({ hits: [] });
  let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
  var classes = useStyles();
  var theme = useTheme();

  
  useEffect(() => {

    // Todo : Add custom dashboard for drivers and license holders

    const fetchRegions = async () => {
      await axios.get(process.env.REACT_APP_API_URL+'/api/regions/',{headers:authHeader()}
      ).then((response)=>{
        setRegions(response.data);
      }).catch((error)=>{
        console.log(error);
      });
    }

    const fetchCabStatus = async () => {
          await axios.get(process.env.REACT_APP_API_URL+'/api/dashboard/cabs/',{headers:authHeader()}
          ).then((response)=>{
            setCabData(response.data);
          }).catch((error)=>{
            console.log(error);
          }
        );
    };

    const fetchActiveTrips = async () => {

    }

    const fetchTripStatistics = async () => {
        await axios.get(process.env.REACT_APP_API_URL+'/api/dashboard/tripStatistics' ,{headers:authHeader()}
        ).then((response)=>{
          setTripStatisticsData(response.data);
        }).catch((error)=>{
          console.log(error);
        }
      );
    };
    const fetchTripTrendData = async () => {
      await axios.get(process.env.REACT_APP_API_URL+'/api/dashboard/tripTrendData/29',{headers:authHeader()}
      ).then((response)=>{
        setTripTrendData(response.data);
      }).catch((error)=>{
        console.log(error);
      });
    };

    const fetchRevenueStatistics = async () => {
        await axios.get(process.env.REACT_APP_API_URL+'/api/dashboard/revenueStatistics',{headers:authHeader()}
        ).then((response)=>{
          setRevenueStatisticsData(response.data);
        }).catch((error)=>{
          console.log(error);
        });
      };

      const fetchSpecialLocations = async () => {
        await axios.get(process.env.REACT_APP_API_URL+'/api/dashboard/specialLocations',{headers:authHeader()}
        ).then((response)=>{
          setSpecialLocations(response.data);
        }).catch((error)=>{
          console.log(error);
        });
      };



  axios.interceptors.response.use( (response) => {
      // Return a successful response back to the calling service
      return response;
    }, (error) => {
      // Return any error which is not due to authentication back to the calling service
      if (error.response.status === 401) {
          localStorage.removeItem('user');
          localStorage.clear();
          history.push('/login');
      }else{
          return new Promise((resolve, reject) => {
              reject(error);
            });
      }
      
    });

    // Load data
    fetchRegions();
    fetchCabStatus();
    fetchTripStatistics();
    fetchTripTrendData();
    fetchRevenueStatistics();
    fetchSpecialLocations();

    // Then refresh every n seconds
    const interval = setInterval(() => {
      fetchTripStatistics();
      fetchTripTrendData();
      fetchRevenueStatistics();
    }, 10000);

    const interval2 = setInterval(() => {
      fetchCabStatus();
    }, 5000);

    return () => {clearInterval(interval); clearInterval(interval2)};
  }, []);


  useEffect(() => { 
    if (regions.length > 0) {
      setCenterLatitude(regions[0].latitude);
      setCenterLongitude(regions[0].longitude);
    }

  }, [regions]);


  const handleCabSelected = function(id) {
    var selectedCab=null;
    cabData.map(cab => {
      if (cab.cabId == id) {
        selectedCab = cab;
      }
    });

    if (selectedCab != null) {
      setCenterLatitude(selectedCab.latitude);
      setCenterLongitude(selectedCab.longitude);
    }
  }



  if(typeof(cabData) === 'undefined' || cabData.length <= 0 || cabData.length ===undefined 
    || typeof(tripStatisticsData) === 'undefined'
    || typeof(revenueStatisticsData) === 'undefined'
    || typeof(tripTrendData) === 'undefined'){
      return <SemipolarLoading/>
  }
  
  const cabLocations = cabData.filter(function(cab) {
    if (cab.permitNumber == null || cab.permitNumber == "") {
      return false; // skip
    }
    return true;
  }).map(data => {
    var title = 'Løyve: ' + data.permitNumber;
    title = title + '\r\nLøyvehaver: '  + data.permitHolderName;
    var status = data.cabStatus;
    if (data.connected == 0 || data.driverNumber == '' || data.driverNumber== 'null') {
      status = 0;
    }

    if (status > 0) {
      title = title + '\r\nSjåfør: '  + data.driverName;
    }

    if (status == 2) {
      if (data.isManual) {
        title = title + '\r\Venter på bestilling fra kunde i bil ';
      }
      else {
        title = title + '\r\nFra: ' + data.from;
        title = title + '\r\nTil: ' + data.to;
        title = title + '\r\nPris: ' + data.price + ' kr';
        title = title + '\r\Tur-kø: ' + data.queueSize;
        
      }
    }

    return {
      latLng: {lat: data.latitude, lng: data.longitude}, 
      title: title, 
      status: status,
      cabId: data.cabId,
      queuePosition:data.queuePosition
    }
  })




  const calculateSumForProperty = (data,property) => {
    const periods = ["daily", "weekly", "monthly"];
  
    return periods.reduce((acc, period) => {
      acc[period] = Object.values(data).reduce((sum, item) => {
        return sum + item[property][period];
      }, 0);
  
      return acc;
    }, {});
  };

  const calculateSumForTripTrend = (tripTrendData) => {
    const index = Object.keys(tripTrendData[Object.keys(tripTrendData)[0]]); 
    //console.log(tripTrendData)
    return index.map((index) => {
      return {
        completed: Object.values(tripTrendData).reduce((sum, item) => sum + item[index].completed, 0),
        cancelled: Object.values(tripTrendData).reduce((sum, item) => sum + item[index].cancelled, 0),
        booked: Object.values(tripTrendData).reduce((sum, item) => sum + item[index].booked, 0),
        revenue: Object.values(tripTrendData).reduce((sum, item) => sum + item[index].revenue, 0),
        sampleDate: tripTrendData[Object.keys(tripTrendData)[0]][index].sampleDate
      };
    });
  };

  const sumActive = calculateSumForProperty(tripStatisticsData,"active");
  const sumBookings = calculateSumForProperty(tripStatisticsData,"bookings");
  const sumCancelled = calculateSumForProperty(tripStatisticsData,"cancelled");
  const sumCompleted = calculateSumForProperty(tripStatisticsData,"completed");

  const revenueTotal = calculateSumForProperty(revenueStatisticsData, "total")
  const tripTrendArray = calculateSumForTripTrend(tripTrendData);

  
  return (
    <>
       <Grid container spacing={1}>
        <Grid item md={3} sm={6} xs={12} key={'Turer'}>
          <TripStatistics title="Aktive turer" active={sumActive} completed={sumCompleted} bookings={sumBookings} cancelled={sumCancelled} />
        </Grid>
        <Grid item md={3} sm={6} xs={12} key={'inntekter'}>
          <RevenueStatistics title="Omsetning" total={revenueTotal}  />
        </Grid>
        <Grid item sm={6} xs={6}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <div className={classes.mainChartHeaderLabels}>
                  <div className={classes.mainChartHeaderLabel}>
                    {/* <Dot color="primary" /> */}
                    <Typography className={classes.mainChartLegentElement}>
                      Siste 30 dager
                    </Typography>
                  </div>
                 </div>
              </div>
            }
          >
            
            <ResponsiveContainer width="100%" minWidth={400} height={150}>
              <ComposedChart
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                data={tripTrendArray}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="sampleDate"/>
                <YAxis yAxisId={1} orientation="right" label={{ value: "kroner", angle: -90, dx:20}}/>
                <YAxis yAxisId={2}/>

                <Tooltip position={{ y: 0 }} />
                <Legend />
                <Area  yAxisId={2} name="Forespørsler" type="monotone" dataKey="booked" fill="#8884d8" stroke="#8884d8" />
                <Bar  yAxisId={2} name="Fullførte turer" dataKey="completed" barSize={20} fill="#413ea0" />
                <Line  yAxisId={2} name="Kansellerte turer" type="monotone" dataKey="cancelled" stroke="#ff7300" />
                <Line  yAxisId={1} name="Omsetning" type="monotone" dataKey="revenue" stroke="#2a703c" strokeWidth={2}/>
                {/* <Scatter dataKey="cnt" fill="red" /> */}
              </ComposedChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>



        <Grid item md={6} sm={6} xs={12}>
           <CabList  data={cabData} onCabSelected={handleCabSelected}></CabList>
          {/* <Widget
            title="Biler"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableWidget}
          >
            <Table data={tableData} onCabSelected={handleCabSelected}/>
          </Widget> */}
        </Grid>


        <Grid item md={6} sm={6} xs={12}>
          { centerLatitude > 0 &&
            <MapContainer markersData={cabLocations} centerLatitude={centerLatitude} centerLongitude={centerLongitude} specialLocations={specialLocations} />
          }
        </Grid>

      </Grid>
    </>
  );
}


export default Dashboard