import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  tableLayout: {
  },
  tableHeaderLayout: {
  },
  tableBodyLayout: {
  },
  tableRowLayout: {
    backgroundColor:'white',
    '&:hover': { backgroundColor:'#eee' },
  },
  tableColLayout: {
    paddingTop:10,
    paddingBottom:10,
  },
}));
