import React, { useRef, useState, useEffect } from 'react'
import Section from '../components/dashboard/section'
import Drivers from '../components/dashboard/drivers'
import trip_home_icon from '../assets/image/web-icons/home.png'
import './../css/main.css'
import {history } from './../functions/_helpers'
import {Link} from 'react-router-dom'
import {FiSearch} from 'react-icons/fi'
import _ from "lodash";
import jwt from 'jwt-decode'
import {driverService} from '../functions/services'

function Index() {
    const addBtns = useRef(null);
    const searchIcon = useRef(null);


    const [drivers, setDrivers] = useState([]);
    const [filteredDrivers, setFilteredDrivers] = useState([]);
    const [searchString, setSearchString] = useState('');
    const driversRef = useRef();
    const filteredDriversRef = useRef();

    driversRef.current = drivers;
    filteredDriversRef.current = filteredDrivers;

    let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
     if(user_data === null || user_data.length === 0)
    {
        localStorage.clear();
        history.push('/login');
    }

    // Check Role
    const decodedToken = jwt(user_data.token);
    if (decodedToken == null || decodedToken == undefined) {
        localStorage.clear();
        history.push('/login');  
    }
    const role = parseInt(decodedToken.role);


    
   useEffect(() => {
      const fetchData = async () => {        
         await driverService.getDrivers()            
         .then((response)=>{
            setDrivers(response);
            setFilteredDrivers(response);
         }).catch((error)=>{
            console.log(error.message);
         });                  
      };

      fetchData();
   }, []);




   if(role === 3){
      history.push('/myTrips');
      return null;
   }


   const handleSearch= (e) => {
      const { name, value } = e.target;
      setSearchString(value);
      filterDrivers(drivers, value);
   }

   const filterDrivers = (array, filter) => {
      setFilteredDrivers(array.filter(driver => 
         (driver.driverNumber != null && driver.driverNumber.toLowerCase().indexOf(filter) >= 0) ||
         (driver.firstName != null && driver.firstName.toLowerCase().indexOf(filter) >= 0) ||
         (driver.lastName != null && driver.lastName.toLowerCase().indexOf(filter) >= 0) ||
         (driver.email != null && driver.email.toLowerCase().indexOf(filter) >= 0) ||
         (driver.phoneNumber != null && driver.phoneNumber.toLowerCase().indexOf(filter) >= 0)
         ));
   }


   const onDriverDelete = (id) => {
      driverService.deleteDriver(id)
      .then(
         (response) => {
            setDrivers(driversRef.current.filter(driver => driver.driverId !== id));
            setFilteredDrivers(filteredDriversRef.current.filter(driver => driver.driverId !== id));
         },
         (error) => {
            console.log(error);
         }
      )
   }


   const onDriverDeactivate = (id) => {
      driverService.deactivateDriver(id)
      .then(
         (response) => {
            setDrivers(driversRef.current.map(driver => driver.driverId === id ? {...driver, active : false}  : driver));
            setFilteredDrivers(filteredDriversRef.current.map(driver => driver.driverId === id ? {...driver, active : false}  : driver));
       },
         (error) => {
            console.log(error);
         }
      )
   }

   const onDriverActivate = (id) => {
      driverService.activateDriver(id)
      .then(
         (response) => {
            setDrivers(driversRef.current.map(driver => driver.driverId === id ? {...driver, active : true}  : driver));
            setFilteredDrivers(filteredDriversRef.current.map(driver => driver.driverId === id ? {...driver, active : true}  : driver));
         },
         (error) => {
            console.log(error);
         }
      )
   }


    const addBox = () => {    
      history.push('/driverEdit/0');
      return;
   }

 
    return (
        <>
            <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
                <div className="flex w-full trip_header ml-2">
                    <div className="col-6 text-left">
                        <img src={trip_home_icon} alt="home" />
                        <span className="font-bold"> <Link to='/' className="non-undeline-link">HJEM &nbsp; &nbsp; / &nbsp; </Link></span>
                        <span className="font-bold"> <Link to='/drivers' className="non-undeline-link" >SJÅFØRER &nbsp; &nbsp; / &nbsp; </Link></span>
                    </div>
                    <div className="col-6 text-right" style={{display: "inline-flex"}}>
                        <input type="search" placeholder="Søk"   onChange={handleSearch} className="pl-10 pr-5 appearance-none h-10 w-full rounded-full text-sm focus:outline-none simple_sorter_btn_search"/>
                        <button type="submit" className="absolute top-0 mt-3 left-0 ml-4 top-adjust" ref={searchIcon} style={{top:"-6px"}}>
                            <FiSearch className="stroke-current h-4 w-4" />
                        </button>
                        <button hidden={role != 1}  type="button" className="add_btn" ref={addBtns} id="addBtn" onClick={() => addBox()}> legg til </button>
                    </div>
                </div>
                <Section>
                    <Drivers drivers={filteredDrivers} onDriverDelete={onDriverDelete} onDriverDeactivate={onDriverDeactivate} onDriverActivate={onDriverActivate} />
                </Section>

            </div>
        </>
    )
}
export default Index
