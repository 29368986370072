import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {ModalBody} from './body'
import {history} from './../../functions/_helpers'
import '../../css/components/modals.css'
import '../../css/main.css'
import {store} from './../../store'
import {userActions} from './../../functions/_actions'

const Modal = () => {
  const [hidden, setHidden] = useState(true)
  const dispatch = useDispatch()
  const show = () => {
    setHidden(false)
    dispatch({
      type: 'SET_CONFIG_KEY',
      key: 'backdrop',
      value: true
    })
  }
  const hide = () => {
    setHidden(true)
    dispatch({
      type: 'SET_CONFIG_KEY',
      key: 'backdrop',
      value: false
    })
  }
  const onClick_tilbake = () => {
   
    history.goBack();
  }
  const onClick_logout = () => {
    var user = JSON.parse(localStorage.getItem('user')) == null ? [] : JSON.parse(localStorage.getItem('user'));
    const userToOut = {
      firstName: user.firstName,
      lastName:user.lastName,
      userId : user.id,
      email: user.email,
      token: user.token
    }
    var result = store.dispatch(userActions.logout(parseInt(user.id),user.token));
    if(result.type === 'USERS_LOGOUT'){
      localStorage.clear();
      history.push('/login');
    }
  }
  return (
   
        <>
          <div className="modal show">
            <div className="relative w-auto lg:my-4 mx-auto lg:max-w-lg max-w-sm">
              <div className="modal-content" style={{height:"250px"}}>
              <h4 className="text-xl font-semibold" style={{paddingTop:"75px",textAlign:"center"}}>Logg Ut</h4> 
              <ModalBody />
              <div className="modal-footer space-x-2">
              <button
                className="sign_btn" style={{display:"Inline"}}
                type="button"
                onClick={onClick_tilbake}>
                Tilbake
              </button>
              <button
                className="sign_btn" style={{display:"Inline"}}
                type="button"
                onClick={onClick_logout}>
                Logge ut
              </button>
              </div>
                {/*<ModalHeader onClick={hide} />
                <ModalBody />
                <ModalFooter onClick={hide} />*/}
              </div>
            </div>
          </div>
        </>
   
  )
}

export default Modal
