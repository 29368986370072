import React, { Component } from 'react';

class PermitUserInfo extends Component {
  
  constructor(props) {
    super(props);
  }

  render() {
    const {items} = this.props;

    return (
      <div className="flex flex-row items-center justify-start" style={{fontSize: "12px"}}>
          <ul>
              <li key={items}>
                <span><span style={{color:"gray"}}>Fornavn: </span>{items.original.firstName}</span><br/>
                <span><span style={{color:"gray"}}>Etternavn: </span>{items.original.lastName}</span><br/>
              </li>
        </ul>

      </div>
    )
  }
}
export default PermitUserInfo;