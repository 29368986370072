import React, { Component } from 'react'
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

class SearchIndicator extends Component {

   render() {
      const {visible, ascending} = this.props;

      if (visible) {
         if (ascending) {
            return (
               <div className="search-indicator-box"><FaCaretUp className="stroke-current text-2xs" /></div>
            )      
         }
         else {
            return (
               <div className="search-indicator-box"><FaCaretDown className="stroke-current text-2xs" /></div>
            )      
         }
      }
      // Add a hidden element (that takes up the same amount of space) so that styling is not messed up
      return (<div className="search-indicator-box" style={{visibility:'hidden'}}><FaCaretDown className="stroke-current text-2xs" /></div>)
   }
}
  
export default SearchIndicator;