import React from 'react';
import L from 'leaflet';
import 'react-leaflet-fullscreen/dist/styles.css'
import FullscreenControl from 'react-leaflet-fullscreen';
import "leaflet.heat";

// styles
import useStyles from "./styles";

function HeatmapContainer({ centerLatitude, centerLongitude, addressPoints }) {
   var classes = useStyles();
   
   // create map
   const mapRef = React.useRef(null);
   React.useEffect(() => {
      mapRef.current = L.map("map", {
         center: [58.14671, 7.9956],
         zoom: 12,
         layers: [
         L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            attribution:
               '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
         })
         ],
         fullscreenControl: true,
         fullscreenControlOptions: {
         position: 'topleft'
         }
      });
   }, []);

   // add layer
   const layerRef = React.useRef(null);
   React.useEffect(() => {
      layerRef.current = L.layerGroup().addTo(mapRef.current);
   }, []);

   React.useEffect(() => {
      mapRef.current.flyTo(new L.LatLng(centerLatitude, centerLongitude));
   }, [centerLongitude]);


   const heatLayerRef = React.useRef(null)
   React.useEffect(() => {
      console.log(addressPoints);
      if (heatLayerRef.current) {
         mapRef.current.removeLayer(heatLayerRef.current);
      }

    
      const points = addressPoints
         ? addressPoints.map((p) => {
            return [p.latitude, p.longitude];
         })
         : [];

         heatLayerRef.current = L.heatLayer(points).addTo(mapRef.current);

   }, [addressPoints]);





  return (<div id="map"  className={classes.mapLayout}/>);
}

export default HeatmapContainer;