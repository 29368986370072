import React from 'react'
import { FaCar ,FaRoute,FaTaxi, FaCog , FaCalendar, FaRegAddressCard, FaDollarSign, FaMap, FaHistory, FaGlobe, FaCommentDots, FaTelegramPlane} from 'react-icons/fa';
import { MdSupervisorAccount } from "react-icons/md";
import { GiSteeringWheel } from "react-icons/gi";
import { RiStackshareLine } from "react-icons/ri";
import { RiDashboardLine } from "react-icons/ri";
import {history} from './../functions/_helpers'
import jwt from 'jwt-decode'

const initialState = [
  {
    //let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'))
    // icons: https://react-icons.github.io/react-icons/icons?name=fa
    
    items: [
      {
        url: '/dashboard',
        icon: <RiDashboardLine size={20} />,
        title: 'DASHBOARD',
        items: []
      },
      {
        url: '/myTrips',
        icon: <FaRoute size={20} />,
        title: 'MINE TURER',
        items: []
      },
      {
        url: '/shifts',
        icon: <FaHistory size={20} />,
        title: 'Mine skift',
        items: []
      },
      {
        url: '/drivers',
        icon: <GiSteeringWheel size={20} />,
        title: 'SJÅFØRER',
        items: []
      },
      {
        url: '/cabs',
        icon: <FaTaxi size={20} />,
        title: 'BILER',
        items: []
      },
      {
        url: '/permitHolders',
        icon: <FaRegAddressCard size={18} />,
        title: 'Løyvehavere',
        items: []
      },
      {
        url: '/users',
        icon: <MdSupervisorAccount size={22} />,
        title: 'brukere',
        items: []
      },
      {
        url: '/settings',
        icon: <FaCog size={20} />,
        title: 'innstillinger',
        items: []
      },
      {
        url: '/regions',
        icon: <FaGlobe size={20} />,
        title: 'kjøreområder',
        items: []
      },
      {
        url: '/accounting',
        icon: <FaDollarSign size={20} />,
        title: 'regnskap',
        items: []
      },
      {
        url: '/messages',
        icon: <FaTelegramPlane size={20} />,
        title: 'Meldinger',
        items: []
      },
      {
        url: '/preOrders',
        icon: <FaCalendar size={20} />,
        title: 'Forhåndsbestilling',
        items: []
      }
      // {
      //   url: '/heatmap',
      //   icon: <FaMap size={20} />,
      //   title: 'heatmap',
      //   items: []
      // },
    ]
  }
]
const initialStatePermits = [
  {
    
    items: [
      {
        url: '/dashboard',
        icon: <RiDashboardLine size={20} />,
        title: 'DASHBOARD',
        items: []
      },
      {
        url: '/myTrips',
        icon: <FaRoute size={20} />,
        title: 'MINE TURER',
        items: []
      },
      {
        url: '/shifts',
        icon: <FaHistory size={20} />,
        title: 'Mine skift',
        items: []
      },
      {
        url: '/cabs',
        icon: <FaTaxi size={20} />,
        title: 'BILER',
        items: []
      }
    ]
  }
]

const initialStateDriver = [
  {   
    items: [
      {
        url: '/myTrips',
        icon: <FaRoute size={20} />,
        title: 'MINE TURER',
        items: []
      },
      {
        url: '/shifts',
        icon: <FaHistory size={20} />,
        title: 'Mine skift',
        items: []
      }
    ]
  }
]
export default function navigation(state = initialState, action) {

  let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   
  /**/
  switch (action.type) {
    default:
      if(user_data.role===3) {state = initialStateDriver; return initialStateDriver}
      if(user_data.role===2) {state = initialStatePermits; return initialStatePermits}
      if(user_data.role===1) {state = initialState; return initialState}
      return state
  }
}
