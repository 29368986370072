import React, { useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { FaEdit  } from 'react-icons/fa';
import { userActions } from '../../functions/_actions'
import { useDispatch } from 'react-redux'

const Setting = ({setting, slim}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [changes, setChanges] = useState(false);
  const [displayValue, setDisplayValue] = useState(setting.value);
  const [settingValue, setSetting] = useState({
    settingId: setting.settingId,
    value: setting.value,
    description:setting.description,
    name:setting.name,
    key:setting.key,
    group:setting.group,
  });


  React.useEffect(() => {
    setSetting(setting);
    setDisplayValue(setting.value);
 }, [setting]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => { 
    setOpen(false);
  }

  const handleSubmit = () => {   
    if (changes) {
      dispatch(userActions.updateSetting(settingValue));   
      setOpen(false);
    }

  };

  const onvalueChange = (e) => {
    const { id, value } = e.target;  

    setDisplayValue(value);
    var val =value.replace(',','.');
    let floatValue = parseFloat(val);
    if (floatValue != NaN) {
      setSetting(settingValue => ({...settingValue, [id]: floatValue}))
      setChanges(true);
    }
  }


  return (
    <>
      <div className="settings-container">
        <div className="settings-name">{setting.name}</div>
        <div className="settings-value">
          <input disabled type="text" style={{border:"1px solid #37708e"}} value={settingValue.value} className="form-control"/>
        </div>
        <div className="settings-icon">
          <FaEdit size={20} color={"#666"}  onClick={handleClickOpen}></FaEdit>
        </div>
        {slim == 1 ?
         <div className="settings-description-slim">{setting.description}</div>
         :
         <div className="settings-description">{setting.description}</div>
        }
      </div>


      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{setting.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {setting.description}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="value"
            label=""
            type="text"
            value={displayValue}
            onChange={onvalueChange} 
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Avbryt
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Lagre
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )



}

export default Setting;