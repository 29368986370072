import { authHeader, history } from '../_helpers';

export const permitService = {
  getPermit,
  putPermit,
  postPermit,
  deletePermit,
  getPermits,
  getPermitsForUser
}

function getPermits() {
   const requestOptions = {
         method: 'GET',
         headers: authHeader()
   };
   return fetch(process.env.REACT_APP_API_URL + '/api/permits/', requestOptions).then(handleResponse, handleError);
}

function getPermitsForUser(id) {
   const requestOptions = {
         method: 'GET',
         headers: authHeader()
   };
   return fetch(process.env.REACT_APP_API_URL + '/api/permits/' + id, requestOptions).then(handleResponse, handleError);
}


function getPermit(id) {
   const requestOptions = {
         method: 'GET',
         headers: authHeader()
   };
   return fetch(process.env.REACT_APP_API_URL + '/api/permits/singlePermit/' + id, requestOptions).then(handleResponse, handleError);
}

function putPermit(permit){
   const requestOptions = {
      method: 'PUT',
      headers: {...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(permit)
   };

   console.log(requestOptions.body);
   return fetch(process.env.REACT_APP_API_URL + '/api/permits/' + permit.permitId, requestOptions).then(handleResponse, handleError);
}


function postPermit(permit){
   const requestOptions = {
      method: 'POST',
      headers: {...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(permit)
   };

   return fetch(process.env.REACT_APP_API_URL + '/api/permits' , requestOptions).then(handleResponse, handleError);
}

function deletePermit(id){
   const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
   };
   return fetch(process.env.REACT_APP_API_URL + '/api/permits/' + id, requestOptions).then(handleResponse, handleError);
}


function handleResponse(response) {
return new Promise((resolve, reject) => {
      if (response.ok) {
         // return json if it was returned in the response
         var contentType = response.headers.get("content-type");
         if (contentType && contentType.includes("application/json")) {
               response.json().then(json => resolve(json));
         } else {
               resolve();
         }
      } else {
         // return error message from response body
         response.text().then(text => reject(text));
         console.log(response);
         if(response.status === 401){
            localStorage.clear();
            history.push('/login');
         }
      }
});
}
 



function handleError(error) {
   return Promise.reject(error && error.message);
}