import React, { useState, useEffect } from "react";
import '../../css/components/my-trips.css'
import FormatUtc from '../dashboard/Tools/formatUtc'
import WorkShiftPayment from './child-components/workShiftPayment'
import SearchIndicator from "./child-components/searchIndicator";
import { PictureAsPdf as PictureAsPdfIcon } from "@material-ui/icons";

const WorkShiftsTable = ({workShiftList, onScrollCb, onSortCb, role}) => {
  const [workShifts, setWorkShifts] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedWorkShift, setSelectedWorkShift] = useState({});
  const [sortField, setSortField] = useState("createdDate");
  const [ascending, setAscending] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
   setWorkShifts(workShiftList);
    setLoading(false);
  },[workShiftList]);


//   const handleClose = function() {
//     setShow(false);
//     //setSelectedTrip(null);
//   }
//   const handleShow = function(shift) {
//     if (role==1) {
//       setSelectedWorkShift(shift);
//       setShow(true);
//     }
//   }

  const handleScroll = function(e) {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    //console.log(e.target.scrollHeight + ", " + e.target.scrollTop + ", " + e.target.clientHeight);

    if (bottom && !loading) { 
      setLoading(true);
      onScrollCb(); 
    }
  }

  const handleSort= (field) => {
    var direction = ascending;
    if (field == sortField) {
      //Just change direction
      direction = !direction;
    }
    else {
      direction = false;
      // New field

    }
    setSortField(field);
    setAscending(direction);
    onSortCb(field, direction);

  }

//   const getIconColor = function(trip) {
//     if (trip.isManual) {
//       return "blue";
//     }
//     if (trip.cancelReason > 0) {
//       return "black";
//     }
//     if (trip.bookingStatus < 4) {
//       return "red";
//     }

//     return "green";
//   }

  const getDriverName = function(shift) {
    return shift.driverNumber + ' - ' + shift.firstName + ' ' + shift.lastName;
  }

  const getEndDate = function(shift) {
     if (shift.endTime == null) {
        return '-';
     }
     else 
      return <FormatUtc item={shift.endTime}/>
  }


  const getMileage = function(milage) {
    if (milage == null || milage <= 0) {
       return '-';
    }
    else 
     return (milage).toFixed(0) + " km"
 }

 

  let workShiftsHtml = null;
  if (workShifts) {
   workShiftsHtml = workShifts.map((shift, index) => {
      return (
          <div className="myTrips-table-row" key={'MTR_' + index} onScroll={handleScroll}>
            <div className="myTrips-driver text-center">{shift.workShiftNumber} </div>
            <div className="myTrips-date text-center"><FormatUtc item={shift.startTime}/></div>
            <div className="myTrips-date text-center">{getEndDate(shift)}</div>
            <div className="myTrips-permit text-center">{shift.permitNumber}</div>
            <div className="myTrips-region">{getDriverName(shift)}</div>
            <div className="myTrips-region text-center">{shift.numberOfTrips}</div>
            <div className="myTrips-region text-center">{shift.numberOfDeclinedTrips}</div>
            <div className="myTrips-currency text-right">{ <span>{(shift.totalDistance/1000).toFixed(2)} km</span>}</div>
            <div className="myTrips-currency text-right">{getMileage(shift.mileageStart)}</div>
            <div className="myTrips-currency text-right">{getMileage(shift.mileageEnd)}</div>
            <div className="myTrips-currency text-right"><div><WorkShiftPayment price={shift.totalRevenue} currency={shift.currency}/></div></div>
            <div className="myTrips-currency text-right"><div><WorkShiftPayment price={shift.totalVat} currency={shift.currency}/></div></div>
            {/* <div className="myTrips-region">{shift.numberOfCancelledTrips}</div> */}
            <div className="myTrips-icon"><PictureAsPdfIcon /></div>
          </div>
      )
    })
  }


  return (
    <>
      <div className="myTrips-table-header">
        <div name="shiftNumber" title="Sorter" className="myTrips-driver text-center" onClick={() =>handleSort("shiftNumber")}>Skiftnr.<SearchIndicator visible={sortField == "shiftNumber"} ascending={ascending}/></div>
        <div name="startTime" title="Sorter" className="myTrips-date text-center" onClick={() =>handleSort("startTime")}>Start<SearchIndicator visible={sortField == "startTime"} ascending={ascending}/></div>
        <div name="endTime" title="Sorter" className="myTrips-date text-center" onClick={() =>handleSort("endTime")}>Slutt<SearchIndicator visible={sortField == "endTime"} ascending={ascending}/></div>
        <div name="permitNumber" title="Sorter" className="myTrips-permit text-center" onClick={() =>handleSort("permitNumber")}>Løyve<SearchIndicator visible={sortField == "permitNumber"} ascending={ascending}/></div>
        <div name="driverNumber" title="Sorter" className="myTrips-region text-center" onClick={() =>handleSort("driverNumber")}>Sjåfør<SearchIndicator visible={sortField == "driverNumber"} ascending={ascending}/></div>
        <div name="numberOfTrips" title="Sorter" className="myTrips-region text-center" onClick={() =>handleSort("numberOfTrips")}>Antall turer<SearchIndicator visible={sortField == "numberOfTrips"} ascending={ascending}/></div>
        <div name="numberOfDeclinedTrips" title="Sorter" className="myTrips-region text-center" onClick={() =>handleSort("numberOfDeclinedTrips")}>Avviste turer<SearchIndicator visible={sortField == "numberOfDeclinedTrips"} ascending={ascending}/></div>
        <div name="totalDistance" title="Sorter" className="myTrips-region text-center" onClick={() =>handleSort("totalDistance")}>Distanse<SearchIndicator visible={sortField == "totalDistance"} ascending={ascending}/></div>
        <div name="totalDistance" title="Sorter" className="myTrips-region text-center" onClick={() =>handleSort("mileageStart")}>Km inn<SearchIndicator visible={sortField == "mileageStart"} ascending={ascending}/></div>
        <div name="totalDistance" title="Sorter" className="myTrips-region text-center" onClick={() =>handleSort("mileageEnd")}>Km ut<SearchIndicator visible={sortField == "mileageEnd"} ascending={ascending}/></div>
        <div name="totalRevenue" title="Sorter" className="myTrips-region text-center" onClick={() =>handleSort("totalRevenue")}>Omsetning<SearchIndicator visible={sortField == "totalRevenue"} ascending={ascending}/></div>
        <div name="totalVat" title="Sorter" className="myTrips-region text-center" onClick={() =>handleSort("totalVat")}>Mva<SearchIndicator visible={sortField == "totalRevenue"} ascending={ascending}/></div>
        <div className="myTrips-icon text-center"></div>
        {/* <div name="numberOfCancelledTrips" title="Sorter" className="myTrips-region" onClick={() =>handleSort("numberOfCancelledTrips")}>Kansellerte turer<SearchIndicator visible={sortField == "numberOfCancelledTrips"} ascending={ascending}/></div> */}
      </div>
      <div className="myTrips-table-content" onScroll={handleScroll}>
        {workShiftsHtml}
      </div>

    </>
  )
}
export default WorkShiftsTable