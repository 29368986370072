import { authHeader, history } from '../_helpers';

export const messageService = {
  sendMessage
}

function sendMessage(message){
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(message)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/message/sendMessage' , requestOptions).then(handleResponse, handleError);
 }


function getMessageLog(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
};

return fetch(process.env.REACT_APP_API_URL + '/api/message/getLog', requestOptions).then(handleResponse, handleError);
}


 function handleResponse(response) {
   return new Promise((resolve, reject) => {
       if (response.ok) {
           // return json if it was returned in the response
           var contentType = response.headers.get("content-type");
           if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
           } else {
                resolve();
           }
       } else {

          // return error message from response body
          response.json().then(json=> reject({json, error: (json || {}).error || 'Det oppstod en server feil'}));

           if(response.status === 401){
               localStorage.clear();
               history.push('/login');
               return;
           }

              
       }
   });
 }
 
 function handleError(error) {
   return Promise.reject(error && error.message);
 }