import React, { useState, useEffect } from "react";
import '../../css/components/region.css'

const RegionsTable = ({regionList, onSelectedCb}) => {
   const [regions, setRegions] = useState([]);


   useEffect(()=>{
      setRegions(regionList);
   },[regionList]);


   const handleShow = function(region) {
      onSelectedCb(region);
   }

   var regionsHtml = null;
   if (regions) {
      regionsHtml = regions.map((region, index) => {
         return (
            <div className="region-table-row" title="Klikk for detaljer" key={'MTR_' + index}  onClick={() => handleShow(region)}>
               <div className="region-table-id">{region.regionId}</div>
               <div className="region-table-name">{region.name}</div>
               <div className="region-table-cabs">{region.numberOfCabs}</div>
            </div>
         )
      })
   }




   return (
      <>
      <div className="region-table-header">
      <div name="id" className="region-table-id">Id</div>
      <div name="name" className="region-table-name">Navn</div>
         <div name="numberOfCabs" className="region-table-cabs">Antall biler</div>
      </div>
      <div className="region-table-content">
         {regionsHtml}
      </div>

      </>
   )
}
export default RegionsTable