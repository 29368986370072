import {combineReducers} from 'redux'
import dashboard from './dashboard'
import config from './config'
import leftSidebar from './left-sidebar'
import palettes from './palettes'
import user from './user'
import navigation from './navigation'
import { authentication } from './../functions/_reducers/authentication.reducer'
import { registration } from './../functions/_reducers/registration.reducer'
import { users } from './../functions/_reducers/users.reducer'
import { alert } from './../functions/_reducers/alert.reducer'
const rootReducer = combineReducers({
  dashboard,
  navigation,
  config,
  leftSidebar,
  palettes,
  user,
  authentication,
  registration,
  users,
  alert
})

export default rootReducer
