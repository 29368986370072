import React, { useState, useEffect} from 'react'
import Datatable from './datatable-user'
import Avatars from './avatars'
import {SemipolarLoading} from 'react-loadingg'
import Rolls from './rolls'
import { history } from '../../functions/_helpers'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import TableMenu from './tableMenu'


const Users = (props) => {
    
    const [data, setData] = useState({ hits: [] });
    const [showEmptyTable,setShowEmptyTable] = useState(false)
    const [loading,setLoading] = useState(true);
    useEffect(() => {
      if (!loading && (props.users == null || props.users.length === 0)) {
         setShowEmptyTable(true);
      }
      else {
         setData(props.users);
         setShowEmptyTable(false);
      }
      setLoading(false);
   }, [props]);

    
   const editUser = function(id) {
      history.push('/userEdit/' + id);
   }

   const deleteUser = function(id) {
      confirmAlert({
         title: '',
         message: 'Vil du slette brukeren?',
         buttons: [
            {
            label: 'Ja',
            onClick: () => doDelete(id)
            },
            {
            label: 'Nei',
            onClick: () => doNothing()
            }
         ]
      });
   }
  
   function doNothing(){
      history.push('/users');
   }
   
   function doDelete(id){
      props.onUserDelete(id);
   }



   const columns = React.useMemo(
      () => [
         {
               Header: ' ',
               accessor: 'profileImageUrl',
               Cell: props => <Avatars items={props.value} />
         },
         {
               Header: 'Fornavn',
               accessor: 'firstName',
         },
         {
               Header: 'etternavn',
               accessor: 'lastName',
         },
         
         {
               Header: 'rolle',
               accessor: 'role',
               Cell: props => <Rolls items = {props.value}/>
         },
         {
               Header: 'telefonnummer',
               accessor: 'phoneNumber'
         },
         {
               Header: "epost",
               accessor :"email"
         },
         {
               Header:'firma',
               accessor:'company'
         },
         {
               Header: '',
               accessor: 'userId',
               Cell: props => <TableMenu id={props.value} editCb={editUser} deleteCb={deleteUser}/>
         }
         
      ],
      []
   )
    
   const items = JSON.stringify(data);
   if(typeof(data) === 'undefined' || items === null  || items.length <= 0 || items.length === undefined || data.length <= 0 || data.length ===undefined){
      if(!showEmptyTable){
         return <SemipolarLoading/>
      }
   }

   if (showEmptyTable) {
      return(<div><h3 style={{textAlign:"center",margin:"0 auto",color:"#4663ac"}}>Ingen brukere funnet!</h3></div>)
   }

   return <Datatable columns={columns} data={data} />
}

export default Users


