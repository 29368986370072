import React from 'react'
import Layout from '../layouts/centered'
import {Link} from 'react-router-dom'
import {FiBox} from 'react-icons/fi'
import Modal from '../components/modals'
import Widget from '../components/widget'

const Logout = () => {
  return (
    <Layout>
      
      <Modal />
    </Layout>
  )
}

export default Logout
