import React from 'react'
const AppVersion = ({items}) => {
    

    if(typeof(items) === 'undefined' || items === null  || items.length <= 0 || items[0] === null || typeof(items) === null || typeof(items)===[undefined]){
        return(<></>) 
    }

  return (
    <div className="flex flex-row items-center justify-start">
    <ul>
    {[JSON.parse(items[0])].map(_item => (
                    <li key={_item}>
                          <span>{_item.AppVersion}</span>
                    </li>
                ))}
 
       <li></li>
    </ul>

    </div>
  )
}

export default AppVersion