import { authHeader, config, history } from '../_helpers';

export const workShiftService = {
  search,
  recalculate,
  getReport
}

function search(model) {
  const requestOptions = {
      method: 'POST',
      headers: {...authHeader(),'Content-Type': 'application/json' },
      body: JSON.stringify(model)
  };
  return fetch(process.env.REACT_APP_API_URL + '/api/workShift', requestOptions).then(handleResponse, handleError);
}

// function recalculate() {
//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader()
// };
//   return fetch(process.env.REACT_APP_API_URL + '/api/workShift', requestOptions).then(handleResponse, handleError);
// }


function getReport(model) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(),'Content-Type': 'application/json' },
    body: JSON.stringify(model)
  };

  return fetch(process.env.REACT_APP_API_URL + '/api/workShift/getReport', requestOptions).then(handleResponse, handleError);

}

function recalculate(model) {
  const requestOptions = {
    method: 'POST',
    headers: {...authHeader(),'Content-Type': 'application/json' },
    body: JSON.stringify(model)
  };

  return fetch(process.env.REACT_APP_API_URL + '/api/workShift/recalculateReport', requestOptions).then(handleResponse, handleError);

}



function handleResponse(response) {
   return new Promise((resolve, reject) => {
       if (response.ok) {
           // return json if it was returned in the response
           var contentType = response.headers.get("content-type");
           if (contentType && contentType.includes("application/json")) {
               response.json().then(json => resolve(json));
           } else {
               resolve();
               response.blob().then(blob => {
                 let url = window.URL.createObjectURL(blob);
                 let a = document.createElement('a');
                 a.href = url;
                 a.download = 'report.xlsx';
                 a.click();
               })
           }
       } else {
           // return error message from response body
           response.text().then(text => reject(text));
           console.log(response);
           if(response.status === 401){
               localStorage.clear();
               history.push('/login');
           }
       }
   });
 }

 

 function handleError(error) {
   return Promise.reject(error && error.message);
 }