import React from 'react'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {FiMenu} from 'react-icons/fi'
import DropdownUser from './dropdown-user'
import '../../css/components/navbar.css'
import '../../css/main.css'
const Navbar = () => {
  const {config} = useSelector(
    state => ({
      config: state.config
    }),
    shallowEqual
  )
  let {collapsed} = {...config}
  const dispatch = useDispatch()
  return (
    <div className="navbar navbar-1">
      <div className="navbar-inner w-full flex items-center justify-start">
        <button 
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'collapsed',
              value: !collapsed
            })
          }
          className="mx-4 fimenuhidder" style={{marginTop:"-30px"}}>
          <FiMenu size={20} className="fimenuhidder"/>
        </button>

        <span className="ml-auto"></span>
        <DropdownUser />
      </div>
    </div>
  )
}

export default Navbar
