import React, { useState, useEffect, useCallback} from 'react'
import { useParams } from "react-router-dom";
import trip_home_icon from './../assets/image/web-icons/home.png'
import '../css/main.css'
import { history } from '../functions/_helpers'
import _ from "lodash";
import { homeLocationService } from '../functions/services'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import jwt from 'jwt-decode'

const  HomeLocation= ({props}) => {
   const { id, driverId }= useParams();
   const [submitted, setSubmitted] = useState(false);
   const [changes, setChanges] = useState(false);
    
   const [homeLocation, setHomeLocation] = useState({
      homeLocationId:0,
      driverId: 0,
      name: '',
      latitude:0,
      longitude:0,
      radius:0,
      deleted:false
   })


   useEffect(() => {
      console.log(driverId);
      // Load permit
      if (id > 0) {
         homeLocationService.getHomeLocation(id)
         .then(
         (response) => {
            setHomeLocation(response);
         },
         (error) => {
               console.log(error);
         }
         )
      }
      else {
         setHomeLocation(homeLocation => ({ ...homeLocation, driverId: parseInt(driverId)}));
      }

   }, []);


   /* Check access rights. Only admin (1) is allowed */
   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
      localStorage.clear();
      history.push('/login');
   }

   // Check Role
   const decodedToken = jwt(user_data.token);
   if (decodedToken == null || decodedToken == undefined) {
         localStorage.clear();
         history.push('/login');  
   }
   const role = parseInt(decodedToken.role);

   if(role !== 1){
      history.push('/');
      return null;
   }





   function handleChange(e) {
      const { name, value } = e.target;
      setHomeLocation(homeLocation => ({ ...homeLocation, [name]: value }));
      setChanges(true);
   }
 


   const deleteHomeLocation = function() {
      confirmAlert({
         title: '',
         message: 'Vil du slette hjemmelokasjonen?',
         buttons: [
           {
             label: 'Ja',
             onClick: () => doDelete()
           },
           {
             label: 'Nei',
             onClick: () => doNothing()
           }
         ]
       });
   }

   function doNothing(){
   }
    
   function doDelete(){
      homeLocationService.deleteHomeLocation(id)
      .then(
        (response) => {
         handleBack();
        },
        (error) => {
           console.log(error);
        }
      )
   }

   function handleSubmit(e) {
      console.log(homeLocation);
      e.preventDefault();
      setSubmitted(true);
      
      if(changes) {
         homeLocationService.postHomeLocation(homeLocation)
         .then(
         (response) => {
            handleBack();
         },
         (error) => {
            console.log(error);
         })
      }
   }


   const handleBack = () => {
      history.push('/driverEdit/' + driverId);
   }

   const getMenuText = function() {
      if (id == 0){
         return "LEGG TIL";
      }
      else {
         return "ENDRE";
      }
   }


   const getHeading = function() {
      if (id == 0){
         return "Ny hjemmelokasjon";
      }
      else {
         return "Endre hjemme lokasjon: " + homeLocation.name;
      }
   }


    return (
      <>
         <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
            <div className="flex w-full trip_header ml-2" >
               <div className="col-6 text-left">
                  <img src={trip_home_icon} alt="home" />
                  <span className="font-bold"> HJEM &nbsp; &nbsp; / &nbsp; </span>
                  <span className="font-bold uppercase"> Løyve &nbsp; &nbsp; / &nbsp; </span>
                  <span className="font-bold"> {getMenuText()} &nbsp; &nbsp; / &nbsp; </span>
               </div>
               <div className="text-right inline-flex" style={{position:"absolute",right:"30px"}}>
                  <button type="button" className="add_btn" id="backBtn" onClick={()=>handleBack()}> Tilbake </button>
               </div>
            </div>
            <div className="form-container">
               <form name="form" className="form permits" onSubmit={handleSubmit} >
                  <h4 className="trip_title font-bold mb-3"> {getHeading()} </h4>
                  <div className="page-content trips ">
                  <label className="font-bold block"> Navn </label>
                     <input type="text" style={{border:"1px solid #37708e"}} name="name" value={homeLocation.name} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleChange} className={'form-control' + (submitted && !homeLocation.name ? ' is-invalid' : '')} />

                     <label className="font-bold block"> Breddegrad </label>
                     <input type="number" style={{border:"1px solid #37708e"}} name="latitude" value={homeLocation.latitude} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleChange} className={'form-control' + (submitted && !homeLocation.latitude ? ' is-invalid' : '')} />

                     <label className="font-bold block"> Lengdegrad </label>
                     <input type="number" style={{border:"1px solid #37708e"}} name="longitude" value={homeLocation.longitude} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleChange} className={'form-control' + (submitted && !homeLocation.longitude ? ' is-invalid' : '')} />

                     <label className="font-bold block"> Radius (m) </label>
                     <input type="number" style={{border:"1px solid #37708e"}} name="radius" value={homeLocation.radius} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onChange={handleChange} className={'form-control' + (submitted && !homeLocation.radius ? ' is-invalid' : '')} />


                  </div>
                  <div className="driver_btns text-right mt-10" style={{float: "right",marginRight: "0px",height: "45px",margin: "30px auto"}}>
                     <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>deleteHomeLocation(e)}> Slett </button>                            
                     <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>handleBack(e)}> Avbryt </button>                            
                     <button className="sign_btn"  style={{display:"inline", marginLeft:"30px"}} onClick={(e)=>handleSubmit(e)}> Lagre </button>                            
                  </div>
               </form>
            </div>
         </div>
      </>
    )

}
export default HomeLocation
