import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Dashboard from './pages/dashboard'
import Mytrips from './pages/myTrips'
import Drivers from './pages/drivers'
import Cabs from './pages/cabs'
import PermitHolders from './pages/permitHolders'
import Users from './pages/users'
import ForgotPassword from './pages/forgot-password'
import Login from './pages/login'
import Profile from './components/dashboard/EditItemsFromTable/profileEdit'
import PermitHolderEdit from './components/dashboard/EditItemsFromTable/permitHolderEdit'
import {PrivateRoute} from './functions/privateRoutes'
import Permit from './pages/permit'
import UserEdit from './components/dashboard/EditItemsFromTable/userEdit'
import DriverEdit from './components/dashboard/EditItemsFromTable/driverEdit'
import CabEdit from './components/dashboard/EditItemsFromTable/cabEdit'
import Logout from './pages/logout'
import Settings from './pages/settings'
import Accounting from './pages/accounting'
import Regions from './pages/regions'
import Shifts from './pages/shifts'
import Heatmap from './pages/heatmap'
import Map from './pages/map'
import HomeLocation from './pages/homeLocation'
import Messages from './pages/messages'
import PreOrders from './pages/preorders'

const Routes = () => {
  return (<>
    <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
    <Switch>
      <PrivateRoute exact path="/">
        <Dashboard />
      </PrivateRoute>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/myTrips">
        <Mytrips />
      </Route>
      <Route path="/drivers">
        <Drivers />
      </Route>
      <Route path="/cabs">
        <Cabs />
      </Route>
      <Route path="/permitHolders">
        <PermitHolders />
      </Route>
      <Route path="/users">
        <Users />
      </Route>
      <Route path="/dashboard">
        <Dashboard/>
      </Route>
      {/* <Route path="/profile">
        <Profile/>
      </Route> */}
      <Route path="/permitHolderEdit/:id">
        <PermitHolderEdit/>
      </Route>
      <Route path="/permit/:id/:permitHolderId">
        <Permit/>
      </Route>
      <Route path="/userEdit/:id">
        <UserEdit/>
      </Route>
      <Route path="/driverEdit/:id">
        <DriverEdit/>
      </Route>
      <Route path="/homeLocation/:id/:driverId">
        <HomeLocation/>
      </Route>
      <Route path="/cabEdit/:id">
        <CabEdit/>
      </Route>
      <Route path="/logout">
        <Logout/>
      </Route>
      <Route path="/settings">
        <Settings/>
      </Route>
      <Route path="/accounting">
        <Accounting/>
      </Route>
      <Route path="/regions">
        <Regions/>
      </Route>
      <Route path="/shifts">
        <Shifts/>
      </Route>
      <Route path="/heatmap">
        <Heatmap/>
      </Route>
      <Route path="/map">
        <Map/>
      </Route>
      <Route path="/messages">
        <Messages/>
      </Route>
      <Route path="/preorders">
        <PreOrders/>
      </Route>
    </Switch>
    </>
  )
}
export default Routes
