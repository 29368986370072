import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {history,authHeader,config} from './../functions/_helpers'
import trip_home_icon from './../assets/image/web-icons/home.png'
import './../css/main.css'
import Section from '../components/dashboard/section'
import SettingsGroup from '../components/dashboard/settingsGroup'

function Index(props) {

  const [dataSettings,setSettings]= useState({hits:[]});

  let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
 
  const fetchData = async () => {
    await axios.get(process.env.REACT_APP_API_URL+'/api/settings',{headers:authHeader()}
      ).then((response)=>{

        let groups = response.data.reduce((r, a) => {
          r[a.group] = [...r[a.group] || [], a];
          return r;
         }, {});
         
          setSettings(groups);
      }).catch((error)=>{
        if(error.response){
            console.log(error.response.data);
        }else if (error.request){
            console.log(error.request);
        }else{
            console.log(error.message);
        }
    });
  }

  useEffect(() => {
    fetchData();
  } , []);

  function handleSettings(e) {
    e.preventDefault();
  }

  if(user_data === null || user_data.length === 0)
  {
    localStorage.clear();
    history.push('/login');
  }
  if(user_data.role != 1){
      history.push('/');
      return null;
  }
 
  let settingGroups = Object.keys(dataSettings).map(function(group) {
    return (
        <SettingsGroup group={group} settings={dataSettings[group]}></SettingsGroup>
    )
  });


  return (
    <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
      <div className="flex w-full trip_header ml-2" >
          <div className="col-6 text-left">
              <img src={trip_home_icon} alt="home" />
              <span className="font-bold"> <Link to='/' className="non-undeline-link">HJEM &nbsp; &nbsp; / &nbsp; </Link></span>
              <span className="font-bold uppercase"> Innstillinger &nbsp; &nbsp; / &nbsp; </span>
          </div>
      </div>      
      <Section>
        <div className="trip_box ml-2 custom-add-item-border"  style= {{overflow: "auto", height:"80vh"}}>
          <h4 className="trip_title font-bold mb-3"> System parametere </h4>
          <form name="form" className="form" onSubmit={handleSettings}>
            {settingGroups}
          </form>
        </div>
      </Section>
    </div>
  )
}
export default Index