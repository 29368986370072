import React, {useState} from 'react'
import {useTable, useSortBy, usePagination, useRowSelect} from 'react-table'
import {PageWithText} from '../pagination'
import './../../css/main.css'
import { FaSort } from "react-icons/fa";

import {history} from './../../functions/_helpers'
import { MdFirstPage, MdNavigateBefore,MdNavigateNext,MdLastPage} from "react-icons/md";


const Datatable = ({columns, data, sortById}) => {
   const [isMarking, setIsMarking] = useState(false);

   var currentTime= new Date();

   
   const handleMouseDown = (e => {
      currentTime = new Date();
      setIsMarking(false);
   });

   const handleMouseUp = (e => {
      var diff = new Date().getTime() - currentTime.getTime();
      if (diff > 500) {
         setIsMarking(true);
      }
   });

   const handleClick = (v) => {   // this one is for clicking the whole row
      if (!isMarking) {
         if(typeof(v.original.cabId) !== 'undefined'){
         history.push('/cabEdit/' + v.original.cabId);
         } 
      }
   }

  
   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      //pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: {pageIndex, pageSize,selectedRowIds}
   } = useTable(
      {
         columns,
         data,
         initialState: {pageIndex: 0, pageSize: 100, sortBy: [{id:sortById, desc:false}]}
      },
      useSortBy,
      usePagination,
      useRowSelect,
      hooks => {
         hooks.visibleColumns.push(columns => [
         // Let's make a column for selection
         {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({getToggleAllRowsSelectedProps}) => (
               <>
               {/*<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />*/}
               </>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({row}) => (
               <>
               {/*<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />*/}
               </>
            )
         },
         ...columns
         ])
      }
   )

   // 
   return (
      <div className="table-container">
         <table {...getTableProps()} className="table">
         <thead>
            {headerGroups.map(headerGroup => (
               <tr {...headerGroup.getHeaderGroupProps()}>
               {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                     <div className="flex flex-row items-center justify-center" style={{color:"#fff"}}>
                     <span>{column.render('Header')}</span>
                     {/* Add a sort direction indicator */}
                     <span className="ml-auto">
                        {column.isSorted ? (
                           column.isSortedDesc ? (
                           <FaSort className="stroke-current text-2xs" />
                           ) : (
                           <FaSort className="stroke-current text-2xs" />
                           )
                        ) : (
                           ''
                        )}
                     </span>
                     </div>
                  </th>
               ))}
               </tr>
            ))}
         </thead>
         <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
               
               prepareRow(row)
               return (
               <tr {...row.getRowProps()} onClick={() => handleClick(row)} style={{zIndex:"-1"}}>
                  {row.cells.map(cell => {
                  return <td {...cell.getCellProps()} onMouseDown={(e) =>handleMouseDown(e)} onMouseUp={(e) =>handleMouseUp(e)}>{cell.render('Cell')}</td>
                  })}
               </tr>
               )
            })}
         </tbody>
         </table>

         <div className="flex flex-row items-center justify-between my-4" style={{height:"5px"}}>
         <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
         {pageIndex !== 0 && (
               <PageWithText onClick={() => gotoPage(0)}><MdFirstPage style={{forntSize:"20px", height:"20px", width:"20px"}}/></PageWithText>
            )}
            {canPreviousPage && (
               <PageWithText onClick={() => previousPage()}><MdNavigateBefore style={{forntSize:"20px", height:"20px", width:"20px"}}/></PageWithText>
            )}
            {canNextPage && (
               <PageWithText onClick={() => nextPage()} disabled={!canNextPage}>
               <MdNavigateNext style={{forntSize:"20px", height:"20px", width:"20px"}}/>
               </PageWithText>
            )}
            {pageIndex !== pageCount - 1 && (
               <PageWithText
               onClick={() => gotoPage(pageCount - 1)}
               disabled={!canNextPage}>
               <MdLastPage style={{forntSize:"20px", height:"20px", width:"20px"}}/>
               </PageWithText>
            )}
            
         </div>
         <select
            className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none" 
            value={pageSize}
            onChange={e => {
               setPageSize(Number(e.target.value))
            }}>
            {[25, 50, 100].map(pageSize => (
               <option key={pageSize} value={pageSize}>
               Show {pageSize}
               </option>
            ))}
         </select>
         </div>
      </div>
   
   )
}

export default Datatable
