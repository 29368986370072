import React, { Component } from 'react';

class DriverContactCell extends Component {
  
  constructor(props) {
    super(props);
  }
  render() {
    const {items} = this.props;

    return (
      <div className="flex flex-row items-center justify-start" style={{fontSize: "12px"}}>
          <ul>
              <li key={items}>
                <span>Epost: {items.original.email !== null? items.original.email: ""}</span><br/>
                <span>Telefon: {items.original.phoneNumber !== null?items.original.phoneNumber:""}</span>
              </li>
        </ul>
      </div>
    )
  }
}

export default DriverContactCell;