import React, { useState, useEffect } from 'react'
import Popper from 'popper.js'
import AccountLinks from './account-links'
import './../../css/main.css'
import './../../css/components/dropdowns.css'
import Rolls from './../../components/dashboard/rolls'
import default_profile from './../../assets/image/web-icons/defaultProfile.png'

const Dropdown = ({ placement = 'bottom-end' }) => {
  const [hidden, setHidden] = useState(true)
  let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
  const defaultImg = '';
  if(typeof(user_data.profileImageUrl) === 'undefined' || user_data.profileImageUrl === null){
     user_data.profileImageUrl = default_profile;
  }
  const openDropdown = () => {
    new Popper(buttonRef.current, dropdownRef.current, {
      placement: placement
    })
    setHidden(false)
  }
  const closeDropdown = () => {
    setHidden(true)
  }

  const buttonRef = React.createRef()
  const dropdownRef = React.createRef()
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false
      }
      setHidden(!hidden)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    
  }, [hidden, dropdownRef, buttonRef])
  return (
    <div className="">
      <div className="pr-5">
            <span className="user_state"> <Rolls items = {user_data.role}/> </span>
            <p className="user_email"> {user_data.email}</p>
          </div>
      <button
        ref={buttonRef}
        onClick={() => (hidden ? openDropdown() : closeDropdown())}
        className="flex h-16 w-8 rounded-full ml-2 profile-height" >
        <span className="absolute top-0 right-0 pt-4 account_dropdown" style={{top:"-12px",right:"-20px"}}>
          
          <img style={{top:"-2px"}}
            className="h-8 w-8 rounded-full shadow user_avatar"
            src={user_data.profileImageUrl}
            alt="avatar"
          />
        </span>
      </button>
      <div ref={dropdownRef} className={`dropdown ${hidden ? '' : 'open'}`} >
        <div className={`dropdown-content w-48  py-3`} style={{top:"-30px",left:"-22px", position:"absolute"}}>
          <AccountLinks />
        </div>
      </div>
    </div>
  )
}

export default Dropdown
