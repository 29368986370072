import React, { useState, useRef, useEffect} from 'react'
import Section from '../components/dashboard/section'
import Users from '../components/dashboard/users'
import trip_home_icon from '../assets/image/web-icons/home.png'
import './../css/main.css'
import jwt from 'jwt-decode'
import { history } from '../functions/_helpers'
import {Link} from 'react-router-dom'
import {FiSearch} from 'react-icons/fi'
import 'react-phone-number-input/style.css'
import {userService} from '../functions/services'


function Index() {
   const addBtns = useRef(null);
   const searchIcon = useRef(null);

   const [users, setUsers] = useState([]);
   const [filteredUsers, setFilteredUsers] = useState([]);
   const [searchString, setSearchString] = useState('');
   const usersRef = useRef();
   const filteredUsersRef = useRef();

   usersRef.current = users;
   filteredUsersRef.current = filteredUsers;

   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
   if(user_data === null || user_data.length === 0)
   {
         localStorage.clear();
         history.push('/login');
   }

   // Check Role
   const decodedToken = jwt(user_data.token);
   if (decodedToken == null || decodedToken == undefined) {
         localStorage.clear();
         history.push('/login');  
   }

   const role = parseInt(decodedToken.role);
   if(role !== 1){
      history.push('/');
   }


   useEffect(() => {
      const fetchData = async () => {        
         await userService.getAll()            
         .then((response)=>{
            setUsers(response);
            setFilteredUsers(response);
         }).catch((error)=>{
            console.log(error.message);
         });                  
      };

      fetchData();
   }, []);


   const handleSearch= (e) => {
      const { name, value } = e.target;
      setSearchString(value);
      filterUsers(users, value);
   }

   const filterUsers = (array, filter) => {
      setFilteredUsers(array.filter(user => 
         (user.company != null && user.company.toLowerCase().indexOf(filter) >= 0) ||
         (user.firstName != null && user.firstName.toLowerCase().indexOf(filter) >= 0) ||
         (user.lastName != null && user.lastName.toLowerCase().indexOf(filter) >= 0) ||
         (user.email != null && user.email.toLowerCase().indexOf(filter) >= 0) ||
         (user.phoneNumber != null && user.phoneNumber.toLowerCase().indexOf(filter) >= 0)
         ));
   }


   const onUserDelete = (id) => {
      userService.deleteUser(id)
      .then(
         (response) => {
            setUsers(usersRef.current.filter(user => user.userId !== id));
            setFilteredUsers(filteredUsersRef.current.filter(user => user.userId !== id));
         },
         (error) => {
            console.log(error);
         }
      )
   }

   const addBox = () => {
      history.push('/userEdit/0');
      return;
      
   }
   

   return (
      <>
         <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main">
               <div className="flex w-full trip_header ml-2">
                  <div className="col-6 text-left">
                     <img src={trip_home_icon} alt="home" />
                     <span className="font-bold"> <Link to='/' className="non-undeline-link">HJEM &nbsp; &nbsp; / &nbsp; </Link></span>
                     <span className="font-bold"> <Link to='/users' className="non-undeline-link" >BRUKERE &nbsp; &nbsp; / &nbsp; </Link></span>
                  </div>
                  <div className="col-6 text-right" style={{display: "inline-flex"}}>

                     <input type="search" placeholder="Søk"  onChange={handleSearch} className="pl-10 pr-5 appearance-none h-10 w-full rounded-full text-sm focus:outline-none simple_sorter_btn_search"/>
                     <button type="submit" className="absolute top-0 mt-3 left-0 ml-4 top-adjust" ref={searchIcon} style={{top:"-6px"}}>
                           <FiSearch className="stroke-current h-4 w-4" />
                     </button>
                     <button hidden={role != 1}  type="button" className="add_btn" ref={addBtns} id="addBtn" onClick={() => addBox()}> legg til </button>
                  </div>
               </div>
               <Section>
                  <Users users={filteredUsers} onUserDelete={onUserDelete} />
               </Section>

         </div>
      </>
   )
}
export default Index
