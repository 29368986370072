import { authHeader, config, history } from '../_helpers';

export const driverService = {
   getDrivers,
   getDriver,
   putDriver,
   postDriver,
   deleteDriver,
   activateDriver,
   deactivateDriver,
   getExtraDriverProperties
}


function getDrivers() {
   const requestOptions = {
       method: 'GET',
       headers: authHeader()
   };
    return fetch(process.env.REACT_APP_API_URL + '/api/drivers/', requestOptions).then(handleResponse, handleError);
 }

function getDriver(id) {
   const requestOptions = {
       method: 'GET',
       headers: authHeader()
   };
    return fetch(process.env.REACT_APP_API_URL + '/api/drivers/' + id, requestOptions).then(handleResponse, handleError);
 }

function getExtraDriverProperties(id) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_URL + `/api/drivers/getExtraDriverProperties${('/' + id) || ''}`, requestOptions).then(handleResponse, handleError);
}
 
function putDriver(driver){
    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(driver)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/drivers/' + driver.driverId, requestOptions).then(handleResponse, handleError);
}


 function postDriver(driver){
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(driver)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/drivers' , requestOptions).then(handleResponse, handleError);
 }

 function deleteDriver(id){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
     return fetch(process.env.REACT_APP_API_URL + '/api/drivers/' + id, requestOptions).then(handleResponse, handleError);
 }

 function activateDriver(id){
    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/drivers/activate/' + id , requestOptions).then(handleResponse, handleError);
 }


 function deactivateDriver(id){
    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/drivers/deactivate/' + id , requestOptions).then(handleResponse, handleError);
 }



 function handleResponse(response) {
   return new Promise((resolve, reject) => {
       if (response.ok) {
           // return json if it was returned in the response
           var contentType = response.headers.get("content-type");
           if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
           } else {
                resolve();
           }
       } else {
           // return error message from response body
           response.text().then(text => reject(text));
           if(response.status === 401){
               localStorage.clear();
               history.push('/login');
           }
       }
   });
 }
 



 function handleError(error) {
   return Promise.reject(error && error.message);
 }