import React from 'react'
import Layout from '../layouts/centered'
import CenteredForm from '../layouts/centered-form'
import Login from '../components/login-forms/login'
import Footer from '../components/login-forms/footer'

const Index = () => {

    localStorage.clear();
    
    return (
        <Layout>
            {/*<button className="sign_btn" style={{position:"absolute",rigth:"0",top:"0",width:"280px"}} onClick={handleClick}>Register Admin</button>*/}
            <CenteredForm>
                <Login className="sign_btn"/>
            </CenteredForm>
            <div >
            <Footer/>
            </div>
        </Layout>
    )
}

export default Index
