import React, { useState, useEffect, useMemo } from 'react'
import './../../../css/main.css'
import {
   Table,
   TableRow,
   TableHead,
   TableBody,
   TableCell,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { cabService, regionService, messageService } from '../../../functions/services'
import { FaRegWindowClose, FaRegSquare } from 'react-icons/fa';
import { Alert } from '../../alerts'
import { SemipolarLoading } from 'react-loadingg'

const useStyles = makeStyles(theme => ({
   tableLayout: {
   },
   tableHeaderLayout: {
   },
   tableBodyLayout: {
   },
   tableRowLayout: {
      backgroundColor: 'white',
      '&:hover': { backgroundColor: '#eee' },
   },
   tableColLayout: {
      paddingTop: 10,
      paddingBottom: 10,
   },
}));


const MessageEdit = (props) => {

   const [regions, setRegions] = useState([]);
   const [cabsByRegion, setCabsByRegion] = useState([]);
   const [cabsLoading, setCabsLoading] = useState(true);
   const [submitted, setSubmitted] = useState(false);
   const [changes, setChanges] = useState(false);
   const [chosenCabs, setChosenCabs] = useState([]);
   const [errors, setErrors] = useState();
   const [success, setSuccess] = useState();

   const [message, setMessage] = useState({
      title: '',
      text: '',
      cabs: [],
      toAllCabs: false,
      regionId: 0,
      ack: false
   });


   useEffect(() => {

      regionService.getRegions()
         .then(
            (response) => {
               response = response ? [...response] : [];
               response.unshift({ name: 'Alle', regionId: 0 });
               setRegions(response);
            },
            (error) => {
               setErrors(error);
            }
         )

      getCabsByRegionId(message.regionId);

   }, []);

   const getCabsByRegionId = (regionId) => {
      regionId = regionId || 0;

      setCabsLoading(true);
      setErrors(null);

      return cabService.getCabsByRegionId(regionId)
         .then((response) => {

            let result = [];

            if (response) {
               console.log(response);
               result = response.filter(c=> {
                  if (!c.connected || !c.driver || c.driver.driverId == 0) {
                     return false;
                  }
                  return true;
               }).map(x => {                  
                  return {
                     ...x, permitNumber: (x.permit || {}).permitNumber,
                     driverName: ((x.driver || {}).firstName || '') + ' ' + ((x.driver || {}).lastName || ''),
                     driverNumber: (x.driver || {}).driverNumber
                  }
               
               })
            }
            console.log(result);
            setCabsByRegion(result);

         },
            (error) => {
               setErrors(error);
            }
         )
         .finally(() => {
            setCabsLoading(false);
         })
   }

   const handleFormInfoChangedBase = ({ name, value }) => {
      setMessage({ ...message, [name]: value });
      setChanges(true);
      setErrors(null);
      setSuccess(null);
   }

   const handleFormInfoChanged = (e) => {
      handleFormInfoChangedBase(e.target);
   }

   const handleChangeAck = (e) => {
      const checked = ((e || {}).target || {}).checked;
      handleFormInfoChangedBase({ name: 'ack', value: checked });
   }

   const handleChangedSentToAllCars = (e) => {
      const checked = ((e || {}).target || {}).checked;

      handleFormInfoChangedBase({ name: 'toAllCabs', value: checked });
      if (checked) {
         setChosenCabs([]);
      }
   }

   const handleRegionChange = (e) => {

      let { value } = e.target;
      value = value && value != 0 ? parseInt(value) : 0;
      handleFormInfoChangedBase(e.target);
      setChosenCabs([]);

      getCabsByRegionId(value).then(x => {
      }).catch(e => {
      });

   }

   const addCabToChooseCabs = (cab) => {

      if (message.toAllCabs) {
         return;
      }

      const tempCabs = [...chosenCabs];
      (tempCabs || []).push(cab);
      setChosenCabs(tempCabs);
      setChanges(true);
   }

   const removeCabToChooseCabs = (cab) => {

      if (message.toAllCabs) {
         return;
      }

      let res = (chosenCabs || []).filter(x => x != cab);
      setChosenCabs(res);
      setChanges(true);
   }

   const handleSubmit = (e) => {
      e.preventDefault();

      if (!window.confirm("Er du sikker på at du vil sende?")) {
         return;
      }

      setErrors(null);
      setSuccess(null);

      // validation 
      if (!message) {
         return;
      }
      if (!message.title) {
         setErrors('Title er påkrevd.');
         return;
      }
      if (!message.text) {
         setErrors('Tekst er påkrevd.');
         return;
      }
      if ((cabsByRegion || []).length === 0) {
         setErrors('Det er ingen bil i valgt kjøreområdet.');
         return;
      }
      if (!message.toAllCabs && (chosenCabs || []).length === 0) {
         setErrors('Velg mist 1 bil.');
         return;
      }

      setSubmitted(true);

      const dataToSent = { ...message };
      dataToSent.regionId = parseInt(dataToSent.regionId);
      dataToSent.cabs = chosenCabs;

      messageService.sendMessage(dataToSent).then(
         (response) => {
            // todo -> show the was sent
            setSuccess('Meldingen er sendt');
         },
         ({ error }) => {

            const errorText = error || null;
            setErrors(errorText || '');

         }).finally(x => {
            setSubmitted(false);
         });

   }

   const checkCabIsChosen = (cabId) => {
      const findIndex = (chosenCabs || []).findIndex(x => x == cabId);
      if (message.toAllCabs) {
         return true;
      }
      else if (findIndex == -1) {
         return false
      }
      else {
         return true;
      }
   }

   const closeError = () => {
      setErrors(null);

   }

   const closeSuccess = () => {
      setSuccess(null);
   }


   const resetForm = (e) => {

      e.preventDefault();

      setMessage({
         title: '',
         text: '',
         cabs: [],
         toAllCabs: false,
         regionId: 0,
         ack: false
      });
      setChosenCabs([]);
      setChanges(false);
      setErrors(null);
      setSuccess(null);
   }

   const optionRegionItems = useMemo(() => {
      if (regions.length > 0) {
         return regions.map((region) => (
            <option key={region.regionId} value={region.regionId}>
               {region.name}
            </option>
         ));
      } else {
         return null;
      }
   }, [regions]);


   return (
      <>
               <form name="form" className="form flex flex-wrap w-full message-form" onSubmit={handleSubmit}>

                  {errors ? <div className="w-full mb-4">
                     <Alert
                        color="bg-transparent border-red-500 text-red-500 alert-error"
                        onClose={closeError}
                        borderLeft
                        raised>
                        {errors}
                     </Alert>
                  </div> : null}
                  {success ? <div className="w-full mb-4">
                     <Alert
                        color="bg-transparent border-green-500 text-green-500 alert-success"
                        onClose={closeSuccess}
                        borderLeft
                        raised>
                        {success}
                     </Alert>
                  </div> : null}


                     <div className="message-form-size">
                        <div className='sending-data'>{submitted ? '...Sender' : ''}</div>
                        <label className="font-bold block">Kjøreområde</label>
                        <select
                           style={{ border: "1px solid #37708e" }}
                           value={message.regionId}
                           name="regionId"
                           onChange={(e) => handleRegionChange(e)}
                           className={'form-control' + (submitted && !message.regionId ? ' is-invalid' : '')}>
                           {optionRegionItems}
                        </select>

                       
                        <label className="font-bold block">Bil</label>
                        <div className="car-picker">
                           {cabsLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                              <SemipolarLoading size="small" style={{ position: 'relative' }} /></div> :
                              <CarPicker data={cabsByRegion}
                                 chosenCabs={chosenCabs}
                                 onCabSelected={addCabToChooseCabs}
                                 removeCabToChooseCabs={removeCabToChooseCabs}
                                 checkCabIsChosen={checkCabIsChosen} />}
                        </div>                           
                        <div className="cab-checkbox-container">
                           <input type="checkbox"
                              disabled={false}
                              name="toAllCabs"
                              onChange={handleChangedSentToAllCars}
                              checked={message.toAllCabs}
                           />
                           <span className="checkbox-label">Velg alle i det valgte kjøreområdet</span>
                        </div>


                        <label className="font-bold block"> Tittel </label>
                        <input type="text"
                           style={{ border: "1px solid #37708e" }}
                           name="title"
                           value={message.title}
                           onChange={handleFormInfoChanged}
                           className={'form-control' + (submitted && !message.title ? ' is-invalid' : '')} />

                        <label className="font-bold block"> Tekst </label>
                        <textarea type="text"
                           style={{ border: "1px solid #37708e" }}
                           name="text"
                           value={message.text}
                           onChange={handleFormInfoChanged}
                           rows="3"
                           className={'form-control' + (submitted && !message.text ? ' is-invalid' : '')} />

                        <div className="cab-checkbox-container">
                           <input type="checkbox"
                              disabled={false}
                              name="ack"
                              onChange={handleChangeAck}
                              checked={message.ack}
                           />
                           <span className="checkbox-label">Be om kvittering</span>
                        </div>  
                       <div style={{ display: 'flex', justifyContent: 'center'}}>
                        {submitted ? <div style={{ display: 'flex' }}><SemipolarLoading size="small" style={{ position: 'relative', fontSize: '6px' }} />
                        </div> : null}
                        </div>

                        <div
                           className="driver_btns text-center mt-10"
                           style={{ marginRight: "0px", height: "45px", marginTop: "40px", marginBottom: "40px", justifyContent: "center", flexDirection: 'row', alignItems: "center" }}>


                           <button
                              className="sign_btn" style={{ display: "inline", marginLeft: "30px" }}
                              disabled={submitted ? true : false}
                              onClick={(e) => handleSubmit(e)}>

                              {submitted ? <div>...Sender</div> : 'Send'}
                           </button>

                           <button
                              className="sign_btn" style={{ display: "inline", marginLeft: "30px" }}
                              disabled={false}
                              onClick={(e) => resetForm(e)}>
                              Avbryt
                           </button>
                        </div>

                     </div>

               </form>
      </>
   )
}


const CarPicker = (props) => {
   const { onCabSelected, data = [], checkCabIsChosen, removeCabToChooseCabs, chosenCabs } = props;

   var classes = useStyles();

   const handleCabSelected = function (cabId) {
      onCabSelected(cabId);
   }

   const cells = (data || []).map((el) => {

      const isChoosen = checkCabIsChosen(el.cabId) ? true : false;
      return (<TableRow key={el.cabId} className={classes.tableRowLayout + (isChoosen ? ' cab-choosen' : '')} >

         <TableCell className={classes.tableColLayout} style={{ textAlign: 'left' }}>{el.permitNumber}</TableCell>
         <TableCell className={classes.tableColLayout}>{el.driverNumber || '-'}</TableCell>
         <TableCell className={classes.tableColLayout}>{el.driverName || '-'}</TableCell>
         <TableCell className={classes.tableColLayout} >
            {isChoosen ? <span className="car-picker-btn" onClick={() => removeCabToChooseCabs(el.cabId)}>
               <FaRegWindowClose size={20} /> </span> :
               <span onClick={() => handleCabSelected(el.cabId)} className="choose-car-btn adjust-small car-picker-btn">
                  <FaRegSquare size={20} /> </span>
            }
         </TableCell>
      </TableRow>
      )
   });

   return <Table stickyHeader className={classes.tableLayout}>
      <TableHead className={classes.tableHeaderLayout}>
         <TableRow className={classes.tableRowLayout}>
            <TableCell className={classes.tableColLayout} style={{ textAlign: 'left' }}>Løyve-nr.</TableCell>
            <TableCell className={classes.tableColLayout}>Sjåfør nr.</TableCell>
            <TableCell className={classes.tableColLayout}>Sjåfør</TableCell>
            <TableCell className={classes.tableColLayout}></TableCell>
         </TableRow>
      </TableHead>
      <TableBody className={classes.tableBodyLayout}>
         {cells || null}
      </TableBody>
   </Table>
}

export default MessageEdit
