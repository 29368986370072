import React from 'react'
import Section from '../components/dashboard/section'
import {history} from '../functions/_helpers'
import Dashboard from '../components/dashboard/dashboards'
import jwt from 'jwt-decode'

const Index = () => {
  let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));
  if(user_data === null || user_data.length === 0 || !user_data.token)
 {
     localStorage.clear();
     history.push('/login');
     return null;
 }

 // Check Role
 const decodedToken = jwt(user_data.token);
 if (decodedToken == null || decodedToken == undefined) {
     localStorage.clear();
     history.push('/login');  
 }
 const role = parseInt(decodedToken.role);
  if(role !== 1 && role !==2){
      history.push('/myTrips');
      return null;
  }
  
  return (
      <Section>
        <div className="top-adjustment" style={{overflow:"auto", height:"90vh", paddingRight:"10px",paddingBottom:"10px"}}>
          <div style={{width:"98%", height:"90%"}}>
            <Dashboard/>
          </div>
        </div>
      </Section>
  )
}
export default Index
