import React, { useState, useEffect } from 'react'
import { history } from '../functions/_helpers'
import jwt from 'jwt-decode'
import trip_home_icon from './../assets/image/web-icons/home.png'
import MyTripsTable from '../components/myTrips/myTripsTable'
import { myTripsService } from '../functions/services'
import { Alert } from '../components/alerts'
import { SemipolarLoading } from 'react-loadingg'

const defaultTake = 50;

const PreOrders = () => {
   const [role, setRole] = useState(null);
   const [bookingLoading, setBookingLoading] = useState(true);
   const [bookings, setBookings] = useState([]);
   const [errors, setErrors] = useState();

   const [searchModel, setSearchModel] = useState({
      ascending: false,
      index: 0,
      take: defaultTake,
      orderField: 'requestedPickupTime'
   })

   useEffect(() => {
      let user_data = JSON.parse(localStorage.getItem('user')) == null ? [] : JSON.parse(localStorage.getItem('user'));

      if (user_data === null || user_data.length === 0) {
         localStorage.clear();
         history.push('/login');
         return;
      }

      // Check Role
      const decodedToken = jwt(user_data.token);
      if (decodedToken == null || decodedToken == undefined) {
         localStorage.clear();
         history.push('/login');
         return;
      }

      const roleTemp = parseInt(decodedToken.role);

      if (roleTemp !== 1){
         history.push('/');
         return;
      }

      setRole(roleTemp);

   }, [])

   useEffect(() => {
   
      if (role != null){
         searchPreorders();
      }

   }, [role, searchModel])

   const searchPreorders = async () => {
      myTripsService.preOrderSearch(searchModel)
         .then(
            (response) => {
               if (searchModel.index > 0) {
                  setBookings([...bookings, ...response]);
               }
               else {
                  setBookings(response);
               }
            },
            (error) => {
               console.log(error);
               setErrors(error);
            })
         .finally(x => {
            setBookingLoading(false);
         });

   }

   const handleOnSortCb = function (field, direction) {
      setSearchModel({ ...searchModel, orderField: field, ascending: direction, index: 0 })
   }

   const handleOnScrollCb = function () {
      var index = searchModel.index + searchModel.take;
      setSearchModel({ ...searchModel, take: defaultTake, index: index });
   }

   const closeError = () => {
      setErrors(null);
   }


   const fieldsToShow = {
      createdDate: { isShown: true, order: 10 },
      bookingNumber: { isShown: true, order: 2 },
      driverNumber: { isShown: true, order: 3 },
      permitNumber: { isShown: true, order: 4 },
      from: { isShown: true, order: 5 },
      to: { isShown: true, order: 6 },
      regionName: { isShown: false, order: 11 },
      price: { isShown: true, order: 8 },
      bookingStatus: { isShown: true, order: 9 },
      requestedPickupTime: { isShown: true, order: 1 },
      numberOfPassengers:{isShown: true, order: 7}
   }

   if (!role) {
      return <SemipolarLoading size="small" style={{ position: 'relative' }} />;
   }

   return (
      <>
         <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main preOrder-wrapper">
            <div className="top-adjustment" style={{ overflow: "hidden", height: "90vh", paddingRight: "10px", paddingBottom: "10px" }}>
               <div style={{ width: "100%", height: "100%" }}>
                  <div className="flex w-full trip_header ml-2" >
                     <div className="col-6 text-left">
                        <img src={trip_home_icon} alt="home" />

                        <span className="font-bold"> HJEM &nbsp; &nbsp; / &nbsp; </span>
                        <span className="font-bold uppercase"> Forhåndsbestilling</span>
                     </div>
                  </div>
                  {errors ? <div className="w-full mb-4">
                     <Alert
                        color="bg-transparent border-red-500 text-red-500 alert-error"
                        onClose={closeError}
                        borderLeft
                        raised>
                        {errors}
                     </Alert>
                  </div> : null}

                  {bookingLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                     <SemipolarLoading size="small" style={{ position: 'relative' }} /></div> :

                     <MyTripsTable sortFieldDefault={"requestedPickupTime"}
                        fieldsToShow={fieldsToShow}
                        fieldsToShowDetails = {{
                           permitNumber: { isShown: true},
                           company:  { isShown: true},
                           driverNumber:  { isShown: true},
                           price:  { isShown: true},
                           distance:  { isShown: true},
                           numberOfPassengers: {isShown:true},
                           cancelReason:  { isShown: true},
                           traveller:  { isShown: true},
                           messageToDriver:  { isShown: true},
                           requestedPickupTime: {isShown: true},
                           tripLog:  { isShown: true},
                        }}
                        tripList={bookings}
                        onScrollCb={handleOnScrollCb}
                        onSortCb={handleOnSortCb}
                        role={role}>
                     </MyTripsTable>
                  }
               </div>
            </div>
         </div>
      </>
   )
}
export default PreOrders
