import React,{useRef,useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import {useForm} from 'react-hook-form';
import {Alert} from '../alerts';
import {userActions} from '../../functions/_actions'
import {store} from './../../store'
import Spinner from 'react-bootstrap/Spinner'
import {history} from './../../functions/_helpers'
import { useDispatch, useSelector } from 'react-redux'
import { alertActions } from './../../functions/_actions'
const FormValidation = ({items, onSubmit, alerts}) => {
  //const loggingIn = useSelector(state => state.authentication.loggingIn);
  const errorMsg = useRef(null);
  const [isLoading, setShowLoader] = useState(false);
  const ref = React.useRef(null);
  const spinnerRef = React.useRef(null);
  const {handleSubmit, errors, register} = useForm();
  const alert = useSelector(
        state => state.alert
    );
  const dispatch2 = useDispatch();

    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch2(alertActions.clear());
        });
    }, []);
    
  const formHandler = formFields => {
    // const { dispatch } = props;
      if (formFields.email && formFields.password) {
          store.dispatch(userActions.login(formFields.email, formFields.password));
      }
    
   }
  function handleInvalidLoginText(e){
    if(spinnerRef.current!==null) {spinnerRef.current.style="none;"}
    setShowLoader(false);
  }
function handleChange(e){
  setShowLoader(false);
  
  if(errorMsg.current !== null){
    errorMsg.current.style = 'display:none;';
  }
}
  const onSubmitFn = data => {
    if (onSubmit) {
      setShowLoader(true);
      formHandler(data);
    }
  }
  items = items.map(item => {
    item['ref'] = register(item['error'])
    return item
  })

 
  return (
    <div onMouseMove={handleInvalidLoginText}>
      
      <form 
      onSubmit={handleSubmit(onSubmitFn)}
      className="form flex flex-wrap w-full">
      
      {alerts &&
        items.map((item, i) => {
          if (!errors[item.name]) return null
          let msg = errors[item.name].message
          if (msg.length === 0) msg = `${item.label} is required`
          return (
            <div className="flex flex-col w-full">
              {errors[item.name] && (
                <div className="mb-2" key={i}>
                  <Alert
                    color="bg-transparent border-red-500 text-red-500"
                    borderLeft
                    raised>
                    {msg}
                  </Alert>
                </div>
              )}
            </div>
          )
        })}
      <div className="w-full">
      
        {items.map((item, i) => {
          if (item.type === 'checkbox') {
            return (
              <div className="form-element">
                {item.label && <div className="form-label">{item.label}</div>}
                <div className="flex items-center justify-start space-x-2" style={{marginLeft:"70px"}}>
                  {item.options.map((option, j) => (
                    <label className="flex items-center justify-start space-x-2" key={j}>
                      <input
                        ref={item.ref}
                        type="checkbox"
                        value={option.value}
                        name={item.name}
                        className={`form-checkbox h-4 w-4 ${
                          errors[item.name] ? 'text-red-500' : ''
                        }`}
                      />
                      <span
                        className={`${
                          errors[item.name] ? 'text-red-500' : ''
                        }`}>
                        {option.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )
          }
          if (item.type === 'radio') {
            return (
              <div className="form-element">
                {item.label && <div className="form-label">{item.label}</div>}
                <div className="flex items-center justify-start space-x-2">
                  {item.options.map((option, j) => (
                    <label className="flex items-center justify-start space-x-2">
                      <input
                        type="radio"
                        value={option.value}
                        name={item.name}
                        ref={register({required: true})}
                        className={`form-radio h-4 w-4 ${
                          errors[item.name] ? 'text-red-500' : ''
                        }`}
                      />
                      <span
                        className={`${
                          errors[item.name] ? 'text-red-500' : ''
                        }`}>
                        {option.label}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            )
          }
          if (item.type === 'select') {
            return (
              <div className="form-element">
                {item.label && <div className="form-label">{item.label}</div>}
                <select
                  ref={item.ref}
                  name={item.name}
                  className={`form-select ${
                    errors[item.name] ? 'border border-red-500' : ''
                  }`}>
                  {item.options.map((option, j) => (
                    <option key={j} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            )
          }
          if (item.type === 'textarea') {
            return (
              <>
                <div className="form-element">
                  {item.label && <div className="form-label">{item.label}</div>}
                  <textarea
                    ref={item.ref}
                    name={item.name}
                    className={`form-textarea ${
                      errors[item.name] ? 'border border-red-500' : ''
                    }`}
                    rows="3"
                    placeholder={item.placeholder}></textarea>
                  {!alerts && errors[item.name] && (
                    <div className="form-error">
                      {errors[item.name].message}
                    </div>
                  )}
                </div>
              </>
            )
          }
          return (
            <>
              <div className="form-element">
                {item.label && <div className="form-label" style={{marginLeft:"70px"}}>{item.label}</div>}
                <input style={{width:"70%",margin:"0 auto",borderColor:"#185A76",borderWidth:"1px",borderRadius:"10px"}} onChange={handleChange}
                  ref={item.ref}
                  name={item.name}
                  type={item.type}
                  className={`form-input ${
                    errors[item.name] ? 'border-red-500' : ''
                  }`}
                  placeholder={item.placeholder}
                />
                {!alerts && errors[item.name] && (
                  <div className="form-error">{errors[item.name].message}</div>
                )}
              </div>
            </>
          )
        })}
         <div className="w-full text-rsampoight">
              <span>
                  <Link style={{marginLeft:"70px"}} className="text-black" to='/forgot-password'>
                      Glemt passord?
                  </Link>
              </span>
            </div>
        <div className="sign_right_btn text-right">
          {<input style={{marginRight:"70px"}}
            type="submit"
            className="sign_btn"
            value="LOGG PÅ"
            ref = {ref}
           />
          }
        </div>
      </div>
    </form>
    {isLoading ? 
       <Spinner style={{margin:"0px auto", width:"35px", height:"35px",color:"#778899", display:"block"}} ref={spinnerRef} animation="border" role="status">
          <span className="sr-only">Loading...</span>
       </Spinner>
       :''}
       {alert.message &&
          <div onMouseMove={handleInvalidLoginText} onChange={handleInvalidLoginText} className={`alert ${alert.type}`} style={{textAlign:"center"}} ref={errorMsg} id="errorMsg">
              <span onChange={handleInvalidLoginText}>Feil brukernavn eller passord</span>
          </div>
         }
    </div>
    
  )
}
export default FormValidation
